import APIService from "../../@mock-api/api.service";

const apiService = new APIService();
export const getTotalEarnedChildCoins = (child) => async (dispatch) => {
    dispatch({ type: 'GET_CHILD_TOTAL_COINS'});

    try {

        let childTotalEarnedCoins =0;
            apiService.getChildTotalEarnedCoins({child}).then((success) => {
                childTotalEarnedCoins=success?.data?.data?.getTotalEarnedCoins;
                if(childTotalEarnedCoins){
                    dispatch({
                        type: 'GET_CHILD_TOTAL_COINS',
                        totalEarnedcoins: childTotalEarnedCoins
                    });
                }
                else{
                    dispatch({
                        type: 'GET_CHILD_TOTAL_COINS',
                        totalEarnedcoins: success.data.errors[0].message
                    });
                }
        });
    } catch (error) {
        dispatch({
            type: 'GET_CHILD_TOTAL_COINS',
            totalEarnedcoins: 'Something went wrong'
        });
    }
};
