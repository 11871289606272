import axios from 'axios';
import {
    GQ_EMAIL_VERIFICATION,
    GQ_FORGOT_PASSWORD,
    GQ_GENERATE_OTP,
    GQ_GENERATE_OTP_PARENT,
    GQ_GENERATE_PARENT_TOKEN,
    GQ_GENERATE_VERIFICATION_EMAIL_PARENT,
    GQ_LOGIN,
    GQ_PARENT_EARLY_ACCESS,
    GQ_PARENT_EMAIL_VERIFICATION,
    GQ_PROVIDER_EMAIL_VERIFICATION,
    GQ_PROVIDER_REGISTER,
    GQ_REGISTER,
    GQ_RESET_PASSWORD,
    GQ_LOGIN_VIA_TOKEN,
    GQ_VERIFY_FORGOT_EMAIL, GQ_GENERATE_PARENT_PASSWORD,
    GQ_UPDATE_VIEW_REFER_COUNT,
    GQ_VERIFY_OTP,
    GQ_VERIFY_PARENT_OTP,
    GQ_VERIFY_PROVIDER_OTP,
    GQ_LOGIN_VIA_OTP,
    GQ_GENERATE_LOGIN_OTP,
    GQ_GET_REGIME_PREFERENCE, GQ_VALIDATE_EMAIL, GQ_GENERATE_PARENT_TOKEN_WITH_PHONE, GQ_PARENT_MOBILE_DECODE
} from "./graphql/register";
import {
    GQ_ADD_CHILD,
    GQ_ADD_CHILD_GLOBAL_ATTRIBUTE,
    GQ_ADD_COURSE_TO_WISHLIST,
    GQ_ADD_HABIT,
    GQ_ADD_HOBBY,
    GQ_ADD_INTEREST,
    GQ_ADD_ONLINE_COURSE,
    GQ_ADD_ONLINE_COURSE_PROVIDER,
    GQ_ADD_SCHOOL,
    GQ_CREATE_NEW_CHILD,
    GQ_GET_ALL_HOBBIES,
    GQ_GET_ALL_INTEREST,
    GQ_GET_ALL_NOTIFICATIONS,
    GQ_GET_ALL_ONLINE_COURSE,
    GQ_GET_ALL_ONLINE_COURSES_BY_CHILD,
    GQ_GET_ALL_SCHOOL,
    GQ_GET_AVATAR,
    GQ_GET_CHILD,
    GQ_GET_CHILD_ASSIGNED_ATTRIBUTES,
    GQ_GET_CHILD_ATTRIBUTE_OPTIONS_BY_NAME_STANDARD,
    GQ_GET_CHILD_CERTIFICATES,
    GQ_GET_CHILD_DEMO_COURSES,
    GQ_GET_CHILD_LIKED_COURSES,
    GQ_GET_CHILD_ONGOING_COURSES,
    GQ_GET_CHILD_REPORT_CARDS,
    GQ_GET_CHILD_SCHOOLS,
    GQ_GET_COURSES,
    GQ_GET_GLOBAL_ATTRIBUTE_BY_TYPE,
    GQ_GET_GLOBAL_CHILD_ATTRIBUTES,
    GQ_GET_HABITS,
    GQ_GET_HOBBIES,
    GQ_GET_INTEREST,
    GQ_GET_ONLINE_COURSE,
    GQ_GET_SCHOOL,
    GQ_GET_SINGLE_CHILD_DETAIL,
    GQ_GET_PARENT_TDP_POPUP,
    GQ_GET_SKILLS,
    GQ_GET_SUBJECTS,
    GQ_GET_WISHLIST_COURSES,
    GQ_IMAGE_UPLOAD,
    GQ_REMOVE_COURSE_FROM_WISHLIST,
    GQ_SEARCH_COURSE_PROVIDERS,
    GQ_SEARCH_HABIT,
    GQ_SINGLE_COURSE_DETAIL,
    GQ_UPDATE_BASIC_INFO,
    GQ_UPDATE_CHILD_FAMILY_INFO,
    GQ_UPDATE_PARENTS_PROF_INFO,
    GQ_PARENTS_TEST_STATUS_INFO,
    GQ_UPDATE_FAMILY_TYPE_INFO,
    GQ_UPDATE_SIBLING__INFO,
    GQ_UPDATE_LIVE_WITH_GRANDPARENTS_INFO,
    GQ_UPDATE_CHILD_SLEEP_TIME,
    GQ_UPDATE_CHILD_SCREEN_TIME,
    GQ_UPDATE_CHILD_SOCIAL_INFO,
    GQ_UPDATE_CHILD_BLOOD_GROUP,
    GQ_UPDATE_CHILD_IMAGE_BANNER,
    GQ_UPDATE_EDUCATIONAL_INFO,
    GQ_UPDATE_HABITS,
    GQ_UPDATE_SKILLS,
    GQ_UPDATE_SUBJECTS,
    GQ_UPLOAD_FILE,
    GQ_GET_FEARS,
    GQ_UPDATE_CHILD_ASSESSMENT,
    GQ_SUBMIT_FEEDBACK,
    GQ_LOGOUT,
    GQ_GET_FEEDBACK_SECTION,
    GQ_SUBMIT_GUIDANCE_FEEDBACK,
    GQ_GET_LIST_OF_SURVEY,
    GQ_GET_SUBMITTED_USER_SURVEY,
    GQ_SUBMIT_USER_SURVEY,
    GQ_CREATE_PARENT_EXPECTATION_FEAR_CONCERN,
    GQ_ADD_TO_WISHLIST_COURSE,
    GQ_REMOVE_FROM_WISHLIST_COURSE,
    GQ_ADD_lIKE_TO_COURSE,
    GQ_GET_COURSE_DETAILS,
    GQ_GET_COURSE_REVIEWS,
    GQ_WRITE_REVIEW_ON_COURSE,
    GQ_REPORT_AN_REVIEW,
    GQ_MAKE_REVIEW_REPORT,
    GQ_DEMO_SESSION_FEEDBACK,
    GQ_DEMO_SESSION_FEEDBACK2,
    GQ_GET_COURSE_PROVIDER,
    GQ_FOLLOW_PROVIDER,
    GQ_UNFOLLOW_PROVIDER,
    GQ_GET_PARENT_PROFESSION,
    GQ_GET_WISH_LISTED_COURSES,
    GQ_CREATE_FAMILY_ATTRIBUTE,
    GQ_GET_DESIGNATION,
    GQ_UPDATE_CHILD_STAGE,
    GQ_UPDATE_CHILD_SECTION_ORDER,
    GQ_UPDATE_CHILD_INITIAL_REQUEST,
    GQ_UPDATE_COUNSELLINGSESSION,
    GQ_GET_COUNSELLINGSESSION,
    GQ_UPDATE_STUDENT_PARENT_SECONDARY_INFO,
    GQ_ASSIGN_TEACHER_TO_STUDENT,
    GQ_GET_SINGLE_CHILD_DETAIL_WITH_UNIQUE_ID,
    GQ_GET_TEACHER_DETAIL_WITH_UNIQUE_ID,
    GQ_DELETE_PARENT_ACCOUNT_SURVEY,
    GQ_DELETE_ARCHIVED_USER
} from "./graphql/child";
import {
    GQ_GET_ALL_COURSES,
    GQ_GET_USERS,
    GQ_GET_SINGLE_USER_DETAIL,
    GQ_GET_CHILD_BY_PARENT,
    GQ_GET_PROVIDER_ENQUIRIES,
    GQ_UPDATE_PROVIDER_STATUS,
    GQ_GET_ALL_CHILDREN,
    GQ_GET_PARENT_EARLY_ACCESS,
    GQ_GENERATE_SINGLE_LOGIN,
    GQ_GENERATE_BULK_LOGIN,
    GQ_SEND_EMAIL_PHONE_VERIFICATION,
    GQ_FETCH_GROUP_ATTRIBUTES,
    GQ_CREATE_CHILD_ATTRIBUTE,
    GQ_SINGLE_ATTRIBUTE_DETAILS,
    GQ_DELETE_PROVIDER_CATEGORY,
    GQ_DELETE_CHILD_ATTRIBUTE,
    GQ_DELETE_CHILD_ATTRIBUTE_BY_ID,
    GQ_DELETE_ONLINE_COURSE,
    GQ_GET_PROVIDER_CATEGORIES_ADMIN,
    GQ_APPROVE_CHILD_ATTRIBUTE_BY_ID,
    GQ_UPDATE_CHILD_ATTRIBUTE,
    GQ_APPROVE_PROVIDER_CATEGORY,
    GQ_GET_FAQ_CATEGORY,
    GQ_ADD_FAQ_CATEGORY,
    GQ_EDIT_FAQ_CATEGORY,
    GQ_DELETE_FAQ_CATEGORY,
    GQ_ADD_SITE_FAQ,
    GQ_EDIT_SITE_FAQ,
    GQ_DELETE_SITE_FAQ,
    GQ_GET_ALL_SITE_FAQ,
    GQ_DELETE_PROGRAM_TYPE,
    GQ_APPROVE_PROGRAM_TYPE,
    GQ_ADMIN_SEND_NOTIFICATION,
    GQ_ADD_SITE_FAQ_BULK,
    GQ_SEND_MARKETTING_EMAIL,
    GQ_ADD_TEMPLATE_EMAIL,
    GQ_EDIT_TEMPLATE_EMAIL,
    GQ_DELETE_TEMPLATE_EMAIL,
    GQ_GET_ALL_EMAIL_TEMPLATES,
    GQ_GET_ALL_MARKETING_EMAILS,
    GQ_ADMIN_SEND_EMAIL,
    GQ_ADMIN_ADD_GROUP,
    GQ_GET_USER_GROUP,
    GQ_DELETE_USER_FROM_GROUP,
    GQ_GET_GROUP_BY_ID,
    GQ_UPDATE_GROUP_DATA,
    GQ_DELETE_GROUP,
    GQ_GET_GROUP_NOTIFICATION_EMAIl_LOGS,
    GQ_GET_GROUPED_FAQS,
    GQ_FILTER_FAQS_INQUIRY
} from "./graphql/admin";
import {
    GQ_ADD_NEW_CATEGORY, GQ_ADD_NEW_DESIGNATION,
    GQ_ADD_PROGRAM_TYPE,
    GQ_COMPLETE_PROVIDER_PROFILE,
    GQ_CREATE_PROVIDER_ENQUIRY, GQ_GET_ALL_DESIGNATION, GQ_GET_COURSES_BY_PROVIDER,
    GQ_GET_PROGRAM_TYPE, GQ_GET_PROGRAM_TYPE_ADMIN,
    GQ_GET_PROVIDER_PROFILE, GQ_SEARCH_DESIGNATION, GQ_SEARCH_PROGRAM_TYPE,
    GQ_SEARCH_PROVIDER_CATEGORY, GQ_SEND_OTP_BUSINESS_EMAIL, GQ_SEND_OTP_BUSINESS_PHONE, GQ_UPDATE_BANK_DETAILS,
    GQ_UPDATE_COMPANY_DETAILS, GQ_UPDATE_CORPORATE_DETAILS,
    GQ_UPDATE_PROVIDER_CATEGORY,
    GQ_UPDATE_PROVIDER_CONTACT_INFO, GQ_UPDATE_PROVIDER_DOCUMENT, GQ_UPDATE_PROVIDER_LOGO_BANNER,
    GQ_UPDATE_PROVIDER_PROFILE,
    GQ_UPDATE_PROVIDER_PROGRAM_TYPE, GQ_VERIFY_BUSINESS_EMAIL, GQ_VERIFY_BUSINESS_PHONE
} from "./graphql/provider";
import {
    GQ_GET_CATEGORIES,
    GQ_GET_PROVIDER_CATEGORIES,
    GQ_GET_STORED_MEDIA_BY_TYPE,
    GQ_SET_MEDIA_LOG,
    GET_FIELD_GROUP, GET_ALL_FIELDS, GET_CHILD_EARNED_COINS, GET_CHILD_TOTAL_EARNED_COINS, GET_CHILD_FIELDS,
    GQ_UPDATE_BILING_INFO,
    GQ_CREATE_TDP_ORDER,
    GQ_CREATE_INTERNATIONAL_TDP_ORDER,
    GQ_GETSERVICEDETAILS,
    GQ_APPLYCOPUN,
    GQ_DOWNLOADINVOICE
} from "./graphql/common";

import {
    GQ_ADD_COMMUNICATION_MODES,
    GQ_COURSE_CHARGES,
    GQ_CREATE_COURSE,
    GQ_GENERATE_VERIFICATION_OTP,
    GQ_GET_ALL_PARENT_FAQ,
    GQ_GET_ALL_PARENT_SITE_FAQ,
    GQ_GET_ALL_REFER_EARNED_COINS,
    GQ_GET_CAREERS_MAPPING,
    GQ_GET_COMMUNICATION_MODES,
    GQ_GET_COURSE,
    GQ_GET_COURSE_MEDIA,
    GQ_GET_LEARNING_NEEDS,
    GQ_GET_PARENT_FAQ_CATEGORY,
    GQ_GET_REFERRAL_MESSAGE,
    GQ_GET_TALENT_NEEDS,
    GQ_GET_TRAITS_MAPPING,
    GQ_UPDATE_COURSE_LOGO_BANNER,
    GQ_UPDATE_EMAIL_WITH_VERIFICATION, GQ_UPDATE_LANDING_TIME, GQ_UPDATE_PARENT_PROFILE,
    GQ_UPDATE_PHONE_WITH_VERIFICATION,
    GQ_UPDATE_PROFILE_PASSWORD,
} from "./graphql/course";


import {
    GQ_ADD_COURSE_PROVIDERS,
    GQ_ADD_EVENT,
    GQ_BOOK_COUNSELLOR_SESSION,
    GQ_BOOK_PROVIDER_DEMO,
    GQ_BOOKED_PENDING_DEMO_COURSES,
    GQ_CHILDREN_DEMO_PENDING_REQUEST, GQ_COUNSELLING_PAYMENT,
    GQ_CREATE_FREE_SLOT,
    GQ_CREATE_FREE_SLOT_GUIDANCE,
    GQ_DAILY_EVENT,
    GQ_DELETE_EVENT,
    GQ_DELETE_FREE_SLOT,
    GQ_DEMO_JOINED_STATUS,
    GQ_GET_CALENDAR_BOOKED_REQUEST,
    GQ_GET_CHILDREN_DEMO_BOOKING_REQUEST,
    GQ_GET_COUNSELLINGREQUEST,
    GQ_GET_CONFIRM_REQUEST,
    GQ_GET_COURSE_PROVIDERS,
    GQ_GET_EVENT_BY_DATE,
    GQ_GET_EVENT_BY_MONTH,
    GQ_GET_FREE_SLOTS,
    GQ_GET_USER_EVENTS,
    GQ_UPDATE_EVENT
} from "./graphql/event";
import {
    GQ_CREATE_TDP_REPORT,
    GQ_ASSIGN_DIMENSION_CELEBRITY,
    GQ_EXIT_TDP_TEST,
    GQ_GET_DIMENSION_COGNITIVE_REPORT,
    GQ_GET_TDP_PROGRESS,
    GQ_GET_TDP_REPORT,
    GQ_GET_TDP_SUMMARY,
    GQ_SUBMIT_TDP_EXAM,
    GQ_TDP_EXAM_DETAILS,
    GQ_PARENT_POPUPSTATUS,
    GQ_UPDATE_GUIDELINE,
    GET_MULTI_DIMENSION_COGNITIVE_REPORT,
    GQ_GENERATE_PDF_REPORT_ID,
    GQ_HANDLE_REQUEST_ACCESS
} from "./graphql/test";
import {GQ_GET_OVERVIEW_TEMPLATE, GQ_GET_POLYGON_ANALYSIS_REPORT,GQ_GET_UPDATE_REPORT,GQ_GET_CONGNITIVE_REPORT,GQ_GET_DIMENSION_INFO} from "./graphql/report";


import {
    GQ_FREE_ASSESSMENT_PAID_USER,
    GQ_GET_FREE_ASSESSMENT_QUESTIONS,
    GQ_SUBMIT_FREE_ASSESSMENT_PAID_USER,
    GQ_GET_FREE_ASSESSMENT_QUE_PAID_USER,
    GQ_CREATE_FREE_ASSESSMENT_PAID_USER,
    GQ_GET_FREE_ASSESSMENT_REPORT,
    GQ_GET_DASHBOARD_DETAILS,
    GQ_UPDATE_PREMIUM_REPRT,
    GQ_CALLPAIDUSER
} from "./graphql/dashboard";

const baseURL = process.env.NEXT_PUBLIC_API_ENDPOINT;


const headers = async () => {
    const headersData = {
        'Content-Type': 'application/json'
    };

    const token = await localStorage.getItem('aark_auth');
    if (token) {
        headersData.Authorization = `Bearer ${token}`;
    }

    return headersData;
};

const request = async (method, variables, query, upload) => {
    const url = `${baseURL}`;
    const options = {method, url, headers: await headers()};

    if (variables) {
        if (upload) {
            options.data = variables
        } else {
            options.data = {
                query: query,
                variables: variables
            };
        }
    }
    return axios(options);
};

export default class APIService {
    //Register Flow API's
    login(data) {
        return request('POST', data, GQ_LOGIN);
    }

    getRegimePreference() {
        return request('POST', {}, GQ_GET_REGIME_PREFERENCE);
    }

    validateEmailAddress(data){
        return request('POST', data, GQ_VALIDATE_EMAIL);
    }

    loginViaOtp(data) {
        return request('POST', data, GQ_LOGIN_VIA_OTP);
    }

    generateLoginOtp(data){
        return request('POST', data, GQ_GENERATE_LOGIN_OTP);
    }

    register(data) {
        return request('POST', data, data.user.role === 'user' ? GQ_REGISTER : GQ_PROVIDER_REGISTER);
    }

    decodeMobileNumber(data) {
        return request('POST', data, GQ_PARENT_MOBILE_DECODE);
    }

    // EMAIL VERIFICATION
    emailVerification(data) {
        return request('POST', data, GQ_EMAIL_VERIFICATION);
    }

    providerEmailVerification(data) {
        return request('POST', data, GQ_PROVIDER_EMAIL_VERIFICATION);
    }

    parentEmailVerification(data) {
        return request('POST', data, GQ_PARENT_EMAIL_VERIFICATION);
    }

    updateViewReferCount(data) {
        return request('POST', data, GQ_UPDATE_VIEW_REFER_COUNT);
    }

    generateOTP(data) {
        return request('POST', data, GQ_GENERATE_OTP);
    }

    generateEmailVerificationParent(data) {
        return request('POST', data, GQ_GENERATE_VERIFICATION_EMAIL_PARENT);
    }

    generateOtpParent(data) {
        return request('POST', data, GQ_GENERATE_OTP_PARENT);
    }

    parentEarlyAccess(data) {
        return request('POST', data, GQ_PARENT_EARLY_ACCESS);
    }

    verifyProviderOTP(data) {
        return request('POST', data, GQ_VERIFY_PROVIDER_OTP);
    }

    verifyOTP(data) {
        return request('POST', data, GQ_VERIFY_OTP);
    }

    verifyParentOTP(data) {
        return request('POST', data, GQ_VERIFY_PARENT_OTP);
    }

    generateParentToken(data){
        return request('POST', data, GQ_GENERATE_PARENT_TOKEN);
    }

    forgotPasswordOTP(data) {
        return request('POST', data, GQ_FORGOT_PASSWORD);
    }
    verifyForgotEmail(data) {
        return request('POST', data, GQ_VERIFY_FORGOT_EMAIL);
    }

    resetPassword(data) {
        return request('POST', data, GQ_RESET_PASSWORD);
    }
    loginByToken(data) {
        return request('POST', data, GQ_LOGIN_VIA_TOKEN);
    }
    

    generateParentPassword(data) {
        return request('POST', data, GQ_GENERATE_PARENT_PASSWORD);
    }

    getParentSiteFaq(data){
        return request('POST', data, GQ_GET_ALL_PARENT_SITE_FAQ)
    }

    getParentFaqCategory(){
        return request('POST', {}, GQ_GET_PARENT_FAQ_CATEGORY)
    }

    updateVerificationTiming(data) {
        return request('POST', data, GQ_UPDATE_LANDING_TIME);
    }

    generateParentTokenWithPhone(data) {
        return request('POST', data, GQ_GENERATE_PARENT_TOKEN_WITH_PHONE);
    }


    // login information edit

    generateVerificationOTP(data){
        return request('POST', data, GQ_GENERATE_VERIFICATION_OTP)
    }

    updateEmailWithVerification(data){
        return request('POST', data, GQ_UPDATE_EMAIL_WITH_VERIFICATION)
    }

    updatePhoneWithVerification(data){
        return request('POST', data, GQ_UPDATE_PHONE_WITH_VERIFICATION)
    }

    updateProfilePassword(data){
        return request('POST', data, GQ_UPDATE_PROFILE_PASSWORD)
    }

    updateParentProfile(data){
        return request('POST', data, GQ_UPDATE_PARENT_PROFILE)
    }


    //referral list

    getAllReferEarnedCoins(){
        return request('POST', {}, GQ_GET_ALL_REFER_EARNED_COINS)
    }
    getReferralMessage(){
        return request('POST', {}, GQ_GET_REFERRAL_MESSAGE)
    }


    //Children API's
    addChild(data) {
        return request('POST', data, GQ_CREATE_NEW_CHILD);
    }

    updateBasicInfo(data) {
        return request('POST', data, GQ_UPDATE_BASIC_INFO);
    }

    updateEducationalInfo(data) {
        return request('POST', data, GQ_UPDATE_EDUCATIONAL_INFO);
    }
    updateChildFamilyInfo(data) {
        return request('POST', data, GQ_UPDATE_CHILD_FAMILY_INFO);
    }

    updateParentsProfInfo(data) {
        return request('POST', data, GQ_UPDATE_PARENTS_PROF_INFO);
    }
    getParentTestStatusTDP(data) {
        return request('POST', data, GQ_PARENTS_TEST_STATUS_INFO);
    }
    updatefamilytype(data) {
        return request('POST', data, GQ_UPDATE_FAMILY_TYPE_INFO);
    }
    updateChildSiblingInfo(data) {
        return request('POST', data, GQ_UPDATE_SIBLING__INFO);
    }
    
    updategrandpartliveher(data) {
        return request('POST', data, GQ_UPDATE_LIVE_WITH_GRANDPARENTS_INFO);
    }

    //
    UpdateChildSleepDetails(data) {
        return request('POST', data, GQ_UPDATE_CHILD_SLEEP_TIME);
    }
    UpdateChildScreenTime(data) {
        return request('POST', data, GQ_UPDATE_CHILD_SCREEN_TIME);
    }
    UpdateSocialInfo(data) {
        return request('POST', data, GQ_UPDATE_CHILD_SOCIAL_INFO);
    }
    UpsertChildBloodGroup(data) {
        return request('POST', data, GQ_UPDATE_CHILD_BLOOD_GROUP);
    }
    UpsertChildAssessment(data) {
        return request('POST', data, GQ_UPDATE_CHILD_ASSESSMENT);
    }
    getChildList() {
        return request('POST', {}, GQ_GET_CHILD);
    }

    getChildByParent(data) {
        return request('POST', data, GQ_GET_CHILD_BY_PARENT);
    }

    singleChildDetail(data) {
        return request('POST', data, GQ_GET_SINGLE_CHILD_DETAIL);
    }

    GetPlansDetails(data) {
        return request('POST', data, GQ_GET_DASHBOARD_DETAILS);
    }

    updatePremiumReportStatus(data) {
        return request('POST', data, GQ_UPDATE_PREMIUM_REPRT);
    }

    TACallPaidUser(data) {
        return request('POST', data, GQ_CALLPAIDUSER);
    }

    getParentPopUpTDP(data) {
        return request('POST', data, GQ_GET_PARENT_TDP_POPUP);
    }


    getChildByUniqueIdId(data) {
        return request('POST', data, GQ_GET_SINGLE_CHILD_DETAIL_WITH_UNIQUE_ID);
    }

    getTeacherByUniqueId(data) {
        return request('POST', data, GQ_GET_TEACHER_DETAIL_WITH_UNIQUE_ID);
    }

    uploadImage(data) {
        return request('POST', data, GQ_IMAGE_UPLOAD, true);
    }

    uploadFile(data) {
        return request('POST', data, GQ_UPLOAD_FILE);
    }

    getSubjects(data) {
        return request('POST', data, GQ_GET_SUBJECTS);
    }

    updateSubject(data) {
        return request('POST', data, GQ_UPDATE_SUBJECTS);
    }

    getSkills(data) {

        return request('POST', data, GQ_GET_SKILLS);
    }

    updateSkills(data) {
        return request('POST', data, GQ_UPDATE_SKILLS);
    }

    getHabits(data) {
        return request('POST', data, GQ_GET_HABITS);
    }

    //Courses
    getCourses(data) {
        return request('POST', data, GQ_GET_COURSES);
    }

    getWishlistedCourses(data) {
        return request('POST', data, GQ_GET_WISH_LISTED_COURSES);
    }
    getCourseDetails(data) {
        return request('POST', data, GQ_GET_COURSE_DETAILS);
    }

    getCourseReviews(data) {
        return request('POST', data, GQ_GET_COURSE_REVIEWS);
    }

    writeReviewOnCourse(data) {
        return request('POST', data, GQ_WRITE_REVIEW_ON_COURSE);
    }

    reportAnReview(data) {
        return request('POST', data, GQ_REPORT_AN_REVIEW);
    }

    makeReviewReport(data) {
        return request('POST', data, GQ_MAKE_REVIEW_REPORT);
    }

    demoSessionFeedback(data) {
        return request('POST', data, GQ_DEMO_SESSION_FEEDBACK);
    }


    counsellingSessionFeedback(data) {
        return request('POST', data, GQ_DEMO_SESSION_FEEDBACK2);
    }

    addToWishlistCourse(data) {
        return request('POST', data, GQ_ADD_TO_WISHLIST_COURSE);
    }

    addLikeToCourse(data) {
        return request('POST', data, GQ_ADD_lIKE_TO_COURSE);
    }

    removeFromWishlistCourse(data) {
        return request('POST', data, GQ_REMOVE_FROM_WISHLIST_COURSE);
    }

    getParentProfession(){
        return request('POST', {}, GQ_GET_PARENT_PROFESSION);
    }

    getDesignation(){
        return request('POST', {}, GQ_GET_DESIGNATION);
    }

    getSingleCourseDetail(data) {
        return request('POST', data, GQ_SINGLE_COURSE_DETAIL)
    }

    updateHabits(data) {
        return request('POST', data, GQ_UPDATE_HABITS);
    }

    getWishlistData(data) {
        return request('POST', data, GQ_GET_WISHLIST_COURSES);
    }

    addCourseWishlist(data) {
        return request('POST', data, GQ_ADD_COURSE_TO_WISHLIST);
    }

    removeCourseWishlist(data) {
        return request('POST', data, GQ_REMOVE_COURSE_FROM_WISHLIST);
    }

    //Method to fetch details of course provider
    getCourseProviderDetails(data) {
        return request('POST', data, GQ_GET_COURSE_PROVIDER);
    }

    followProvider(data){
        return request('POST', data, GQ_FOLLOW_PROVIDER);
    }

    unFollowProvider(data){
        return request('POST', data, GQ_UNFOLLOW_PROVIDER);
    }


    getSchoolData(data) {
        return request('POST', data, GQ_GET_SCHOOL);
    }

    getAvatarData() {
        return request('POST', {}, GQ_GET_AVATAR);
    }

    getInterestData(data) {
        return request('POST', data, GQ_GET_INTEREST);
    }

    getOnlineCourseData(data) {
        return request('POST', data, GQ_GET_ONLINE_COURSE);
    }

    getHobbiesData(data) {
        return request('POST', data, GQ_GET_HOBBIES);
    }

    getAllInterestData() {
        return request('POST', {}, GQ_GET_ALL_INTEREST);
    }

    getAllOnlineCourseData() {
        return request('POST', {}, GQ_GET_ALL_ONLINE_COURSE);
    }

    deleteOnlineCourse(data) {
        return request('POST', data, GQ_DELETE_ONLINE_COURSE);
    }

    deleteProgramType(data) {
        return request('POST', data, GQ_DELETE_PROGRAM_TYPE);
    }

    approveProgramType(data) {
        return request('POST', data, GQ_APPROVE_PROGRAM_TYPE);
    }

    getAllHobbiesData() {
        return request('POST', {}, GQ_GET_ALL_HOBBIES);
    }

    getAllSchoolData() {
        return request('POST', {}, GQ_GET_ALL_SCHOOL);
    }

    addNewSchool(data) {
        return request('POST', data, GQ_ADD_SCHOOL);
    }

    addOnlineCourse(data) {
        return request('POST', data, GQ_ADD_ONLINE_COURSE);
    }

    addNewInterest(data) {
        return request('POST', data, GQ_ADD_INTEREST);
    }

    addNewHobby(data) {
        return request('POST', data, GQ_ADD_HOBBY);
    }

    addNewHabit(data) {
        return request('POST', data, GQ_ADD_HABIT);
    }

    searchHabit(data) {
        return request('POST', data, GQ_SEARCH_HABIT);
    }


    //Admin End API's
    getAllUsers(data) {
        return request('POST', data, GQ_GET_USERS);
    }

    getAllChildren(data) {
        return request('POST', data, GQ_GET_ALL_CHILDREN);
    }

    getCourseList(data) {
        return request('POST', data, GQ_GET_ALL_COURSES);
    }

    getSingleUserDetail(data) {
        return request('POST', data, GQ_GET_SINGLE_USER_DETAIL);
    }

    getProviderEnquiries(data) {
        return request('POST', data, GQ_GET_PROVIDER_ENQUIRIES);
    }

    chanageProviderStatus(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_STATUS)
    }

    getParentEarlyAccess(data){
        return request('POST', data, GQ_GET_PARENT_EARLY_ACCESS)
    }

    generateSingleLogin(data){
        return request('POST', data, GQ_GENERATE_SINGLE_LOGIN)
    }


    generateBulkLogin(data){
        return request('POST', data, GQ_GENERATE_BULK_LOGIN)
    }

    sendEmailPhoneVerification(data){
        return request('POST', data, GQ_SEND_EMAIL_PHONE_VERIFICATION)
    }

    getGroupAttributes(){
        return request('POST', {}, GQ_FETCH_GROUP_ATTRIBUTES)
    }

    createChildAttribute(data){
        return request('POST', data, GQ_CREATE_CHILD_ATTRIBUTE)
    }

    updateChildAttribute(data){
        return request('POST', data, GQ_UPDATE_CHILD_ATTRIBUTE)
    }

    singleAttributeDetails(data){
        return request('POST', data, GQ_SINGLE_ATTRIBUTE_DETAILS)
    }

    deleteProviderCategory(data){
        return request('POST', data, GQ_DELETE_PROVIDER_CATEGORY)
    }

    deleteChildAttribute(data){
        return request('POST', data, GQ_DELETE_CHILD_ATTRIBUTE)
    }

    deleteChildAttributeById(data){
        return request('POST', data, GQ_DELETE_CHILD_ATTRIBUTE_BY_ID)
    }

    approveChildAttributeById(data){
        return request('POST', data, GQ_APPROVE_CHILD_ATTRIBUTE_BY_ID)
    }

    approveProviderCategory(data){
        return request('POST', data, GQ_APPROVE_PROVIDER_CATEGORY)
    }

    addFaqCategory(data){
        return request('POST', data, GQ_ADD_FAQ_CATEGORY)
    }

    editFaqCategory(data){
        return request('POST', data, GQ_EDIT_FAQ_CATEGORY)
    }

    deleteFaqCategory(data){
        return request('POST', data, GQ_DELETE_FAQ_CATEGORY)
    }

    addSiteFaq(data){
        return request('POST', data, GQ_ADD_SITE_FAQ)
    }

    editSiteFaq(data){
        return request('POST', data, GQ_EDIT_SITE_FAQ)
    }

    deleteSiteFaq(data){
        return request('POST', data, GQ_DELETE_SITE_FAQ)
    }

    getSiteFaq(data){
        return request('POST', data, GQ_GET_ALL_SITE_FAQ)
    }

    getFaqCategory(){
        return request('POST', {}, GQ_GET_FAQ_CATEGORY)
    }
    getFaqInquiry(data){
        return request('POST', data, GQ_FILTER_FAQS_INQUIRY)
    }
    getGroupedFaqs(data){
        return request('POST', {}, GQ_GET_GROUPED_FAQS)
    }

    addSiteFaqBulk(data){
        return request('POST', data, GQ_ADD_SITE_FAQ_BULK,true)
    }

    sendMarketingEmail(data){
        return request('POST', data, GQ_SEND_MARKETTING_EMAIL)
    }

    addEmailTemplate(data){
        return request('POST', data, GQ_ADD_TEMPLATE_EMAIL)
    }

    editEmailTemplate(data){
        return request('POST', data, GQ_EDIT_TEMPLATE_EMAIL)
    }

    deleteEmailTemplate(data){
        return request('POST', data, GQ_DELETE_TEMPLATE_EMAIL)
    }

    getAllEmailTemplate(){
        return request('POST', {}, GQ_GET_ALL_EMAIL_TEMPLATES)
    }

    getAllMarketingEmails(){
        return request('POST', {}, GQ_GET_ALL_MARKETING_EMAILS)
    }

    getUserGroup(data){
        return request('POST', data, GQ_GET_USER_GROUP)
    }

    deleteUserFromGroup(data){
        return request('POST', data, GQ_DELETE_USER_FROM_GROUP)
    }

    getGroupById(data){
        return request('POST', data, GQ_GET_GROUP_BY_ID)
    }

    updateGroupData(data){
        return request('POST', data, GQ_UPDATE_GROUP_DATA)
    }

    deleteChildGroup(data){
        return request('POST', data, GQ_DELETE_GROUP)
    }

    //Provider
    createProviderEnquiry(data) {
        return request('POST', data, GQ_CREATE_PROVIDER_ENQUIRY);
    }

    completeProviderProfile(data, type) {
        return request('POST', data, type === 1 ? GQ_COMPLETE_PROVIDER_PROFILE : GQ_UPDATE_PROVIDER_PROFILE);
    }

    getProviderProfile() {
        return request('POST', {}, GQ_GET_PROVIDER_PROFILE);
    }

    updateCompanyDetails(data) {
        return request('POST', data, GQ_UPDATE_COMPANY_DETAILS);
    }

    searchProviderCategory(data) {
        return request('POST', data, GQ_SEARCH_PROVIDER_CATEGORY);
    }

    addNewProviderCategory(data) {
        return request('POST', data, GQ_ADD_NEW_CATEGORY);
    }

    updateProviderCategory(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_CATEGORY);
    }

    getProgramTypeData() {
        return request('POST', {}, GQ_GET_PROGRAM_TYPE);
    }

    getProgramTypeDataAdmin() {
        return request('POST', {}, GQ_GET_PROGRAM_TYPE_ADMIN);
    }

    addProgramTypeData(data) {
        return request('POST', data, GQ_ADD_PROGRAM_TYPE);
    }

    searchProgramTypeData(data) {
        return request('POST', data, GQ_SEARCH_PROGRAM_TYPE);
    }

    updateProviderProgramType(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_PROGRAM_TYPE);
    }

    updateContactPerson(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_CONTACT_INFO);
    }

    verifyBusinessEmail(data) {
        return request('POST', data, GQ_VERIFY_BUSINESS_EMAIL);
    }

    verifyBusinessPhone(data) {
        return request('POST', data, GQ_VERIFY_BUSINESS_PHONE);
    }

    sendBusinessEmailOTP(data) {
        return request('POST', data, GQ_SEND_OTP_BUSINESS_EMAIL);
    }

    sendBusinessPhoneOTP(data) {
        return request('POST', data, GQ_SEND_OTP_BUSINESS_PHONE);
    }

    updateProviderBankDetails(data) {
        return request('POST', data, GQ_UPDATE_BANK_DETAILS);
    }

    updateCorporateDetails(data) {
        return request('POST', data, GQ_UPDATE_CORPORATE_DETAILS);
    }


    updateProviderDocument(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_DOCUMENT);
    }

    getCoursesByProvider() {
        return request('POST', {}, GQ_GET_COURSES_BY_PROVIDER);
    }


    getAllDesignation(){
        return request('POST', {}, GQ_GET_ALL_DESIGNATION);
    }

    searchDesignation(data){
        return request('POST', data, GQ_SEARCH_DESIGNATION);
    }

    addNewDesignation(data){
        return request('POST', data, GQ_ADD_NEW_DESIGNATION);
    }

    //Common

    getFieldGroup(){
        return request('POST', {}, GET_FIELD_GROUP);
    }

    getChildFields(data){
        return request('POST', data, GET_CHILD_FIELDS);
    }

    getChildEarnedCoins(data){
        return request('POST', data, GET_CHILD_EARNED_COINS);
    }

    getChildTotalEarnedCoins(data){
        return request('POST', data, GET_CHILD_TOTAL_EARNED_COINS);
    }

    getAllField(){
        return request('POST', {}, GET_ALL_FIELDS);
    }

    getCategories() {
        return request('POST', {}, GQ_GET_PROVIDER_CATEGORIES);
    }

    //Common
    getAdminCategories() {
        return request('POST', {}, GQ_GET_PROVIDER_CATEGORIES_ADMIN);
    }

    getAllCategories() {
        return request('POST', {}, GQ_GET_CATEGORIES);
    }

    // ADD COURSE
    createOrUpdateCourse(mutation, data) {
        return request('POST', data, mutation);
    }

    getSingleCourse(data) {
        return request('POST', data, GQ_GET_COURSE);
    }

    getCourseCharges() {
        return request('POST', {}, GQ_COURSE_CHARGES);
    }

    setMediaLog(data) {
        return request('POST', data, GQ_SET_MEDIA_LOG);
    }

    getStoredMediaByType(data) {
        return request('POST', data, GQ_GET_STORED_MEDIA_BY_TYPE);
    }

    updateProviderLogoAndBanner(data) {
        return request('POST', data, GQ_UPDATE_PROVIDER_LOGO_BANNER);
    }

    updateChildLogoAndBanner(data) {
        return request('POST', data, GQ_UPDATE_CHILD_IMAGE_BANNER);
    }

    updateChildProfile(data, mutation) {
        return request('POST', data, mutation);
    }

    updateChildInterestsTDP(data, mutation) {
        return request('POST', data, mutation);
    }

    

    getAllOtherCourseProviders() {
        return request('POST', {}, GQ_SEARCH_COURSE_PROVIDERS);
    }

    getOnlineCoursesByChild(data) {
        return request('POST', data, GQ_GET_ALL_ONLINE_COURSES_BY_CHILD);
    }

    getFearsData(data) {
        return request('POST', {}, GQ_GET_FEARS);
    }
    createParentExpectationFearConcerns(data) {
        return request('POST', data, GQ_CREATE_PARENT_EXPECTATION_FEAR_CONCERN);
    }

    addOnlineCourseProvider(data) {
        return request('POST', data, GQ_ADD_ONLINE_COURSE_PROVIDER);
    }

    getGlobalChildAttributes(data) {
        return request('POST', data, GQ_GET_GLOBAL_CHILD_ATTRIBUTES);
    }

    getGlobalChildAttributesByType(data) {
        return request('POST', data, GQ_GET_GLOBAL_ATTRIBUTE_BY_TYPE);
    }

    addGlobalChildAttributes(data) {
        return request('POST', data, GQ_ADD_CHILD_GLOBAL_ATTRIBUTE);
    }

    createFamilyAttribute(data) {
        return request('POST', data, GQ_CREATE_FAMILY_ATTRIBUTE);
    }

    getChildAssignedAttributes(data) {
        return request('POST', data, GQ_GET_CHILD_ASSIGNED_ATTRIBUTES);
    }

    getChildSchools(data) {
        return request('POST', data, GQ_GET_CHILD_SCHOOLS);
    }

    getChildGradeSubjects(data) {
        return request('POST', data, GQ_GET_CHILD_ATTRIBUTE_OPTIONS_BY_NAME_STANDARD);
    }

    getChildReportCards(data) {
        return request('POST', data, GQ_GET_CHILD_REPORT_CARDS);
    }

    getChildCertificates(data) {
        return request('POST', data, GQ_GET_CHILD_CERTIFICATES);
    }

    getChildCourses(data) {
        return request('POST', data, GQ_GET_CHILD_ONGOING_COURSES);
    }

    getChildDemoCourses(data) {
        return request('POST', data, GQ_GET_CHILD_DEMO_COURSES);
    }

    getChildLikedCourses(data) {
        return request('POST', data, GQ_GET_CHILD_LIKED_COURSES);
    }

    // COURSES API ENDPOINTS
    updateCourseLogoAndBanner(data) {
        return request('POST', data, GQ_UPDATE_COURSE_LOGO_BANNER);
    }

    getCommunicationModes() {
        return request('POST', {}, GQ_GET_COMMUNICATION_MODES);
    }

    searchCommunicationModes(data) {
        return request('POST', data, GQ_GET_COMMUNICATION_MODES);
    }

    addCommunicationModes(data) {
        return request('POST', data, GQ_ADD_COMMUNICATION_MODES);
    }

    getCourseMedias(data) {
        return request('POST', data, GQ_GET_COURSE_MEDIA);
    }

    createCourses(data) {
        return request('POST', data, GQ_CREATE_COURSE);
    }

    getCommonData(query) {
        return request('POST', {}, query);
    }

    sendNotification(data){
        return request('POST', data, GQ_ADMIN_SEND_NOTIFICATION);
    }

    getFilterCareers(){
        return request('POST', {}, GQ_GET_CAREERS_MAPPING);
    }

    getLearningNeeds(){
        return request('POST', {}, GQ_GET_LEARNING_NEEDS);
    }

    getUniqueTalents(){
        return request('POST', {}, GQ_GET_TALENT_NEEDS);
    }

    getTraitsMapping(){
        return request('POST', {}, GQ_GET_TRAITS_MAPPING);
    }

    sendEmail(data){
        return request('POST', data, GQ_ADMIN_SEND_EMAIL);
    }

    addGroup(data){
        return request('POST', data, GQ_ADMIN_ADD_GROUP);
    }

    getAllNotifications(){
        return request('POST', {}, GQ_GET_ALL_NOTIFICATIONS);
    }

    getGroupNotificationsEmails(data){
        return request('POST', data, GQ_GET_GROUP_NOTIFICATION_EMAIl_LOGS);
    }

    createEvent(data){
        return request('POST', data, GQ_ADD_EVENT);
    }

    updateEvent(data){
        return request('POST', data, GQ_UPDATE_EVENT);
    }

    dailyEvent(data){
        return request('POST', data, GQ_DAILY_EVENT);
    }

    manageFreeSlot(data){
        return request('POST', data, GQ_CREATE_FREE_SLOT);
    }


    CreateFreeSlotGuidance(data){
        return request('POST', data, GQ_CREATE_FREE_SLOT_GUIDANCE);
    }


    getFreeSlot(data){
        return request('POST', data, GQ_GET_FREE_SLOTS);
    }

    getBookedPendingCourses(data){
        return request('POST', data, GQ_BOOKED_PENDING_DEMO_COURSES);
    }

    getChildrenDemoBookingRequest(data){
        return request('POST', data, GQ_GET_CHILDREN_DEMO_BOOKING_REQUEST);
    }


    getCounsellingRequest (data){
        return request('POST', data, GQ_GET_COUNSELLINGREQUEST);
    }

    getChildrenDemoPendingRequest(data){
        return request('POST', data, GQ_CHILDREN_DEMO_PENDING_REQUEST);
    }

    demoJoinedStatus(data){
        return request('POST', data, GQ_DEMO_JOINED_STATUS);
    }


    getBookedConfirmRequest(data){
        return request('POST', data, GQ_GET_CONFIRM_REQUEST);
    }

    getCalenderBookedRequest(data){
        return request('POST', data, GQ_GET_CALENDAR_BOOKED_REQUEST);
    }

    counsellorSessionPayment(data){
        return request('POST', data, GQ_COUNSELLING_PAYMENT);
    }

    bookCounsellorSession(data){
        return request('POST', data, GQ_BOOK_COUNSELLOR_SESSION);
    }

    bookDemoSession(data){
        return request('POST', data, GQ_BOOK_PROVIDER_DEMO);
    }

    removeFreeSlot(data){
        return request('POST', data, GQ_DELETE_FREE_SLOT);
    }

    getUserEvents(data){
        return request('POST', data, GQ_GET_USER_EVENTS);
    }

    getEventByDate(data){
        return request('POST', data, GQ_GET_EVENT_BY_DATE);
    }

    getEventByMonth(data){
        return request('POST', data, GQ_GET_EVENT_BY_MONTH);
    }

    deleteEvent(data){
        return request('POST', data, GQ_DELETE_EVENT);
    }

    getCourseProvider(){
        return request('POST', {"query" : ""}, GQ_GET_COURSE_PROVIDERS);
    }

    addCourseProvider(data){
        return request('POST', data, GQ_ADD_COURSE_PROVIDERS);
    }


    //Test API's
    getTdpSummary(data){
        return request('POST', data, GQ_GET_TDP_SUMMARY);
    }

    getTDPExamDetails(data){
        return request('POST', data, GQ_TDP_EXAM_DETAILS);
    }
    GetParentPopUpStatusTDP(data){
        return request('POST', data, GQ_PARENT_POPUPSTATUS);
    }

    submitTDPExam(data){
        return request('POST', data, GQ_SUBMIT_TDP_EXAM);
    }

    handleRequestAccess(data){
        return request('POST', data, GQ_HANDLE_REQUEST_ACCESS);
    }

    exitTDPExam(data){
        return request('POST', data, GQ_EXIT_TDP_TEST);
    }

    getTDPProgress(data){
        return request('POST', data, GQ_GET_TDP_PROGRESS);
    }

    getTDPReport(data){
        return request('POST', data, GQ_GET_TDP_REPORT);
    }

    getMultiDimensionCognitiveReport(data){
        return request('POST', data, GET_MULTI_DIMENSION_COGNITIVE_REPORT);
    }

    assignDimensionCelebrity(data){
        return request('POST', data, GQ_ASSIGN_DIMENSION_CELEBRITY);
    }

    generatePdfReportId(data) {
        return request('POST', data, GQ_GENERATE_PDF_REPORT_ID);
    }

    getDimensionCognitiveReport(data){
        return request('POST', data, GQ_GET_DIMENSION_COGNITIVE_REPORT);
    }

    createTDPReport(data){
        return request('POST', data, GQ_CREATE_TDP_REPORT);
    }

    updateGuidelineConfirmation(){
        return request('POST', {}, GQ_UPDATE_GUIDELINE);
    }


    // FEEDBACK

    submitFeedback(data){
        return request('POST', data, GQ_SUBMIT_FEEDBACK);
    }

    Logout(data){
        return request('POST', data, GQ_LOGOUT);
    }

    getFeedbackSection(){
        return request('POST', {}, GQ_GET_FEEDBACK_SECTION);
    }

    submitGuidanceFeedback(data){
        return request('POST', data, GQ_SUBMIT_GUIDANCE_FEEDBACK);
    }
    // Entire Feedback

    getSubmittedUserSurvey(data){
        return request('POST', data, GQ_GET_SUBMITTED_USER_SURVEY);
    }

    getListOfSurvey(data){
        return request('POST', data, GQ_GET_LIST_OF_SURVEY);
    }
    submitUserSurvey(data){
        return request('POST', data, GQ_SUBMIT_USER_SURVEY);
    }

    //Report
    getOverlayReport(){
        return request('POST', {}, GQ_GET_OVERVIEW_TEMPLATE);
    }

    getPolygonAnalysisReport(data){
        return request('POST', data, GQ_GET_POLYGON_ANALYSIS_REPORT);
    }
    updateReportStatus(data){
        return request('POST', data, GQ_GET_UPDATE_REPORT);
    }


    GetCognitiveProfileStrength (data){
        return request('POST', data, GQ_GET_CONGNITIVE_REPORT);
    }


    getDimensionInfo (){
        return request('POST', {}, GQ_GET_DIMENSION_INFO);
    }


    updateChildStage(data){
        return request('POST', data, GQ_UPDATE_CHILD_STAGE);
    }

    updateChildSectionNumber(data){
        return request('POST', data, GQ_UPDATE_CHILD_SECTION_ORDER);
    }

    updateChildInitialRequest(data){
        return request('POST', data, GQ_UPDATE_CHILD_INITIAL_REQUEST);
    }
    getCounsellingStatus(data){
        return request('POST', data, GQ_GET_COUNSELLINGSESSION);
    }
    
    updateCounsellingSession(data){
        return request('POST', data, GQ_UPDATE_COUNSELLINGSESSION);
    }


    //Assign Teacher
    assignTeacherToStudent(data){
        return request('POST', data, GQ_ASSIGN_TEACHER_TO_STUDENT);
    }

    updateStudentSecondaryParent(data){
        return request('POST', data, GQ_UPDATE_STUDENT_PARENT_SECONDARY_INFO);
    }






    UpdateBillingInfo(data) {
        return request('POST', data, GQ_UPDATE_BILING_INFO);
    }

    createOrder(data) {
        return request('POST', data, GQ_CREATE_TDP_ORDER);
    }

    createOrderInternational(data) {
        return request('POST', data, GQ_CREATE_INTERNATIONAL_TDP_ORDER);
    }

    getServiceDetails(data) {
        return request('POST', data, GQ_GETSERVICEDETAILS);
    }


    AddCoupon(data) {
        return request('POST', data, GQ_APPLYCOPUN);
    }
    
    DownloadInvoice(data) {
        return request('POST', data, GQ_DOWNLOADINVOICE);
    }
    startFreeAssessment(data)
    {
        return request('POST', data, GQ_FREE_ASSESSMENT_PAID_USER);
    }

    getFreeAssessmentQuestions(data){
        return request('POST', data, GQ_GET_FREE_ASSESSMENT_QUESTIONS)
    }
    SubmitFreeAssessmentPaidUser(data){
        return request('POST', data, GQ_SUBMIT_FREE_ASSESSMENT_PAID_USER)
    }
    getFreeAssessmentQuePaidUser(data){
        return request('POST', data, GQ_GET_FREE_ASSESSMENT_QUE_PAID_USER)
    }
    CreateFreeAssessmentReportPaidUser(data){
        return request('POST', data, GQ_CREATE_FREE_ASSESSMENT_PAID_USER)
    }

    getFreeAssessmentReport(data){
        return request('POST', data, GQ_GET_FREE_ASSESSMENT_REPORT)
    }

    deleteParentAccountSurvey(data){
        return request('POST',data, GQ_DELETE_PARENT_ACCOUNT_SURVEY)
    }

    deleteArchivedUser(data){
        return request('POST', data, GQ_DELETE_ARCHIVED_USER)
    }
}
