import React, { useEffect, useLayoutEffect, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import { useRouter } from "next/router";
import APIService from "../../@mock-api/api.service";
import {EventEmitter} from "../../helper";


const apiService = new APIService();

export default function Layout({ children, router }) {
  const [showSideBar, setSideBar] = useState(true);
  const [showHeader, setHeader] = useState(true);
  const [isFirstTime, setIsFirsTime] = useState(true);
  const isBrowser = () => typeof window !== "undefined";
  const userDetail =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : "";
  const privatePath =
    typeof window !== "undefined"
      ? localStorage.getItem("private_path")
      : "";

  const condition = router.pathname;

  useLayoutEffect(() => {
    if (router.asPath == "/") {
      if (!localStorage.getItem("aark_auth")) {
        if (
          !router.pathname.includes("/email-verification") &&
          !router.pathname.includes("/terms") &&
          !router.pathname.includes("/generate-password/[token]")
        ) {
          router.push("/");
        }
      }
    } else {
      setTimeout(() => {
        if (!localStorage.getItem("aark_auth")) {
          console.info(router.asPath);
          console.info(router.pathname);
          if (router.asPath != "/") {
            localStorage.setItem("private_path", router.asPath);
            if (
                !router.pathname.includes("/email-verification") &&
                !router.pathname.includes("/terms") &&
                !router.pathname.includes("/generate-password/[token]") &&
                !router.pathname.includes("/pdf")
            ) {
              router.push("/");
            }
          }
        }
      }, 4000);
    }
  }, [router.asPath]);



  useEffect(()=>{
    setTimeout(() => {
      if(userDetail && (router.query.id || router.query.child_id)){
      if(isFirstTime){
        localStorage.setItem("private_path", router.asPath);
        setIsFirsTime(false);
      }
    }
    }, 700);
  },[router.asPath])

  // useEffect(async () => {
  //   if (userDetail) {
 
  //     apiService.getChildByParent({ getChildrenId: userDetail.id }).then((success) => {
  //       if (success?.data?.data?.getChildren?.length > 0) {
  //         if (router?.query?.child_id || router?.query?.id) {
  //           let validChild = success?.data?.data?.getChildren?.find(
  //             (val) => val.id == (router.query?.id ? router.query?.id:router?.query?.child_id)
  //           );
  //           if (validChild && privatePath) {
  //             apiService.singleChildDetail({ childId: validChild.id }).then((res) => {
  //               if (res.data.data && res.data.data.child) {
  //                 localStorage.setItem(
  //                     "childDetails",
  //                     JSON.stringify(res.data.data.child)
  //                 );
  //                 localStorage.setItem(
  //                     "child",
  //                     JSON.stringify(validChild)
  //                 );
  //                 localStorage.setItem('selected_child', validChild.id);
  //                 router.push(privatePath);
  //                 localStorage.removeItem('private_path');
  //                 setTimeout(()=>{
  //                   EventEmitter.dispatch("updateChildInfo", res.data.data.child);
  //                   if(privatePath.includes('/assessment')){
  //                     EventEmitter.dispatch("updateTDPSummary", res.data.data.child);
  //                   }
  //                  else if(privatePath.includes('/gradeassessment')){
  //                     EventEmitter.dispatch("updateTDPSummary", res.data.data.child);
  //                   }
  //                   else if(privatePath.includes('/calendar')){
  //                     EventEmitter.dispatch("callChildFunction", res.data.data.child);
  //                   }else if(privatePath.includes('/report')){
  //                     EventEmitter.dispatch("updateChildInfoReport", {child: res.data.data.child});
  //                   }
  //                 }, 1000)
  //               } else {
  //                 console.log("error is", res.data);
  //               }
  //             });
  //           }
  //         }
  //       }
  //     });
  //   }
  // }, [privatePath]);

  useEffect(() => {
    if (router.pathname) {
      if (
        router.pathname.includes("/faq") ||
        router.pathname.includes("/calendar") ||
        router.pathname.includes("/referral") ||
        router.pathname.includes("/wishlist") ||
        router.pathname.includes("/terms") ||
        router.pathname.includes("/pdf") ||
        router.pathname.includes("/404") ||
        router.pathname.includes("/profile") ||
        router.pathname.includes("teacher-assignment")||
        router.pathname.includes("/talent-blossom")||
        router.pathname.includes("/global-talent-blossom")||
        router.pathname.includes("/talent-counselling")||
        router.pathname.includes("/talent-blossom-parent")||
        router.pathname.includes("/checkout-parent")||
        router.pathname.includes("/paymentsuccessful-999")||
        router.pathname.includes("/global-checkout-parent")||
        router.pathname.includes("/globalparentpaymentfailed")||
        router.pathname.includes("/paymentsuccessful-19")||
        router.pathname.includes("/global-checkout")||
        router.pathname.includes("/globalpaymentfailed")||
        router.pathname.includes("/globalpaymentsuccessful")||
        router.pathname.includes("/global-talent-blossom-parent")||
        router.pathname.includes("/talent-blossom-moral")||
        router.pathname.includes("/checkout-moral")||
        router.pathname.includes("/paymentsuccessful-moral-999") ||
        router.pathname.includes("/global-moral-talent-blossom") ||
        router.pathname.includes("/paymentsuccessful-16")

      ) {
        setSideBar(false);
      } else {
        setSideBar(true);
      }
    }
  }, [router.pathname]);

  useEffect(() => {
    if (router.pathname) {
      if (
        router.pathname.includes("/404") ||
        router.pathname.includes("assessment/terms") ||
        router.pathname.includes("/pdf") ||
        router.pathname.includes("teacher-assignment")
      ) {
        setHeader(false);
      } else {
        setHeader(true);
      }
    }
  }, [router.pathname]);

  if (
    condition === "/" ||
    condition === "/register" ||
    condition === "/terms" ||
    condition.includes('/pdf') ||
    condition.includes("/email-verification") ||
    condition === "/generate-password/[token]" ||
    condition.includes("/teacher-assignment")
  ) {
    return <>{children}</>;
  }
  else if (
    condition === "/talent-assessment" ||
    condition === "/global-talent-blossom" ||
    condition === "/talent-blossom" ||
    condition === "/talent-guidance" ||
    condition === "/paymentsuccessful"||
    condition === "/paymentfailed"||
    condition === "/checkout" ||
    condition === "/talent-counselling" ||
    router.pathname.includes("/talent-blossom-parent")||
        router.pathname.includes("/checkout-parent")||
        router.pathname.includes("/paymentsuccessful-999")||
        router.pathname.includes("/global-checkout")||
        router.pathname.includes("/globalpaymentfailed")||
        router.pathname.includes("/globalpaymentsuccessful")||
        router.pathname.includes("/global-talent-blossom-parent")||
        router.pathname.includes("/global-checkout-parent")||
        router.pathname.includes("/globalparentpaymentfailed")||
        router.pathname.includes("/paymentsuccessful-19")||
        router.pathname.includes("/talent-blossom-moral")||
        router.pathname.includes("/checkout-moral")||
        router.pathname.includes("/paymentsuccessful-moral-999") ||
        router.pathname.includes("/global-moral-talent-blossom") ||
        router.pathname.includes("/global-checkout-moral")||
        router.pathname.includes("/paymentsuccessful-16")

  ) {
    return <>
    <Header />
    {children}
    </>;
  }
  
  else {
    return (
      <>
        {userDetail && (
          <div className="container-fluid " id="mainlayout">
            <div className="col-12 app-content px-3">
              <div className="row overflow-visible blur">
                <div
                  id={"category_index"}
                  className={
                    "col main-content " +
                    (showSideBar ? "" : "full-layout") +
                    (router.pathname.includes("/report/") ? " report-bg" : "")
                  }
                >
                  {showSideBar && <Sidebar />}
                  {showHeader && <Header />}
                  <div className="col-12 profile-view">{children}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
