export default {
    Exam_Details : `${process.env.NEXT_PUBLIC_API_NODE}/getTDPExamDetails`,
    submit_TDPExam: `${process.env.NEXT_PUBLIC_API_NODE}/submitTDPExam`,
    get_TDPSummary:`${process.env.NEXT_PUBLIC_API_NODE}/getTDPSummary`,
    getTDPProgress:`${process.env.NEXT_PUBLIC_API_NODE}/getTDPProgress`,
    get_IntroReport: `${process.env.NEXT_PUBLIC_API_NODE}/fetchChildTalentReport`,
    get_ChildReportHind: `${process.env.NEXT_PUBLIC_API_NODE}/fetchChildTalentReport`,
    create_ChildTalentReport:`${process.env.NEXT_PUBLIC_API_NODE}/createChildTalentReport`,
    create_InterestTestReport:`${process.env.NEXT_PUBLIC_API_NODE}/createInterestTestReport`,
    createTDPExitMessage:`${process.env.NEXT_PUBLIC_API_NODE}/createTDPExitMessage`,
    getParentByChild:`${process.env.NEXT_PUBLIC_API_NODE}/getParentByChild`,
    getTDPReport:`${process.env.NEXT_PUBLIC_API_NODE}/getTDPReport`,
    getTDPReportv3:`${process.env.NEXT_PUBLIC_API_NODE}/getTDPReportv3`,
    generateReportPdf :`${process.env.NEXT_PUBLIC_API_NODE}/generateReportPdf `,
    getTDPSummaryV3:`${process.env.NEXT_PUBLIC_API_NODE}/getTDPSummaryV3`,
    getTDPExamDetailsV3 : `${process.env.NEXT_PUBLIC_API_NODE}/getTDPExamDetailsV3`,
    submitTDPExamV3: `${process.env.NEXT_PUBLIC_API_NODE}/submitTDPExamV3`,
    getTDPProgressV3:`${process.env.NEXT_PUBLIC_API_NODE}/getTDPProgressV3`,
    getOldChildDetails:`${process.env.NEXT_PUBLIC_API_NODE}/getOldChildDetails`,
    getChildDetails:`${process.env.NEXT_PUBLIC_API_NODE}/child`,
    fetchCombinedSchoolReport: `${process.env.NEXT_PUBLIC_API_NODE}/fetchChildcombineTalentReport`,
    getParentTestSummary: `${process.env.NEXT_PUBLIC_API_NODE}/getParentTestSummary`,
    getParentingExamDetails: `${process.env.NEXT_PUBLIC_API_NODE}/getParentingExamDetails`,
    submitParentingStyleTestDetails: `${process.env.NEXT_PUBLIC_API_NODE}/submitParentingStyleTestDetails`,
    createParentStylingTestReport: `${process.env.NEXT_PUBLIC_API_NODE}/createParentStylingTestReport`,
    getChildExamOverallStatus: `${process.env.NEXT_PUBLIC_API_NODE}/getChildExamOverallStatus`,
    getParentStylingTestReport:`${process.env.NEXT_PUBLIC_API_NODE}/getParentStylingTestReport`,
    generateParentTestReportPdf:`${process.env.NEXT_PUBLIC_API_NODE}/generateParentTestReportPdf`,
    getMoralTestSummary: `${process.env.NEXT_PUBLIC_API_NODE}/getMoralTestSummary`,
    getMoralTestDetails: `${process.env.NEXT_PUBLIC_API_NODE}/getMoralExamDetails`,
    submitMoralTestDetails: `${process.env.NEXT_PUBLIC_API_NODE}/submitMoralTestDetails`,
    getMoralTestDetailsV3: `${process.env.NEXT_PUBLIC_API_NODE}/getMoralExamDetailsV3`,
    submitMoralTestDetailsV3: `${process.env.NEXT_PUBLIC_API_NODE}/submitMoralTestDetailsV3`,
    createMoralTestReport: `${process.env.NEXT_PUBLIC_API_NODE}/createMoralTestReport`,
    getMoralTestReport:`${process.env.NEXT_PUBLIC_API_NODE}/getMoralTestReport`,
    getMoralTestReportPdf:`${process.env.NEXT_PUBLIC_API_NODE}/generateMoralTestReportPdf`,
    generateMoralTestReportPdf:`${process.env.NEXT_PUBLIC_API_NODE}/generateMoralTestReportPdf`,
}
