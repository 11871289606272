const initialValue = {
    totalEarnedCoins:0,
};

export default function getTotalChildEarnedCoins(state = initialValue, action) {
    switch (action.type) {
        case "GET_CHILD_TOTAL_COINS":
            return Object.assign({}, state, {
                totalEarnedCoins: action.totalEarnedCoins,
            });
        default:
            return state;
    }
};
