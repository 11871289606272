import React, {createRef, useEffect, useRef, useState} from "react";
import {Flag2, MedalStar} from 'iconsax-react';
import ProgressBar from "react-bootstrap/ProgressBar";
import {images} from "../../../constant";
import APIService from "../../@mock-api/api.service";
import {EventEmitter} from "../../helper";
import {useRouter} from "next/router";

const apiService = new APIService();

export default function GrowthMobile(props) {
    let [childDetails, setChildDetail] = useState(null);

    const [growthProgress, setGrowthProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const scrollRef = useRef();
    const router = useRouter();
    const [updateRoute, setUpdateRoute] = useState(false);


    const progressData = [
        {id: 1, percent: 14.5, name: 'On-boarding', img: images.growth1.default.src},
        {id: 2, percent: 29.5, name: 'Profiling', img: images.growth2.default.src},
        {id: 3, percent: 44, name: 'Talent Discovery', img: images.growth3.default.src},
        {id: 4, percent: 58.5, name: 'Talent Discovery Report', img: images.growth4.default.src},
        {id: 5, percent: 73, name: 'Learning Plan', img: images.growth6.default.src},
        {id: 6, percent: 87.5, name: 'Learning Journey', img: images.growth7.default.src},
    ];

    useEffect(()=>{
        let progress = document.getElementsByClassName("progress-bar bg-info")
        if(props.growthProgress > 40){
            setGrowthProgress(100.5);
            for (let i = 0; i < progress.length; i++) {
                progress[i].style.height = 100.5 + "%";
            }
            handleScroll(props.growthProgress)
        }else {
            if(props.growthProgress < 26 && props.growthProgress > 24){
                setGrowthProgress(29.5);
                for (let i = 0; i < progress.length; i++) {
                    progress[i].style.height = 29.5 + "%";
                }
                handleScroll(29.5)
            }else {
                setGrowthProgress(props.growthProgress + 2)
                for (let i = 0; i < progress.length; i++) {
                    progress[i].style.height = (props.growthProgress + 2) + "%";
                }
                handleScroll(props.growthProgress + 2)
            }
        }

    },[props.growthProgress])

    useEffect(()=>{
        setChildDetail(props.child);
    },[props.child])


    useEffect(()=>{
        setShowProgress(props.showProgressBar)
    },[props.showProgressBar]);

    const handleScroll = (currentGrowthProgress) => {
        let currentQuestion = progressData.find((val)=> val.percent >= currentGrowthProgress);
        if(currentQuestion && scrollRef.current) {
            let percent = ((currentQuestion.id) / 6) * 100;
            let offset = scrollRef.current.offsetHeight;
            let scrollPosition = (percent * offset) / 100;
            scrollRef.current.scrollTop = scrollPosition;
        }

        let child =  localStorage.getItem('child')
        if (child) {
            setChildDetail(JSON.parse(child));
        }
    };


    return (
        <>
            <div className={"col-12 d-lg-none d-block growth-main " + (showProgress ? "open":"")}>
                <div className="col-12 card growth-card">
                    <div className="col-12 header d-flex align-items-center justify-content-between ps-3">
                        <h4 className="mb-0 text-blue fw-bold">Growth Journey</h4>
                        <a onClick={() => {
                            props.handleShowHide()
                            setShowProgress(false)
                        }}>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                                    fill="#2A2A84"/>
                                <path
                                    d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                                    fill="#2A2A84"/>
                            </svg>
                        </a>
                    </div>
                    <div className="col-12 sec-main scr-yellow" ref={scrollRef}>
                        {progressData.reverse().map((item, index)=>
                            <div key={'progress_growth_' + index} className={"sec-card " + (growthProgress >= item.percent ? "active":'') }>
                                <div className={"icon " + (growthProgress == item.percent ? "active":'') }>
                                    <img
                                        className={"img-fluid rounded-circle " + (growthProgress == item.percent ? "opacity-0":'')}
                                        src={item.img}
                                    />
                                </div>
                                <h5 className="">{childDetails ? childDetails.first_name:''}<span className="text-lowercase">’s</span><br/>{item.name}</h5>
                            </div>
                        )}
                        <div className="img-path">
                            <div className="img-progress" style={{ height : (Math.round(growthProgress * 10) / 10) + "%"}}>
                                <div className="img-child">
                                    <div className={"icon " + (progressData.find((val)=> parseFloat(val) == parseFloat(growthProgress)) ? "active":"")}>
                                        <img
                                            className="img-fluid rounded-circle"
                                            src={progressData.find((val)=> val > growthProgress) ? progressData.find((val)=> val > growthProgress).img:progressData[0].img}
                                        />
                                    </div>
                                    <img
                                        className="img-fluid rounded-circle"
                                        src={childDetails ? childDetails.image:images.mascot.default.src}
                                        // style={{ left : this.state.dailyScheduleProgress + "%" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <ProgressBar
                            variant={'info'}

                            //  style={{height: growthProgress}}
                            now={growthProgress}/>
                    </div>
                </div>
                <div className="col-12 growth-btn" onClick={() => {
                    props.handleShowHide()
                    setShowProgress(false)
                }}>
                    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 20.3475C15 20.5134 14.9394 20.6792 14.8097 20.8102C14.5588 21.0633 14.1435 21.0633 13.8926 20.8102L8.25162 15.1191C7.83634 14.7001 7.1615 14.7001 6.74622 15.1191L1.10526 20.8102C0.854362 21.0633 0.439077 21.0633 0.188176 20.8102C-0.0627253 20.557 -0.0627253 20.138 0.188176 19.8849L5.82913 14.1939C6.27037 13.7487 6.86734 13.4956 7.49892 13.4956C8.1305 13.4956 8.72747 13.74 9.16871 14.1939L14.8097 19.8849C14.9308 20.0158 15 20.1817 15 20.3475Z" fill="#2A2A84"/>
                        <path d="M15 13.5997C15 13.7656 14.9394 13.9314 14.8097 14.0623C14.5588 14.3155 14.1435 14.3155 13.8926 14.0623L8.25162 8.37128C7.83634 7.95231 7.1615 7.95231 6.74622 8.37128L1.10526 14.0623C0.854362 14.3155 0.439077 14.3155 0.188176 14.0623C-0.0627253 13.8092 -0.0627253 13.3902 0.188176 13.1371L5.82913 7.44605C6.27037 7.00089 6.86734 6.74776 7.49892 6.74776C8.1305 6.74776 8.72747 6.99216 9.16871 7.44605L14.8097 13.1371C14.9308 13.268 15 13.4339 15 13.5997Z" fill="#2A2A84"/>
                        <path d="M15 6.85199C15 7.01783 14.9394 7.18368 14.8097 7.31461C14.5588 7.56774 14.1435 7.56774 13.8926 7.31461L8.25162 1.62354C7.83634 1.20457 7.1615 1.20457 6.74622 1.62354L1.10526 7.31461C0.854362 7.56774 0.439077 7.56774 0.188176 7.31461C-0.0627253 7.06148 -0.0627253 6.6425 0.188176 6.38937L5.82913 0.698308C6.27037 0.253148 6.86734 1.81198e-05 7.49892 1.81198e-05C8.1305 1.81198e-05 8.72747 0.24442 9.16871 0.698308L14.8097 6.38937C14.9308 6.5203 15 6.68615 15 6.85199Z" fill="#2A2A84"/>
                    </svg>
                    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 20.3475C15 20.5134 14.9394 20.6792 14.8097 20.8102C14.5588 21.0633 14.1435 21.0633 13.8926 20.8102L8.25162 15.1191C7.83634 14.7001 7.1615 14.7001 6.74622 15.1191L1.10526 20.8102C0.854362 21.0633 0.439077 21.0633 0.188176 20.8102C-0.0627253 20.557 -0.0627253 20.138 0.188176 19.8849L5.82913 14.1939C6.27037 13.7487 6.86734 13.4956 7.49892 13.4956C8.1305 13.4956 8.72747 13.74 9.16871 14.1939L14.8097 19.8849C14.9308 20.0158 15 20.1817 15 20.3475Z" fill="#2A2A84"/>
                        <path d="M15 13.5997C15 13.7656 14.9394 13.9314 14.8097 14.0623C14.5588 14.3155 14.1435 14.3155 13.8926 14.0623L8.25162 8.37128C7.83634 7.95231 7.1615 7.95231 6.74622 8.37128L1.10526 14.0623C0.854362 14.3155 0.439077 14.3155 0.188176 14.0623C-0.0627253 13.8092 -0.0627253 13.3902 0.188176 13.1371L5.82913 7.44605C6.27037 7.00089 6.86734 6.74776 7.49892 6.74776C8.1305 6.74776 8.72747 6.99216 9.16871 7.44605L14.8097 13.1371C14.9308 13.268 15 13.4339 15 13.5997Z" fill="#2A2A84"/>
                        <path d="M15 6.85199C15 7.01783 14.9394 7.18368 14.8097 7.31461C14.5588 7.56774 14.1435 7.56774 13.8926 7.31461L8.25162 1.62354C7.83634 1.20457 7.1615 1.20457 6.74622 1.62354L1.10526 7.31461C0.854362 7.56774 0.439077 7.56774 0.188176 7.31461C-0.0627253 7.06148 -0.0627253 6.6425 0.188176 6.38937L5.82913 0.698308C6.27037 0.253148 6.86734 1.81198e-05 7.49892 1.81198e-05C8.1305 1.81198e-05 8.72747 0.24442 9.16871 0.698308L14.8097 6.38937C14.9308 6.5203 15 6.68615 15 6.85199Z" fill="#2A2A84"/>
                    </svg>
                </div>
            </div>
        </>
    )
}
