export const GQ_FREE_ASSESSMENT_PAID_USER = `mutation StartFreeAssessment($user : parentEarlyAccessInput){
  startFreeAssessment(user: $user){
      id
  }
}`;


export const GQ_GET_FREE_ASSESSMENT_QUESTIONS = `query GetFreeAssessmentQuestions($child_grade: Int,$userId: String) {
    getFreeAssessmentQuestions(child_grade: $child_grade, userId: $userId) {
         userId
        dimension_name
          dimension_id
      exam_questions {
        question_id
        question_text
        question_image_url
        question_type
        question_options {
          option_text
          option_image_url
          score
        }
        question_answered
      } 
       exam_status
      exam_next_question_id
      exam_correct_option
    }
  }`;

  export const GQ_SUBMIT_FREE_ASSESSMENT_PAID_USER = `mutation SubmitFreeAssessmentPaidUser($input: FreeAssessmentInput) {
    submitFreeAssessmentPaidUser(input: $input) {
      id
      childId
      dimension_id
      dimension_name
      exam_questions {
        question_id
        question_text
        question_image_url
        question_type
        question_options {
          option_text
          option_image_url
          score
        }
        question_answered
      }
      exam_status
      exam_next_question_id
      exam_correct_option
    }
  }`;
  // GetFreeAssessmentQuePaidUser


  
export const GQ_GET_FREE_ASSESSMENT_QUE_PAID_USER = `query GetFreeAssessmentQuePaidUser($childId: String,$userId: String) {
  getFreeAssessmentQuePaidUser(childId: $childId, userId: $userId) {
       userId
       childId
      dimension_name
        dimension_id
    exam_questions {
      question_id
      question_text
      question_image_url
      question_type
      question_options {
        option_text
        option_image_url
        score
      }
      question_answered
    } 
     exam_status
    exam_next_question_id
    exam_correct_option
  }
}`;


export const GQ_CREATE_FREE_ASSESSMENT_PAID_USER = `mutation CreateFreeAssessmentReportPaidUser($userId: String, $childId: String, $dimension_id: String, $dimension_name: String) {
  createFreeAssessmentReportPaidUser(userId: $userId, childId: $childId, dimension_id: $dimension_id, dimension_name: $dimension_name) {
      id
      score
      parent
      childId
      celebrity{
          celebrity_name
          celebrity_role 
          celebrity_info
          celebrity_hover_info
          celebrity_picture_url
      }
      allotted_score_label
      personality_traits
  }
}`;


export const GQ_GET_FREE_ASSESSMENT_REPORT = `query getFreeAssessmentReport($id: String){
  getFreeAssessmentReport(id: $id){
       id
    score
    parent
    celebrity{
        celebrity_name
        celebrity_role 
        celebrity_info
        celebrity_hover_info
        celebrity_picture_url
    }
    allotted_score_label
    personality_traits
  }
}`;


export const GQ_GET_DASHBOARD_DETAILS = `query GetPlansDetails($id: String, $childId: String) {
  getPlansDetails(id : $id, childId: $childId) {
      talent_discovery_bundle
      talent_guidance
      talent_assessment
      free_call
      free_assessment
      free_assessement_id
      assessment_status
      guidance_slots
      new_user
      free_assessment_user
      counselling
      counselling_status
      report_status
      start_time
      start_day
      meeting_url
      reschedule_url
      feedback
      request_id
      teacher_assigned
      parenting_style_test
      child_grade
      global_user
      moral_test
  }
}`;
export const GQ_CALLPAIDUSER = `mutation TACallPaidUser($id: String, $location: String, $childId: String){
  TACallPaidUser(id: $id, location: $location, childId: $childId)
}`;

export const GQ_UPDATE_PREMIUM_REPRT = `mutation UpdatePremiumReportStatus($childId : String){
  updatePremiumReportStatus(childId : $childId)
}
`;


