import {Modal} from "react-bootstrap";
import {images} from "../../../constant";
import Lottie from "react-lottie";
import React, {useEffect, useState} from "react";
import {useRouter} from "next/router";
import animationData from '/public/assets/animation/99833-edupia-loading.json'
import reportLoader from '/public/assets/animation/report-loader.json'


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const downloadOptions = {
    loop: true,
    autoplay: true,
    animationData: reportLoader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const ActivityIndicator = ({toggleUnlock, screen}) => {
    const router = useRouter();

    return <Modal
        style={{ zIndex: "1050" }}
        // size={"sm"}
        show={toggleUnlock}
         // show={true}
        id="settings"
        contentClassName={"bg-transparent border-0"}
        dialogClassName={
            "modal-sm modal-dialog-centered bg-transparent"
        }
        aria-labelledby="settings"
        tabIndex="-1"
        backdrop={"static"}
    >
        <Modal.Body className="text-center">
            <img width="200px" src={images.jLoader.default.src} />
            {/*{screen == 'report' ? <h2 className="text-center">Downloading your report</h2>:<h2 className="text-center">Loading</h2>}*/}
        </Modal.Body>
    </Modal>;
}
