const initialValue = {
    getChildren:null,
};

export default function getChildrenReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHILDREN_DETAILS":
            return Object.assign({}, state, {
                getChildren: action.children_details,
            });
        default:
            return state;
    }
};