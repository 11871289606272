import React, { createRef, useEffect, useRef, useState } from "react";
import { Flag2, MedalStar } from "iconsax-react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { images } from "../../../constant";
import APIService from "../../@mock-api/api.service";
import { ERROR_MESSAGE, EventEmitter } from "../../helper";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";

const apiService = new APIService();

export default function GrowthPlanProgress(props) {
  let currentGrowthProgress = 8.3;
  let [childDetails, setChildDetail] = useState(null);

  const [growthProgress, setGrowthProgress] = useState(currentGrowthProgress);
  const [showProgress, setShowProgress] = useState(false);
  const scrollRef = useRef();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [updateRoute, setUpdateRoute] = useState(false);
  const [totalCoins, setTotalCoins] = useState(80);

  const progressData = [
    {
      id: 1,
      percent: 8.3,
      name: "On-boarding",
      img: images.growth1.default.src,
    },
    {
      id: 2,
      percent: 41.7,
      name: "Talent Discovery",
      img: images.growth3.default.src,
    },
    {
      id: 3,
      percent: 58.3,
      name: "Talent Discovery Report",
      img: images.growth4.default.src,
    },
    // {id: 5, percent: 64.35, name: 'Talent Counseling', img: images.growth5.default.src},
    {
      id: 4,
      percent: 75,
      name: "Learning Plan",
      img: images.growth6.default.src,
    },
    {
      id: 5,
      percent: 91.7,
      name: "Learning Journey",
      img: images.growth7.default.src,
    },
  ];

  useEffect(() => {
    EventEmitter.unsubscribe("updateGrowthProgressBar");
    EventEmitter.subscribe("updateGrowthProgressBar", (totalEarnedCoins) => {
      setGrowthProgress(8.3);
      calculateGrowth(totalEarnedCoins);
      setUpdateRoute(!updateRoute);
      setTotalCoins(totalEarnedCoins);
    });
  }, []);

  useEffect(() => {
    calculateGrowth(totalCoins);
    setShowProgress(props.showProgressBar);
  }, [props.showProgressBar]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (scrollRef.current && !scrollRef.current.contains(event.target)) {
        props.handleShowHide();
        setShowProgress(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [scrollRef]);

  const handleScroll = (currentGrowthProgress) => {
    let currentQuestion = progressData.find(
      (val) => val.percent >= currentGrowthProgress
    );
    if (currentQuestion && scrollRef.current) {
      let percent = (currentQuestion.id / 6) * 100;
      let offset = scrollRef.current.offsetWidth;
      let scrollPosition = (percent * offset) / 100;
      scrollRef.current.behavior = 'instant';
      if (router.pathname.includes("/child/")) {
        scrollRef.current.scrollLeft = scrollPosition - 250;
      } else if (currentQuestion.id == 3) {
        scrollRef.current.scrollLeft = offset;
      }
    }

    let child = localStorage.getItem("child");
    if (child) {
      setChildDetail(JSON.parse(child));
    }
  };

  const calculateGrowth = async (totalEarnedCoins) => {
    let mandatory = totalEarnedCoins?.mandatory;
    let non_mandatory = totalEarnedCoins?.non_mandatory;
    let selectedChild = await localStorage.getItem("childDetails");
    let examCompleted = await localStorage.getItem("wishList");
    let isStageCleared = false;
    if (selectedChild) {
      isStageCleared = JSON.parse(selectedChild)?.child_stage_cleared;
    }
    if (selectedChild) {
      if (examCompleted === true) {
        setGrowthProgress(100);
        props.sendProgressValue(100);
        handleScroll(100);
      } else if (isStageCleared) {
        apiService
          .getTdpSummary({
            type: "child",
            dimensionExamUserId: JSON.parse(selectedChild).id,
          })
          .then((res) => {
            let currentTestProgress = 0;
            if (res.data.data && res.data.data.getTDPSummary) {
              let count = res.data.data.getTDPSummary.filter(
                (val) => val.is_exam_completed === true
              );
              currentTestProgress += count.length;
            } else {
              enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
            }
            let percent = (currentTestProgress / 8) * 100 * 0.167;
            let currentGrowthProgress = 25 + percent;
            if (currentGrowthProgress >= 41) {
              setGrowthProgress(100);
              props.sendProgressValue(100);
            } else {
              props.sendProgressValue(currentGrowthProgress);
              setGrowthProgress(currentGrowthProgress);
            }
            handleScroll(26);
          });
      } else {
        let totalMandatoryCoins = 0;
        let totalNonMandatoryCoins = 0;
        await props?.fieldGroup?.map((val) => {
          val.fields.map((item) => {
            if (item?.mandatory) {
              totalMandatoryCoins += item.coins;
            } else {
              totalNonMandatoryCoins += item.coins;
            }
          });
        });
        if (totalMandatoryCoins > 0) {
          if (mandatory >= totalMandatoryCoins) {
            setGrowthProgress(41.7);
            props.sendProgressValue(41.7);
            handleScroll(15);
          } else {
            let currentGrowthProgress = 8.3;
            let totalPercent = (mandatory / totalMandatoryCoins) * 100;
            let percentage = 0.17 * totalPercent + currentGrowthProgress;
            setGrowthProgress(percentage);
            props.sendProgressValue(percentage);
            handleScroll(7);
          }
        } else {
          handleScroll(7);
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          "col-12 d-lg-block d-none  growth-main " +
          (showProgress ? "open" : "")
        }
      >
        <div className="col-12 card growth-card">
          <div className="col-12 sec-main scr-yellow" ref={scrollRef}>
            {progressData.map((item, index) => (
              <div
                key={"progress_growth_" + index}
                className={
                  "sec-card " + (growthProgress >= item.percent ? "active" : "")
                }
              >
                <div
                  className={
                    "icon " + (growthProgress == item.percent ? "active" : "")
                  }
                >
                  <img
                    className={
                      "img-fluid rounded-circle " +
                      (growthProgress == item.percent ? "opacity-0" : "")
                    }
                    src={item.img}
                  />
                </div>
                <h5 className="">
                  {childDetails ? childDetails.first_name : ""}
                  <span className="text-lowercase">’s</span>
                  <br />
                  {item.name}
                </h5>
              </div>
            ))}
            <div className="img-path">
              <div
                className="img-progress"
                style={{ width: Math.round(growthProgress * 10) / 10 + "%" }}
              >
                <div className="img-child">
                  <div
                    className={
                      "icon " +
                      (progressData.find(
                        (val) =>
                          Math.round(val.percent * 10) / 10 ==
                          Math.round(growthProgress * 10) / 10
                      )
                        ? "active"
                        : "")
                    }
                  >
                    <img
                      className="img-fluid rounded-circle"
                      src={
                        progressData.find(
                          (val) =>
                            Math.round(val.percent * 10) / 10 >
                            Math.round(growthProgress * 10) / 10
                        )
                          ? progressData.find(
                              (val) =>
                                Math.round(val.percent * 10) / 10 >
                                Math.round(growthProgress * 10) / 10
                            ).img
                          : progressData[0].img
                      }
                    />
                  </div>
                  <img
                    className="img-fluid rounded-circle"
                    src={
                      childDetails
                        ? childDetails.image
                        : images.mascot.default.src
                    }
                  />
                </div>
              </div>
            </div>
            <ProgressBar now={growthProgress} />
          </div>
        </div>
        <div
          className="col-12 growth-btn"
          onClick={() => {
            props.handleShowHide();
            setShowProgress(false);
          }}
        >
          <svg
            width="15"
            height="21"
            viewBox="0 0 15 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 20.3475C15 20.5134 14.9394 20.6792 14.8097 20.8102C14.5588 21.0633 14.1435 21.0633 13.8926 20.8102L8.25162 15.1191C7.83634 14.7001 7.1615 14.7001 6.74622 15.1191L1.10526 20.8102C0.854362 21.0633 0.439077 21.0633 0.188176 20.8102C-0.0627253 20.557 -0.0627253 20.138 0.188176 19.8849L5.82913 14.1939C6.27037 13.7487 6.86734 13.4956 7.49892 13.4956C8.1305 13.4956 8.72747 13.74 9.16871 14.1939L14.8097 19.8849C14.9308 20.0158 15 20.1817 15 20.3475Z"
              fill="#2A2A84"
            />
            <path
              d="M15 13.5997C15 13.7656 14.9394 13.9314 14.8097 14.0623C14.5588 14.3155 14.1435 14.3155 13.8926 14.0623L8.25162 8.37128C7.83634 7.95231 7.1615 7.95231 6.74622 8.37128L1.10526 14.0623C0.854362 14.3155 0.439077 14.3155 0.188176 14.0623C-0.0627253 13.8092 -0.0627253 13.3902 0.188176 13.1371L5.82913 7.44605C6.27037 7.00089 6.86734 6.74776 7.49892 6.74776C8.1305 6.74776 8.72747 6.99216 9.16871 7.44605L14.8097 13.1371C14.9308 13.268 15 13.4339 15 13.5997Z"
              fill="#2A2A84"
            />
            <path
              d="M15 6.85199C15 7.01783 14.9394 7.18368 14.8097 7.31461C14.5588 7.56774 14.1435 7.56774 13.8926 7.31461L8.25162 1.62354C7.83634 1.20457 7.1615 1.20457 6.74622 1.62354L1.10526 7.31461C0.854362 7.56774 0.439077 7.56774 0.188176 7.31461C-0.0627253 7.06148 -0.0627253 6.6425 0.188176 6.38937L5.82913 0.698308C6.27037 0.253148 6.86734 1.81198e-05 7.49892 1.81198e-05C8.1305 1.81198e-05 8.72747 0.24442 9.16871 0.698308L14.8097 6.38937C14.9308 6.5203 15 6.68615 15 6.85199Z"
              fill="#2A2A84"
            />
          </svg>
          <svg
            width="15"
            height="21"
            viewBox="0 0 15 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 20.3475C15 20.5134 14.9394 20.6792 14.8097 20.8102C14.5588 21.0633 14.1435 21.0633 13.8926 20.8102L8.25162 15.1191C7.83634 14.7001 7.1615 14.7001 6.74622 15.1191L1.10526 20.8102C0.854362 21.0633 0.439077 21.0633 0.188176 20.8102C-0.0627253 20.557 -0.0627253 20.138 0.188176 19.8849L5.82913 14.1939C6.27037 13.7487 6.86734 13.4956 7.49892 13.4956C8.1305 13.4956 8.72747 13.74 9.16871 14.1939L14.8097 19.8849C14.9308 20.0158 15 20.1817 15 20.3475Z"
              fill="#2A2A84"
            />
            <path
              d="M15 13.5997C15 13.7656 14.9394 13.9314 14.8097 14.0623C14.5588 14.3155 14.1435 14.3155 13.8926 14.0623L8.25162 8.37128C7.83634 7.95231 7.1615 7.95231 6.74622 8.37128L1.10526 14.0623C0.854362 14.3155 0.439077 14.3155 0.188176 14.0623C-0.0627253 13.8092 -0.0627253 13.3902 0.188176 13.1371L5.82913 7.44605C6.27037 7.00089 6.86734 6.74776 7.49892 6.74776C8.1305 6.74776 8.72747 6.99216 9.16871 7.44605L14.8097 13.1371C14.9308 13.268 15 13.4339 15 13.5997Z"
              fill="#2A2A84"
            />
            <path
              d="M15 6.85199C15 7.01783 14.9394 7.18368 14.8097 7.31461C14.5588 7.56774 14.1435 7.56774 13.8926 7.31461L8.25162 1.62354C7.83634 1.20457 7.1615 1.20457 6.74622 1.62354L1.10526 7.31461C0.854362 7.56774 0.439077 7.56774 0.188176 7.31461C-0.0627253 7.06148 -0.0627253 6.6425 0.188176 6.38937L5.82913 0.698308C6.27037 0.253148 6.86734 1.81198e-05 7.49892 1.81198e-05C8.1305 1.81198e-05 8.72747 0.24442 9.16871 0.698308L14.8097 6.38937C14.9308 6.5203 15 6.68615 15 6.85199Z"
              fill="#2A2A84"
            />
          </svg>
        </div>
      </div>
    </>
  );
}
