import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'


export default function ToolTipModal(props){
    const {children,text}=props
    return(
        <>
           <OverlayTrigger
                placement="auto"
                delay={{ show: 250, hide: 400 }}
                // show={true}
                tex={"text"}
                overlay={
                    text ? <Tooltip id="button-tooltip" {...props} >
                        {text}
                    </Tooltip>:<></>
                }
            >
                {children}
            </OverlayTrigger>
        </>
    )

}

