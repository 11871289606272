export const GQ_GET_CATEGORIES = `query Query {
  getAllCategories {
    category
    id
  }
}`;

export const GQ_GET_PROVIDER_CATEGORIES = `query GetAllCategories {
  getAllProviderCategory {
    category
    id
  }
}`;

export const GQ_SET_MEDIA_LOG = `mutation FileUpload($path: String, $mimetype: String, $type: String) {
  fileUpload(path: $path, mimetype: $mimetype, type: $type) {
    id
  }
}`;

export const GQ_GET_STORED_MEDIA_BY_TYPE = `query GetSavedImageByType($query: String) {
  getSavedImageByType(query: $query) {
    id
    path
    mimetype
  }
}`;

export const GET_FIELD_GROUP  =`query GetfieldsGroup {
    getfieldsGroup {
        section
        total_coins
        sortOrder
        sectionTitle
        fields {
          sectioninfo
            field
            info
            section
            educatingLiners
            appreciatingLiners
            coins
            mandatory
            sectionOrderNumber
        }
    }
}`;

export const GET_CHILD_FIELDS = `query GetChildFields($child: String) {
  getChildFields(child: $child) {
    section
    total_coins
    sortOrder
    sectionTitle
    fields {
      id
      section
      field
      sectioninfo
      info
      educatingLiners
      appreciatingLiners
      coins
      mandatory
      sectionOrderNumber
      createdAt
      updatedAt
    }
  }
}`;

export const    GET_CHILD_EARNED_COINS  =`query GetChildFieldsGroup($child: String) {
  getChildFieldsGroup(child: $child) {
    section
    total_coins
    sortOrder
    fields {
      id
      section
      field
      sectioninfo
      info
      educatingLiners
      appreciatingLiners
      coins
      mandatory
      sectionOrderNumber
      createdAt
      updatedAt
      is_earned
    }
  }
}`;

export const GET_CHILD_TOTAL_EARNED_COINS  =`query GetTotalEarnedCoins($child: String) {
  getTotalEarnedCoins(child: $child) {
    total
    mandatory
    non_mandatory
    parent
  }
}`;

export const GET_ALL_FIELDS  =`query GetAllfields {
  getAllfields {
    id
    section
    field
    info
    educatingLiners
    appreciatingLiners
    coins
    mandatory
    sectionOrderNumber
    createdAt
    updatedAt
  }
}`



export const GQ_UPDATE_BILING_INFO = `mutation UpdateBillingInfo($user: BillingAddressInput) {
  updateBillingInfo(user: $user) {
    id
    leadId
      talent_discovery_bundle_price
      early_bird_discount
      subtotal
      gst
      total
      request_id
  }
}`;



export const GQ_CREATE_TDP_ORDER = `mutation CreateOrderTDA($user: OrderDetailsInput) {
  createOrderTDA(user: $user) {
   orderid
   requestId
   finalamount
   name
   contact 
   email
  }
}`;

export const GQ_CREATE_INTERNATIONAL_TDP_ORDER = `mutation createOrderInternationalTDA($user: OrdersDetailsInput) {
  createOrderInternationalTDA(user: $user) {
   orderid
   requestId
   finalamount
   name
   contact 
   email
  }
}`;

export const GQ_GETSERVICEDETAILS = `query GetServiceDetails($payment_request_id: String) {
  getServiceDetails(payment_request_id: $payment_request_id){
      serviceName
      userId
      price
      request_id
      payment_status
  }
}`;


export const GQ_APPLYCOPUN = `query AddCoupon($user : CouponInput) {
  addCoupon(user: $user) {
   talent_discovery_bundle_price
      early_bird_discount
      coupon_discount
      subtotal
      gst
      total
      coupon_type
      discount
  }
}`;



export const GQ_DOWNLOADINVOICE = `query DownloadInvoice($request_id : String) {
  downloadInvoice(request_id: $request_id) 
}`;