export const GQ_GET_OVERVIEW_TEMPLATE = `query GetOverviewTemplates {
  getOverviewTemplates {
    id
    description
  }
}`;

export const GQ_GET_POLYGON_ANALYSIS_REPORT = `query GetPolygonTalentAnalysisReport($childrenId: String) {
  getPolygonTalentAnalysisReport(children_id: $childrenId) {
    id
    children_id
    overview {
      heading
      template_id
      icon
      description {
        child_name
        Personality_trait
        learning_dimensions
        learning_needs
      }
    }
    unique_talent {
      heading
      icon
      description
      talents {
        title
        description
        score
        level
        image
      }
    }
    personality_traits {
      traits {
        description
        title
        image
      }
      description
      icon
      heading
    }
    interest_areas {
      interest {
        description
        title
        image
      }
      description
      icon
      heading
    }
    learning_needs {
      needs {
        title
        learning_dimensions
        talents
        careers
        description
        image
      }
      description
      icon
      heading
    }
    createdAt
  }
}`;

export const GQ_GET_CONGNITIVE_REPORT = `query GetCognitiveProfileStrength ($children_id : String){
  getCognitiveProfileStrength(children_id : $children_id)
}`;


export const GQ_GET_DIMENSION_INFO = `query GetDimensionInfo{
  getDimensionInfo{
      dimension_name
      image
      description
  }
}`;

export const GQ_GET_UPDATE_REPORT = `mutation UpdateReportStatus($childId: String){
  updateReportStatus(childId : $childId)
}
`;



