const initialValue = {
    getPlanDetails:null,
};

export default function getChildrenReducer(state = initialValue, action) {
  
    switch (action.type) {
        case "PLAN_DETAILS":
            return Object.assign({}, state, {
                getPlanDetails: action.plan_details,
            });
        default:
            return state;
    }
};