import axios from 'axios';
import urls from './WebUrls';

class AssessmentServices {
    examDetails = (token, data) => {
        return axios.get(urls.Exam_Details, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    getParentingExamDetails = (token, data) => {
        return axios.get(urls.getParentingExamDetails, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    getParentByChild = (token, data) => {
        return axios.get(urls.getParentByChild, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }



    getTDPReport = (token, data) => {
        return axios.get(urls.getTDPReport, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }



    getTDPReportv3 = (token, data) => {
        return axios.get(urls.getTDPReportv3, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }


    submitTDPExam= (token, data) => {
        return axios.post(urls.submit_TDPExam, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }

    submitParentingStyleTestDetails= (token, data) => {
        return axios.post(urls.submitParentingStyleTestDetails, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }

    generateReportPdf= (token, data) => {
        return axios.post(urls.generateReportPdf, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }

    generateParentTestReportPdf= (token, data) => {
        return axios.post(urls.generateParentTestReportPdf, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }

    

    getTdpSummary= (token, data) => {
        return axios.get(urls.get_TDPSummary,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    getTDPProgress=(token, data) => {
        return axios.get(urls.getTDPProgress,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    get_Child_intro_report=(token,data) => {
        return axios.get(urls.get_IntroReport,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }
    get_Child_report_hindi=(token,data) => {
        return axios.get(urls.get_ChildReportHind,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    createChildTalentReport=(token,data) => {
        return axios.post(urls.create_ChildTalentReport,data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            // params: data
        });
    }

    createParentStylingTestReport=(token,data) => {
        return axios.post(urls.createParentStylingTestReport,data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            // params: data
        });
    }
    
    createInterestTestReport=(token,data) => {
        return axios.post(urls.create_InterestTestReport,data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        
        });
    }

    getTDPSummaryV3= (token, data) => {
        return axios.get(urls.getTDPSummaryV3,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    getParentTestSummary= (token, data) => {
        return axios.get(urls.getParentTestSummary,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    getTDPExamDetailsV3 = (token, data) => {
       
        return axios.get(urls.getTDPExamDetailsV3, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }
    submitTDPExamV3= (token, data) => {
        return axios.post(urls.submitTDPExamV3, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }

    getTDPProgressV3=(token, data) => {
        return axios.get(urls.getTDPProgressV3,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    


    createTDPExitMessage=(token,data) => {
        return axios.post(urls.createTDPExitMessage,data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        
        });
    }

    getOldChildDetails =(token, data) => {
        return axios.get(urls.getOldChildDetails ,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    getChildDetails=(token, data) => {
        return axios.get(urls.getChildDetails ,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    get_Child_intro_report22 = (token, data) => {
        return axios.get(urls.fetchCombinedSchoolReport, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    generatePdfReportCombinedSchoolReport = (token, data) => {
        return axios.post(urls.generteSchoolPdf, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }
    getChildExamOverallStatus = (token, data) => {
        return axios.get(urls.getChildExamOverallStatus, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }
    


    getParentStylingTestReport = (token, data) => {
        return axios.get(urls.getParentStylingTestReport, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    getMoralTestSummary= (token, data) => {
        return axios.get(urls.getMoralTestSummary,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    getMoralExamDetails = (token, data) => {
        return axios.get(urls.getMoralTestDetails, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    submitMoralTestDetails= (token, data) => {
        return axios.post(urls.submitMoralTestDetails, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }

    
    getMoralExamDetailsV3 = (token, data) => {
        return axios.get(urls.getMoralTestDetailsV3, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }

    submitMoralTestDetailsV3= (token, data) => {
        return axios.post(urls.submitMoralTestDetailsV3, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }

    createChildTestReport=(token,data) => {
        return axios.post(urls.createMoralTestReport,data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            // params: data
        });
    }

    getMoralChildTestReport = (token, data) => {
        return axios.get(urls.getMoralTestReport, {
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
            params: data
        });
    }
    generateChildMoralTestReportPdf= (token, data) => {
        return axios.post(urls.generateMoralTestReportPdf, data,{
            headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": "skip-browser-warning"
            },
        });
    }
}




export default new AssessmentServices();
