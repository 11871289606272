import Image from "next/image";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { setIn, useFormik } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";
import { useSnackbar, withSnackbar } from "notistack";
import Autocomplete from "react-google-autocomplete";
import { useRouter } from "next/router";
import {
  ERROR_MESSAGE,
  EventEmitter,
  SUCCESS_MESSAGE,
} from "../../helper/index";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import APIService from "../../@mock-api/api.service";
import { images } from "../../../constant";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { User, ArrowLeft2, Calendar1, CloseCircle, Gps } from "iconsax-react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Spinner from "react-bootstrap/Spinner";
import Statedata from "../../helper/states.json";
import city from "../../helper/city.json"
import Statedistrictdata from "../../helper/State__and__dist.json";
import dynamic from "next/dynamic";
import Select from "react-select";
const PopupModal = dynamic(
  () => import("react-calendly").then((mod) => mod.PopupModal),
  {
    ssr: false,
  }
);

const apiService = new APIService();

import attach from "../../../public/assets/img/icons/img-add.png";
import { route } from "next/dist/server/router";

const schoolStandard = ["KG2", 1, 2, 3, 4, 5, 6, 7];
const parentOptions = ["Father", "Mother", "Guardian"];

const AddChildModal = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { displayChild } = props;
  // const [cityOptions, setcityOptions] = useState(["dewas","indore","dehradun"]);
  //Redux states

  const [states, setstates] = useState("");

  const [cityOptions, setcityOptions] = useState()
  const userData = useSelector((state) => state);
  const [userId, setuserId] = useState("");
  const [avatarData, setAvatarData] = useState([]);
  const [residenceCity, setResidenceCity] = useState("");
  const [avatarGallery, setAvatarGallery] = useState([]);
  const [pageSettings, setpageSettings] = useState({
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  });
  const [prefill, setprefill] = useState({
    email: "",
    name: "",
  });
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    setcityOptions(city)
  }, []);

  //Handling Image
  const [uploadedFilePath, setUploadedFilePath] = useState("");
  const [cropper, setCropper] = useState();
  const [cropData, setCropData] = useState("");
  const [userDetails, setUserDetails] = useState("");

  //Handle Button
  const [isNextBtnDisabled, setNextBtnDisabled] = useState(false);
  const [isAddBtnDisabled, setAddBtnDisabled] = useState(false);

  const [heading, setHeading] = useState("Who is filling this form?");

  //Handling show/hide view
  const [isCropperVisible, setCropperVisible] = useState(false);
  const [showdasstage, setshowdasstage] = useState("");

  // SET STEPS
  const [step, setStep] = useState(2);
  const section_name = "about";
  //current added child

  const [childId, setChildId] = useState(undefined);

  //Formik validation and initialisation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Can only be alphabet")
      .max(15, "First name must be at less than 15 chars!"),
    last_name: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Can only be alphabet")
      .max(15, "Last name must be at less than 15 chars!")
      .required("Enter last name"),
    gender: Yup.string().required("Select Gender"),
    date_of_birth: Yup.string().required("Select Date of birth").nullable(),
    school_standard: Yup.string().required("Select Standard"),
    city: Yup.string().required("Enter city"),
  });
  const initialValues = {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    school_standard: "",
    image: cropData ? cropData : uploadedFilePath,
    city: "",
    state: ""
  };



  let who_are_you_option = [
    { value: "School Owner", label: "School Owner" },
    { value: "Trustee/Director", label: "Trustee/Director" },
    { value: "Principal", label: "Principal" },
    { value: "Teacher", label: "Teacher" },
    { value: "Parent", label: "Parent" },
    { value: "Student", label: "Student" },
    { value: "Other", label: "Other" },
  ];

  useEffect(() => {
    let teacherAssignment = localStorage.getItem("assigned_teacher");
    let userDetails = localStorage.getItem("userDetails");
    if (teacherAssignment) {
      if (JSON.parse(teacherAssignment)?.parent_email?.toLowerCase() == '') {
        updateBasicForm(teacherAssignment);
      } else if (JSON.parse(teacherAssignment)?.parent_email?.toLowerCase() == JSON.parse(userDetails)?.email?.toLowerCase()) {
        updateBasicForm(teacherAssignment);
      }

      // if (
      //   JSON.parse(teacherAssignment)?.parent_email?.toLowerCase() ==
      //   JSON.parse(userDetails)?.email?.toLowerCase()
      // ) {
      //   updateBasicForm(teacherAssignment);
      // }
      
    }
    var email = JSON.parse(userDetails).email;
    var firstname = JSON.parse(userDetails).first_name;
    var lastname = JSON.parse(userDetails).last_name;
    setprefill({
      email: email,
      name: firstname + " " + lastname,
    });
  }, []);
  const router = useRouter();
  const [tdauser, settdauser] = useState(false);
  const [tdauser12, settdauser12] = useState(false);
  const [fullname, setfullname] = useState("");
  const [starttime, setstarttime] = useState("");
  const [endtime, setendtime] = useState("");
  const [grade, setGrade] = useState(null)
  useEffect(() => {
    if (router.query.tda) {
      const tauser1 = router.query.tda;
      settdauser(tauser1);
      setisOpen(true);

      const user = localStorage.getItem("userDetails");
      setuserId(JSON.parse(user).id);
    } else if (
      router.query.event_type_uuid &&
      router.query.event_type_name == "Joonify Talent Advisory Call"
    ) {
      settdauser12(true);
      setfullname(router.query.invitee_full_name);
      setendtime(router.query.event_end_time);
      setstarttime(router.query.event_start_time);
      window.history.replaceState(null, "", "/child/add");
    }
  }, [router.query]);

  const updateBasicForm = (teacherAssignment) => {
    let child = JSON.parse(teacherAssignment).child.split(" ");
    console.log("jdhfjdshfjhjksd", child)
    let first_name = {
      target: {
        name: "first_name",
        value: child[0],
      },
    };
    let last_name = {
      target: {
        name: "last_name",
        value: child[1],
      },
    };
    let school_standard = {
      target: {
        name: "school_standard",
        value: JSON.parse(localStorage.getItem("assigned_teacher")).grade.toString(),
      },
    };

    handleChange(school_standard);
    handleChange(first_name);
    handleChange(last_name);
  };

  const assignTeacherToStudent = (data, child) => {
    let userDetails = localStorage.getItem("userDetails");
    apiService
      .assignTeacherToStudent({
        data: {
          children_id: child.id,
          teacher_id: JSON.parse(data).teacher,
          student_roll_number: JSON.parse(data).roll_number,
          student_section: JSON.parse(data).section,
          // student_grade: parseInt(JSON.parse(data).grade),
          student_grade: JSON.parse(data).grade == "KG2" ? 0 : parseInt(JSON.parse(data).grade)
        },
      })
      .then((res) => {
        if (res.data.data && res.data.data.assignTeacherToStudent) {
          if (JSON.parse(data).registered) {
            if (
              JSON.parse(data).parent_email != JSON.parse(userDetails).email
            ) {
              updateTeacherAssigned(data, userDetails);
            }
          }
          getChildDetails(child.id);
          enqueueSnackbar(
            "The teacher has been assigned successfully.",
            SUCCESS_MESSAGE
          );
          localStorage.removeItem("assigned_teacher");
        } else {
          enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
        }
      });
  };

  const updateTeacherAssigned = (data, userDetails) => {
    apiService
      .updateStudentSecondaryParent({
        data: {
          parent_secondary_email: JSON.parse(userDetails).email,
          parent_secondary_phone: JSON.parse(userDetails).phone,
          student_roll_number: JSON.parse(data).roll_number,
        },
      })
      .then((res) => {
        if (res.data.data && res.data.data.updateStudentParentSecondaryInfo) {
          localStorage.removeItem("assigned_teacher");
        } else {
          enqueueSnackbar("Error updating student", ERROR_MESSAGE);
        }
      });
  };

  const getChildDetails = (childId) => {
    apiService.singleChildDetail({ childId }).then((res) => {
      if (res.data.data && res.data.data.child) {
        localStorage.setItem(
          "childDetails",
          JSON.stringify(res.data.data.child)
        );
      } else {
        console.log("error is", res.data);
      }
    });
  };

  //Handling form submit
  const onSubmit = (values, actions) => {
    let teacherAssignment = localStorage.getItem("assigned_teacher");
    if (step != 4) {
      // const data = values;

      const data = {
        city: values.city,
        date_of_birth: values.date_of_birth,
        first_name: values.first_name,
        gender: values.gender,
        image: values.image,
        last_name: values.last_name,
        parent: values.parent,
        school_standard: values.school_standard,
        // state: states
      }
      const user = localStorage.getItem("userDetails");
      data.parent = JSON.parse(user).id;
      data.image = cropData
        ? cropData
        : uploadedFilePath
          ? uploadedFilePath
          : "";
      setAddBtnDisabled(true);
      apiService
        .addChild({ child: data })
        .then((res) => {
          if (res.data.data && res.data.data.createNewChild) {
            if (teacherAssignment) {
              assignTeacherToStudent(
                teacherAssignment,
                res.data.data.createNewChild
              );
            }
            setAddBtnDisabled(false);
            setGrade(values.school_standard)
            setCropData("");
            setUploadedFilePath(data.image);
            enqueueSnackbar("Child added successfully.", SUCCESS_MESSAGE);
            setStep(4);
            setChildId(res.data.data.createNewChild.id);

            // update child count in local storage
            const userDetails = JSON.parse(user);
            userDetails.childCount = userDetails.childCount + 1;
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            localStorage.setItem(
              "selected_child",
              res.data.data.createNewChild.id
            );
            localStorage.setItem(
              "child",
              JSON.stringify(res.data.data.createNewChild)
            );
          } else {
            setAddBtnDisabled(false);
            enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
          }
        })
        .catch((errors) => {
          setAddBtnDisabled(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: false,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  useEffect(() => {
    if (props.showModal) {
      getAvatarData();
      const userDetail = JSON.parse(localStorage.getItem("userDetails"));
      setUserDetails(userDetail);
    }
  }, [props.showModal]);

  //Handling image upload and cropping
  const uploadBase64 = (data) => {
    setAddBtnDisabled(true);
    apiService
      .uploadFile({ file: data })
      .then((res) => {
        if (res.data.data && res.data.data.base64Upload) {
          setCropData(res.data.data.base64Upload.location);
          setAddBtnDisabled(false);
          setCropperVisible(false);
        } else {
          this.props.enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
          setAddBtnDisabled(false);
          setCropperVisible(false);
        }
      })
      .catch((errors) => {
        setAddBtnDisabled(false);
        setCropperVisible(false);
        console.log("errors", errors);
      });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      // setCropperVisible(false)
      uploadBase64(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const onCropChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedFilePath(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setCropperVisible(true);
  };

  const handleFileUpload = (event) => {
    let file = event.target.files[0];
    if (file && !file.name) {
      enqueueSnackbar("Please select image", ERROR_MESSAGE);
      return false;
    }
    if (file.size > 10e6) {
      enqueueSnackbar("Please upload a file smaller than 10 MB", ERROR_MESSAGE);
      return false;
    }
    onCropChange(event);
  };

  const getAvatarData = () => {
    apiService.getAvatarData().then((res) => {
      if (res.data.data && res.data.data.getAllAvatar) {
        setAvatarData(res.data.data.getAllAvatar);
        setUploadedFilePath(res.data.data.getAllAvatar[0]?.avatar);
        const avatarObj = [];
        if (res.data.data.getAllAvatar.length) {
          res.data.data.getAllAvatar.map((item) =>
            avatarObj.push({
              original: item.avatar,
              thumbnail: item.avatar,
            })
          );
          // avatarObj.push({
          //     original: (attach.src),
          //     thumbnail: (attach.src),
          // })
          setAvatarGallery(avatarObj);
        }
      } else {
        enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
      }
    });
  };

  const disabledDate = (current) => {
    return current && current > moment().subtract(0, "years")._d;
  };

  const changeStep = () => {
    values.city = residenceCity.trim();
    values.first_name = values?.first_name?.trim();
    values.last_name = values?.last_name?.trim();
    // if (!values.parentType && step === 1) {
    //   enqueueSnackbar("Please select any one option", ERROR_MESSAGE);
    //   return false;
    // }
    if ((!values.first_name || !values.last_name) && step === 2) {
      enqueueSnackbar("Please enter first and last name", ERROR_MESSAGE);
      return false;
    }
    if (errors.first_name && step === 2) {
      enqueueSnackbar(errors.first_name, ERROR_MESSAGE);
      return false;
    }
    if (errors.last_name && step === 2) {
      enqueueSnackbar(errors.last_name, ERROR_MESSAGE);
      return false;
    }
    if (step < 3) {
      setStep(step + 1);
    } else if (step === 3) {
      if (!values.gender) {
        enqueueSnackbar(
          `Please select ${values.first_name}'s gender`,
          ERROR_MESSAGE
        );
        return false;
      }
      if (!values.city) {
        enqueueSnackbar(
          `Please select ${values.first_name}'s City of Residence`,
          ERROR_MESSAGE
        );
        return false;
      }
      if (!values.date_of_birth) {
        enqueueSnackbar(
          `Please select ${values.first_name}'s date of birth`,
          ERROR_MESSAGE
        );
        return false;
      }
      if (!values.school_standard) {
        enqueueSnackbar(
          `Please select ${values.first_name}'s current grade`,
          ERROR_MESSAGE
        );
        return false;
      }
      onSubmit(values);
    }
  };

  useEffect(() => {
    if (step === 1) {
      setHeading("Who is filling this form?");
    }
    if (step === 2) {
      setHeading("What is your child’s name?");
    }
    if (step === 3) {
      setHeading(`Hello ${values?.first_name}’s,`);
    }
  }, [step]);

  const onThumbnailClick = (e, i) => {
    setCropData("");
    setUploadedFilePath(avatarGallery[i].original);
  };

  function handleClick() {
    settdauser12(false);
    setStep(2);
  }

  // function gradeRoute(){
  //   alert(grade)
  //  if(grade<3){
  //   router.push("/gradeassessment")
  //  }
  //  else{
  //   router.push("/assessment")
  //  }
  // }


  const basicInfoView = () => {
    return (
      <>
        {!isCropperVisible && (
          <>
            {/* {step === 1 && (
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <div className="checkbox-group mx-auto">
                      <div className="checkbox">
                        <label className="checkbox-wrapper" id={"parentType_F"}>
                          <input
                            className="checkbox-input"
                            id="demo1"
                            name="parentType"
                            type="radio"
                            checked={values.parentType == "Father"}
                            value={"Father"}
                            onChange={handleChange}
                          />
                          <span className="checkbox-tile">
                            <img
                              className="img-fluid"
                              src={images.father.default.src}
                            />
                            <span className="checkbox-label">Father</span>
                          </span>
                        </label>
                      </div>
                      <div className="checkbox">
                        <label className="checkbox-wrapper" id={"parentType_M"}>
                          <input
                            className="checkbox-input"
                            id="p2"
                            name="parentType"
                            type="radio"
                            checked={values.parentType == "Mother"}
                            value={"Mother"}
                            onChange={handleChange}
                          />
                          <span className="checkbox-tile">
                            <img
                              className="img-fluid"
                              src={images.mother.default.src}
                            />
                            <span className="checkbox-label">Mother</span>
                          </span>
                        </label>
                      </div>
                      <div className="checkbox">
                        <label className="checkbox-wrapper" id={"parentType_G"}>
                          <input
                            className="checkbox-input"
                            id="p3"
                            name="parentType"
                            type="radio"
                            checked={values.parentType == "Guardian"}
                            value={"Guardian"}
                            onChange={handleChange}
                          />
                          <span className="checkbox-tile">
                            <img
                              className="img-fluid"
                              src={images.guardian.default.src}
                            />
                            <span className="checkbox-label">Guardian</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            {step === 2 && (
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-md-9">
                    <div className="col-12 form-group mb-4">
                      <label id={"label_FN"}>First Name</label>
                      <User className="icon-field" variant="linear" />
                      <Form.Control
                        className="form-control"
                        id="first_name"
                        type="text"
                        autoComplete="off"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        placeholder="Enter first name*"
                      />
                    </div>
                    <div className="col-12 form-group">
                      <label id={"label_LN"}>Last Name</label>
                      <User className="icon-field" variant="linear" />
                      <Form.Control
                        className="form-control"
                        id="last_name"
                        type="text"
                        autoComplete="off"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        placeholder="Enter last name*"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="col-12">
                <div className="row justify-content-between">
                  <div className="col-md-5">
                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="col-auto">
                          <h4 className="text-center w-100 fw-bold mb-3">
                            <span className="text-capitalize">
                              {values.first_name}
                            </span>
                            ’s Gender
                          </h4>
                          <div className="checkbox-group mx-auto">
                            <div className="checkbox">
                              <label
                                className="checkbox-wrapper"
                                id={"G1_label"}
                              >
                                <input
                                  className="checkbox-input"
                                  id="G1"
                                  name="gender"
                                  type="radio"
                                  value={"Boy"}
                                  checked={values.gender === "Boy"}
                                  onChange={handleChange}
                                />
                                <span className="checkbox-tile">
                                  <img
                                    className="img-fluid"
                                    src={images.boy.default.src}
                                  />
                                  <span className="checkbox-label">Boy</span>
                                </span>
                              </label>
                            </div>
                            <div className="checkbox">
                              <label
                                className="checkbox-wrapper"
                                id={"G2_label"}
                              >
                                <input
                                  className="checkbox-input"
                                  id="G2"
                                  name="gender"
                                  type="radio"
                                  value={"Girl"}
                                  checked={values.gender === "Girl"}
                                  onChange={handleChange}
                                />
                                <span className="checkbox-tile">
                                  <img
                                    className="img-fluid"
                                    src={images.girl.default.src}
                                  />
                                  <span className="checkbox-label">Girl</span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <div className="col-12">
                      <div className="row justify-content-end">
                        <div className="col-md-10">
                          <div className="col-md-12 form-group">
                            <label id={"city_of_residence_label"}>
                              <span className="text-capitalize">
                                {values.first_name}
                              </span>
                              <span className="t-small">’s</span> City of
                              Residence{" "}
                            </label>
                            <Gps className="icon-field" variant="linear" />
                            <div className="col-12 ">
                              <Autocomplete
                                id={"city_of_residence_input"}
                                className="col-12 form-control"
                                style={{ width: "100%", zIndex: 5000 }}
                                defaultValue={residenceCity}
                                name={"city"}
                                // value={values.city}
                                apiKey={
                                  "AIzaSyCuewA9ei2ujr6hrbWfK34_aX-Ta6ExgaI"
                                }
                                onPlaceSelected={(selectedOption) => {
                                  let e = {
                                    target: {
                                      name: "residence_city",
                                      value: selectedOption.formatted_address,
                                    },
                                  };
                                  setResidenceCity(e.target.value);
                                }}
                                onChange={(e) =>
                                  setResidenceCity(e.target.value)
                                }
                              />

                              {/* <Select
                                options={cityOptions}
                                placeholder={"Select City"}
                                id={"city_of_residence_label"}
                                name={"city"}
                                onChange={(e) => {
                                  let event = {
                                    target: {
                                      name: "city",
                                      value: e.value,
                                    },
                                    
                                  };
                                  
                                  handleChange(event),
                                  setResidenceCity(event.target.value);
                                    setstates(Statedistrictdata[0][event.target.value]);
                               
                                }}
                              /> */}

                              {errors.city && touched.city ? (
                                <Form.Control.Feedback type="invalid">
                                  <Danger variant="Bulk" />
                                  {errors.city}
                                </Form.Control.Feedback>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col-12 form-group">
                            <label id={"DOB_label"} className="text-capitalize">
                              {values.first_name}’<span>s</span> Date of Birth
                            </label>
                            <Calendar1
                              className="icon-field"
                              variant="linear"
                            />
                            <DatePicker
                              name={"date_of_birth"}
                              format="DD-MM-YYYY"
                              value={values.date_of_birth}
                              inputReadOnly="false"
                              placeholder={"Click to open calendar"}
                              onChange={(date) => {
                                let event = {
                                  target: {
                                    name: "date_of_birth",
                                    value: date,
                                  },
                                };
                                handleChange(event);
                              }}
                              disabledDate={disabledDate}
                              defaultPickerValue={moment().subtract(0, "years")}
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                            />
                          </div>
                          <div className="col-12 form-group mt-md-5 mt-1 mb-0 grade">
                            <label id={"S_label"} className="ps-1">
                              In which grade{" "}
                              <span className="text-capitalize">
                                {values.first_name}
                              </span>{" "}
                              is studying?
                            </label>
                            <div className="checkbox-group mx-auto grade-slc mb-2">
                              {/* {alert(values.school_standard)} */}
                              {schoolStandard.map((item, index) => (
                                <div className="checkbox">
                                  <label
                                    className="checkbox-wrapper"
                                    id={"standard_label"}
                                  >
                                    <input
                                      className="checkbox-input"
                                      id={`grade-${index}`}
                                      name="school_standard"
                                      type="radio"
                                      value={JSON.parse(localStorage.getItem("assigned_teacher")) ? JSON.parse(localStorage.getItem("assigned_teacher")).grade : item}
                                      checked={values.school_standard == item}
                                      onChange={handleChange}
                                    />
                                    <span className="checkbox-tile">
                                      <span className="checkbox-label">
                                        {item}
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 avatar">
                    <hr />
                    <h3 className="w-100 text-center fw-bold mb-3 mt-3">
                      Choose{" "}
                      <span className="text-capitalize">
                        {values.first_name}
                      </span>
                      ’s Avatar or Upload Image
                    </h3>
                    <div className="col-12">
                      <div
                        className={`col-md-auto head-card ${cropData ? "show" : ""
                          }`}
                      >
                        <div className="col-12 card card-sm">
                          {!!avatarData.length && (
                            <img
                              className="img-fluid"
                              src={cropData ? cropData : uploadedFilePath}
                            />
                          )}
                        </div>
                      </div>
                      <ImageGallery
                        items={avatarGallery}
                        slideDuration={200}
                        onThumbnailClick={onThumbnailClick}
                      />
                      <div className="col-12 thumb-main">
                        <div className="col-auto thumb img-upload">
                          <Form.Control
                            type="file"
                            name="image"
                            accept="image/*"
                            id="upload_image_1"
                            placeholder="Upload image"
                            onChange={(event) => {
                              let file = event.target.files[0];
                              if (file && !file.name) {
                                enqueueSnackbar(
                                  "Please select image",
                                  ERROR_MESSAGE
                                );
                                return false;
                              }
                              if (file.size > 10e6) {
                                enqueueSnackbar(
                                  "Please upload a file smaller than 10 MB",
                                  ERROR_MESSAGE
                                );
                                return false;
                              }
                              onCropChange(event);
                            }}
                          />
                          <img
                            className="img-fluid"
                            src={images.addImg.default.src}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-none">
                      <div className="row justify-content-center">
                        <div className="col-md-auto head-card">
                          <div className="col-12 card card-sm">
                            {!!avatarData.length && (
                              <img
                                className="img-fluid"
                                src={cropData ? cropData : uploadedFilePath}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-12 thumb-main">
                          <div className="row justify-content-center">
                            {!!avatarData.length &&
                              avatarData.map((item, index) => (
                                <div
                                  className={`col-auto card card-xs thumb ${item.avatar === uploadedFilePath
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <img
                                    id={`avatar_${index}`}
                                    className="img-fluid"
                                    onClick={() => {
                                      setCropData("");
                                      setUploadedFilePath(item.avatar);
                                    }}
                                    src={item.avatar}
                                    alt="avatar"
                                  />
                                </div>
                              ))}
                            <div className="col-auto card card-xs thumb img-upload">
                              <Form.Control
                                type="file"
                                name="image"
                                accept="image/*"
                                id="upload_image_1"
                                placeholder="Upload image"
                                onChange={(event) => {
                                  let file = event.target.files[0];
                                  if (file && !file.name) {
                                    enqueueSnackbar(
                                      "Please select image",
                                      ERROR_MESSAGE
                                    );
                                    return false;
                                  }
                                  if (file.size > 10e6) {
                                    enqueueSnackbar(
                                      "Please upload a file smaller than 10 MB",
                                      ERROR_MESSAGE
                                    );
                                    return false;
                                  }
                                  onCropChange(event);
                                }}
                              />
                              <img
                                className="img-fluid"
                                src={images.addImg.default.src}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === 4 && (
              <>
                <div className="col-12 head-text">
                  <img className="img-fluid " src={images.minImg.default.src} />
                </div>
                <div className="col-12 body-text">
                  <div className="row align-items-center">
                    <div className="col-md-7 left">
                      <img
                        className="ml-5 img-fluid text-logo"
                        src={images.logoText.default.src}
                      />
                      <h2 className="md-ms-4">can help you discover</h2>
                      <h2 className="md-ms-4">
                        the <b>Growth Path</b> for
                      </h2>
                      <div className="btn-div d-none d-md-block">
                        <div className="arrow">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <button
                          className="btn btn-blue btn-anim"
                          onClick={() => {
                            displayChild(childId, grade);
                            localStorage.setItem("childId", childId);
                            setStep(1);
                            router.push(`/joonifyreports`);
                          }}
                        >
                          <img
                            className="img-fluid"
                            src={images.btnCaret.default.src}
                          />
                          <span>
                            Click to Continue
                            <span className="highlight">!</span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-5 id-card">
                      <img
                        className="img-fluid img-id"
                        src={images.idImg.default.src}
                      />
                      <div className="card card-xs avatar-card">
                        <img
                          className="img-fluid"
                          src={cropData ? cropData : uploadedFilePath}
                        />
                      </div>
                      <h2 className="text-capitalize">{values.first_name}</h2>
                    </div>
                    <div className="col-12 d-block d-md-none">
                      <div className="btn-div">
                        <div className="arrow">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <button
                          className="btn btn-blue btn-anim"
                          onClick={() => {
                            displayChild(childId, grade);
                            localStorage.setItem("childId", childId);
                            setStep(1);
                            // gradeRoute()
                          }}
                        >
                          <img
                            className="img-fluid"
                            src={images.btnCaret.default.src}
                          />
                          <span>
                            Click to Continue
                            <span className="highlight">!</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {isCropperVisible && (
          <div
            className="col-12 form-details"
            style={{ width: "100%", float: "center", height: "100%" }}
          >
            <div className=" cropper-custom">
              <Cropper
                style={{ height: 300, width: "100%" }}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                src={uploadedFilePath}
                zoomable={false}
                aspectRatio={1}
                preview=".img-preview"
                guides={false}
                viewMode={1}
                dragMode="move"
                cropBoxMovable={true}
              />
            </div>
            <div className="pt-0 modal-footer pb-0">
              <button
                onClick={getCropData}
                className="btn btn-blue float-end mx-auto mt-3"
                disabled={isAddBtnDisabled ? true : false}
              >
                Set avatar{" "}
                {isAddBtnDisabled && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mx-2"
                  />
                )}
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {tdauser12 ? (
        <Modal
          show={tdauser12}
          id="settings"
          aria-labelledby="settings"
          tabIndex="-1"
          dialogClassName={"modal-dialog-centered"}
          backdrop={"static"}
          size="ab"
        >
          <div className="talentadvisorygifmaindiv">
            <div className="talentadvisorygifdiv">
              <img
                className="talentadvisorygif"
                src={images.talentadvisorygif.default.src}
              />
            </div>

            <div>
              <div>
                <p className="congratalentpopup">
                  Congratulations on successfully
                  <br /> booking a{" "}
                  <span className="pinknamebasicreport pinknamebasicreportpopup">
                    Talent Advisory Call
                    <br />{" "}
                    <img
                      className="talentpopuppinkstrip"
                      src={images.talentpopuppinkstrip.default.src}
                    />
                  </span>
                  .
                </p>
              </div>

              <div>
                <p className="paralentpopup">
                  One of our expert advisors shall
                  <br /> be reaching out to you shortly.
                </p>
              </div>

              <div>
                <p className="paralentpopup">
                  You can either wait or just start exploring
                  <br /> the platform by starting your{" "}
                  <span className="paralentpopupspan">
                    FREE
                    <br /> Assessment journey
                  </span>
                  ,
                </p>
              </div>
            </div>

            <div className="paralentpopupfreeassessmentdiv">
              <button
                onClick={() => handleClick()}
                className="paralentpopupfreeassessment"
              >
                Start Your FREE Assessment now
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <>
          {tdauser === "TA_user" ? (
            <PopupModal
              url="https://tidycal.com/joonifydotcom/joonify-counselling-session"
              pageSettings={pageSettings}
              // utm={this.props.utm}
              prefill={prefill}
              onModalClose={() => setisOpen(false)}
              open={isOpen}
              rootElement={document.getElementById("__next")}
              text="Click here to schedule!"
              utm={{
                utmCampaign: "TAC",
                utmContent: "",
                utmMedium: "",
                utmSource: userId,
                utmTerm: "",
              }}
            />
          ) : (
            <Modal
              show={props.showModal}
              // show={true}
              id="settings"
              aria-labelledby="settings"
              tabIndex="-1"
              dialogClassName={`modal-dialog-centered modal-trans step-${step} ${isCropperVisible ? "step-img" : ""
                }`}
              backdrop={"static"}
              size="md"
            >
              <div className="modal-onboard ">
                {step <= 3 && (
                  <Modal.Header className="">
                    <div className="col-12 text-center">
                      <Modal.Title as="h2" className="modal-title">
                        {step > 2 && !isAddBtnDisabled && !isCropperVisible && (
                          <a className="icon-back">
                            <ArrowLeft2
                              className="icon-back"
                              variant="linear"
                              onClick={() => {
                                if (step > 1) {
                                  setStep(step - 1);
                                }
                              }}
                            />
                          </a>
                        )}
                        {heading}
                      </Modal.Title>
                      {step === 3 && (
                        <h5 className="mb-0">
                          Please help us with few more details.
                        </h5>
                      )}
                      {userDetails.childCount > 0 && (
                        <a
                          className="icon-close"
                          href="javascript:void(0)"
                          onClick={() => {
                            props.closeModal();
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.46261 19C6.09085 19 5.71908 18.8631 5.42558 18.5697C4.85814 18.0024 4.85814 17.0636 5.42558 16.4963L16.5004 5.42543C17.0678 4.85819 18.007 4.85819 18.5744 5.42543C19.1419 5.99267 19.1419 6.93154 18.5744 7.49878L7.49965 18.5697C7.22572 18.8631 6.83438 19 6.46261 19Z"
                              fill="#333333"
                            />
                            <path
                              d="M17.5374 19C17.1656 19 16.7939 18.8631 16.5004 18.5697L5.42558 7.49878C4.85814 6.93154 4.85814 5.99267 5.42558 5.42543C5.99301 4.85819 6.93221 4.85819 7.49965 5.42543L18.5744 16.4963C19.1419 17.0636 19.1419 18.0024 18.5744 18.5697C18.2809 18.8631 17.9092 19 17.5374 19Z"
                              fill="#333333"
                            />
                          </svg>
                        </a>
                      )}
                    </div>
                  </Modal.Header>
                )}
                <Modal.Body className="">
                  <form
                    key="basicInfoView"
                    id="registerForm"
                    className="tooltip-end-bottom"
                    onSubmit={handleSubmit}
                  >
                    {basicInfoView()}
                  </form>
                </Modal.Body>
                {step != 4 && !isCropperVisible && (
                  <Modal.Footer className="pt-0">
                    {!isCropperVisible && (
                      <button
                        type="button"
                        className="btn btn-blue "
                        onClick={changeStep}
                        disabled={isAddBtnDisabled}
                      >
                        Next
                        {isAddBtnDisabled && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mx-2"
                          />
                        )}
                      </button>
                    )}
                  </Modal.Footer>
                )}
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default withSnackbar(AddChildModal);
