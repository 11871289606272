const initialValue = {
    previous_path: '',
    previous_path_child:'',
    previous_path_calender:''

};

export default function setPreviousPath(state = initialValue, action){
    switch(action.type) {
        case "PATH_PREVIOUS":
            return Object.assign({}, state, {
                previous_path: action.previous_path,
            });
            case "PATH_PREVIOUS_CHILD":
            return Object.assign({}, state, {
                previous_path_child: action.previous_path_child,
            });
            case "PATH_PREVIOUS_CALENDER":
            return Object.assign({}, state, {
                previous_path_calender: action.previous_path_calender,
            });
        default:
            return state;
    }
};
