import '../public/assets/css/style.css'
import '../public/assets/css/style.css'
import Head from "next/head";
// import '../sass/style.sass';
import Layout from "../src/components/layout/layout";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import store from "../src/redux/store";
import {Provider} from "react-redux";

import {SnackbarProvider} from "notistack";
import React, {useEffect} from "react";




function MyApp({Component, pageProps, router}) {

    useEffect(() => {
   

        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5ZCHVVRR')
    
    }, []);
    return (
        <>
            <Head>
                <link
                    rel="icon"
                    href="https://release-joonify-early-access-frontend-landing-assets.s3.ap-south-1.amazonaws.com/favicon.ico" />
                <link
                    rel="apple-touch-icon"
                    href="https://release-joonify-early-access-frontend-landing-assets.s3.ap-south-1.amazonaws.com/logo.png" />
                <title>India’s 1st Intelligent Platform for Kid’s Learning & Growth</title>
                <meta name="description" content="JOONIFY is India’s 1st Intelligent Platform for Kid’s Learning & Growth, providing Parents the widest range of online Learning Solutions for their child’s Growth Journey, enabling them to make a Better Choice by Discovering their child’s individual learning needs & help them achieve their True Potential for a Better Future."/>
                <meta name="keywords" content="Online Learning, Study at Home, Home Schooling, K12 Education, Primary Schooling, DIY Classes, Children's Study, Child's Study, Online Courses, Joonify, Early Access, Learn Better Grow Faster, Online Learning Platform, School at Home, Interactive Learning, Study from Home, K5 Education, Child Educator, Child Progress, Kids Learning, Student Life, Smart Learning, Pre School, Home Schooling, Pre Schooling, Home Schooling Life, Home Schooling Family, Kids Educational Activities, Kids Study, Kids Learning Fun, Kids Learning Tools, Smart Assessment, Assessment Tool, Smart Study, Parents First Choice, Stay at Home, New Parents, Parenting, DIY for Kids, Fun & Learning, First School, Indis'a no. 1 Online Learning Platform, Top Learning, Keep Learning, Right Way to Learn"/>
                <meta property="og:title" content="India’s 1st Intelligent Platform for Kid’s Learning & Growth" />
                <meta property="og:description" content="JOONIFY is India’s 1st Intelligent Platform for Kid’s Learning & Growth, providing Parents the widest range of online Learning Solutions for their child’s Growth Journey, enabling them to make a Better Choice by Discovering their child’s individual learning needs & help them achieve their True Potential for a Better Future." />
                <meta property="og:image" content="https://joonify.s3.amazonaws.com/public/junify_logo.png" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
                <meta name="HandheldFriendly" content="true" />

                <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5ZCHVVRR"
                 height="0" width="0"  style={{display: "none",visibility:"hidden"}}></iframe></noscript>
               
               <link href="https://fonts.googleapis.com/css2?family=Mukta:wght@600&display=swap" rel="stylesheet" />

                {/* <script
                    dangerouslySetInnerHTML={{
                        __html: `if(typeof daScript === 'undefined'){
                                        const daScript = document.createElement('script');
                                        daScript.setAttribute('src','${process.env.NEXT_PUBLIC_ANALYTICS_ENDPOINT}');
                                        daScript.crossOrigin = "anonymous"
                                        document.head.appendChild(daScript)
                                    }`
                    }}
                /> */}
                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer = window.dataLayer || [];
                              function gtag(){dataLayer.push(arguments);}
                              gtag('js', new Date());
                            
                              gtag('config', 'G-6QBLP4BJ2C');`,
                    }}
                />

                <script async src="https://www.googletagmanager.com/gtag/js?id=G-6QBLP4BJ2C"/>
            </Head>
            <SnackbarProvider maxSnack={3}>
                <Provider store={store}>
                    <Layout router={router}>{<Component {...pageProps} />}</Layout>
                </Provider>
            </SnackbarProvider>
        </>
    );
}

export default MyApp
