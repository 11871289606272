const initialValue = {
  login_user_data: [],
  token: "",
};

export default function userDetailReducer(state = initialValue, action) {
  switch (action.type) {
    case "USER_DETAILS":
      return Object.assign({}, state, {
        login_user_data: action.login_user_data,
      });
    default:
      return state;
  }
};
