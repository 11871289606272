import { combineReducers } from "redux";
import userDetailReducer from "./authReducer";
import set_InfoSection from "./infoSectionReducer";
import setPreviousPath from "./previousPathReducer";
import getTotalChildEarnedCoins from "./getTotalCoinsReducer";
import getChildrenReducer from "./getChildrenReducer";
import getPlanReducer from "./getPlanReducer";
import getTdpReportReducer from "./getTdpReportReducer";

const appReducer = combineReducers({
  userDetailReducer:userDetailReducer ,set_InfoSection: set_InfoSection,setPreviousPath:setPreviousPath,getTotalChildEarnedCoins:getTotalChildEarnedCoins,getChildrenReducer:getChildrenReducer,getPlanReducer:getPlanReducer,getTdpReportReducer:getTdpReportReducer
});


export default appReducer;
