// DemographicBarometerProgressBar.js

import React, { useEffect, useRef, useState } from "react";
import {
  images,
  K_MANDATORY_COINS,
  K_NON_MANDATORY_COINS,
} from "../../../../../constant";
import { Flag, Flag2, InfoCircle, Danger } from "iconsax-react";
import ProgressBar from "react-bootstrap/ProgressBar";
import APIService from "../../../../@mock-api/api.service";
import { useDispatch, useSelector } from "react-redux";
import { getTotalEarnedChildCoins } from "../../../../redux/getTotalCoinsAction/getTotalCoinsAction";
import ToolTipModal from "../../../toot_tip/ToolTip";
import { ERROR_MESSAGE, EventEmitter } from "../../../../helper";
import { Accordion } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "/public/assets/animation/confetti.json";
import ProfilingBarometerProgressBar from "./ProfilingBarometerProgressBar";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { subscribe } from "graphql";

const apiService = new APIService();

function DemographicBarometerProgressBar(props) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const scrollRef = useRef();

  const exams = [
    "Emotional Reactivity",
    "Aptitude",
    "Logical Reasoning",
    "Applied Knowledge",
    "Creativity",
    "Accountability",
    "Linguistics",
    "Kinesthetic and Spatial",
    "Parent's test",
  ];
  const {
    childDetails,
    getSectionCoins,
    getSectionEarnedCoins,
    childFieldGroups,
  } = props;
  const [
    showUnlockedTalentDiscoveryStage,
    setShowUnlockedTalentDiscoveryStage,
  ] = useState(false);
  const [tdpSummary, setTdpSummary] = useState(null);
  const [examCompleted, setExamCompleted] = useState(false);
  const [isTdpAvailable, setIsTdpAvailable] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  const childEarnedCoins = useSelector(
    (state) => state?.getTotalChildEarnedCoins?.totalEarnedCoins
  );
  const [mandatoryCoins, setMandatoryCoins] = useState(null);

  const dispatch = useDispatch();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(async () => {
    EventEmitter.unsubscribe("DispatchDemographicProgressBar");
    EventEmitter.subscribe("DispatchDemographicProgressBar", (progressData) => {
      getFieldGroupList(progressData);
    });
    if (childDetails?.child_stage_cleared) {
      setIsTdpAvailable(true);
    }
  }, []);

  const getFieldGroupList = async (progressData) => {
    let child = await localStorage.getItem("selected_child");
    if (child) {
      apiService.getChildFields({ child: child }).then((res) => {
        const getFieldGroup = res.data.data.getChildFields;
        localStorage.setItem("fieldGroup", JSON.stringify(getFieldGroup));
        let totalCoins = 0;
        getFieldGroup.map((val) => {
          val.fields.map((item) => {
            if (item?.mandatory) {
              totalCoins += item.coins;
            }
          });
        });
        if (totalCoins != 0) {
          setMandatoryCoins(totalCoins);
          localStorage.setItem("mandatoryCoins", totalCoins);
        }
        calculatePercentage(progressData);
      });
    }
  };

  useEffect(() => {
    scrollRef.current.scrollTop = 300;
    let examCompleted = localStorage.getItem("wishList");
    if (examCompleted == "true") {
      setExamCompleted(true);
    } else {
      setExamCompleted(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getTotalEarnedChildCoins(childDetails.id));
      if (childDetails && !apiCalled) {
        setApiCalled(true);
        getTDPSummary();
      }
      let examCompleted = localStorage.getItem("wishList");

      if (examCompleted == "true") {
        setExamCompleted(true);
      } else {
        setExamCompleted(false);
      }
    }, 500);
  }, [childDetails]);

  const updateChildStage = async () => {
    let child = await localStorage.getItem("selected_child");
    if (child) {
      apiService
        .updateChildStage({ updateChildStageId: child })
        .then((res) => {
          if(res.data.data && res.data.data.updateChildStage) {
            EventEmitter.dispatch("tdpStageCleared");
            EventEmitter.dispatch("changeChildSidebar", {childId: child});
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const calculatePercentage = async (childFieldGroups) => {
    setTimeout(async () => {
      let mandatory = childFieldGroups?.mandatory;
      let totalMandatoryCoins = await localStorage.getItem("mandatoryCoins");
      if (totalMandatoryCoins > 0) {
        let child = localStorage.getItem("childDetails");
        const parsedData = child ? JSON.parse(child) : childDetails;
        if (mandatory >= totalMandatoryCoins) {
          if (isTdpAvailable) {
            setIsTdpAvailable(true);
          } else {
            if (parsedData.child_stage_cleared == false) {
              updateChildStage();
              setTimeout(() => {
                parsedData.child_stage_cleared = true;
              }, 0);
            }
            setIsTdpAvailable(true);
          }
        } else {
          setIsTdpAvailable(false);
        }
      }
    }, 0);
  };

  const getTDPSummary = () =>
    apiService
      .getTdpSummary({ type: "child", dimensionExamUserId: childDetails.id })
      .then((res) => {
        if (res.data.data && res.data.data.getTDPSummary) {
          setTdpSummary(res.data.data.getTDPSummary);
          setTimeout(() => {
            setApiCalled(false);
          }, 1000);
        } else {
          enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
        }
      });

  const handleAssessmentClick = async () => {
    const userDetail = await localStorage.getItem("userDetails");
    if (userDetail) {
      let path = JSON.parse(userDetail)?.ta_guidelines_checked
        ? "/assessment"
        : "/assessment/guideline";
      EventEmitter.dispatch("showFeedbackEvent", "Profiling");
      router.push(path);
    }
  };

  return (
    <>
      <div className="col-12 content">
        <div
          className={`cong-stage ${
            showUnlockedTalentDiscoveryStage ? "" : "d-none"
          }`}
        >
          <div className="col-12 card card-report text-center mb-0">
            <div className="lottie lottie-left">
              <Lottie
                options={defaultOptions}
                height={200}
                width={200}
                speed={0.7}
              />
            </div>
            <div className="lottie lottie-right">
              <Lottie
                options={defaultOptions}
                height={200}
                width={200}
                speed={0.7}
              />
            </div>
            <div className="col-12 head">
              <h3 className="mb-3">Congratulations!</h3>
            </div>
            <div className="col-12 content px-3">
              <h5 className="mb-3">
                You have successfully
                <br />
                unlocked the
              </h5>
              <h4 className="mb-4 fw-bold text-blue">Talent Discovery Stage</h4>
            </div>
          </div>
        </div>
        <Accordion
          ref={scrollRef}
          className="scr-yellow user"
          defaultActiveKey="4"
        >
          <Accordion.Item
            eventKey={"0"}
            className={examCompleted ? "active" : ""}
          >
            <Accordion.Header>
              <a
                onClick={() => {
                  if (examCompleted) {
                    router.push("/learning");
                  }
                }}
              >
                <ToolTipModal
                  text={
                    examCompleted
                      ? ""
                      : "Please complete the previous stages to unlock the further journey"
                  }
                >
                  <div className="col-12 start">
                    <div className="row">
                      <div className="col-auto left">
                        <img
                          className="img-fluid stack"
                          src={images.coinStack.default.src}
                        />
                      </div>
                      <div className="col right ">
                        <h5 className="blink mb-0 text-pink">
                          {childDetails.first_name}
                          <span className="text-lowercase">'s</span>
                          &nbsp;Learning Journey
                          <img src={images.pb6.default.src} />
                        </h5>
                      </div>
                    </div>
                  </div>
                </ToolTipModal>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item
            eventKey={"1"}
            className={examCompleted ? "active" : ""}
          >
            <Accordion.Header>
              <a
                onClick={() => {
                  if (examCompleted) {
                    router.push("/course");
                  }
                }}
              >
                <ToolTipModal
                  text={
                    examCompleted
                      ? ""
                      : "Please complete the previous stages to unlock the further journey"
                  }
                >
                  <div className="col-12 start">
                    <div className="row">
                      <div className="col-auto left">
                        <img
                          className="img-fluid stack"
                          src={images.coinStack.default.src}
                        />
                      </div>
                      <div className="col right ">
                        <h5 className="blink mb-0 text-blue">
                          {childDetails.first_name}
                          <span className="text-lowercase">'s</span>
                          &nbsp;Learning Plan
                          <img src={images.pb5.default.src} />
                        </h5>
                      </div>
                    </div>
                  </div>
                </ToolTipModal>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item
            eventKey={"2"}
            className={examCompleted ? "active" : ""}
          >
            <Accordion.Header>
              <a
                onClick={() => {
                  if (examCompleted) {
                    router.push(`/report/${childDetails?.id}`);
                  }
                }}
              >
                <ToolTipModal
                  text={
                    examCompleted
                      ? ""
                      : "Please complete the previous stages to unlock the further journey"
                  }
                >
                  <div className="col-12 start">
                    <div className="row">
                      <div className="col-auto left">
                        <img
                          className="img-fluid stack"
                          src={images.coinStack.default.src}
                        />
                      </div>
                      <div className="col right ">
                        <h5 className="blink mb-0 text-yellow">
                          {childDetails.first_name}
                          <span className="text-lowercase">'s</span>
                          &nbsp;Talent Discovery Report
                          <img src={images.pb4.default.src} />
                        </h5>
                      </div>
                    </div>
                  </div>
                </ToolTipModal>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item
            eventKey={"3"}
            className={isTdpAvailable ? "active" : "inactive"}
          >
            <Accordion.Header>
              <ToolTipModal
                text={
                  !isTdpAvailable
                    ? "Please complete the previous stages to unlock the further journey"
                    : ""
                }
              >
                <div className="col-12 start">
                  <div className="row">
                    <div className="col-auto left">
                      <img
                        className="img-fluid stack"
                        src={images.coinStack.default.src}
                      />
                    </div>
                    <div className="col right ">
                      <h5 className="blink mb-0 text-lightBlue">
                        {childDetails?.first_name}
                        <span className="text-lowercase">'s</span>
                        &nbsp;Talent Discovery Assesment
                        <img src={images.pb3.default.src} />
                      </h5>
                    </div>
                  </div>
                </div>
              </ToolTipModal>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {tdpSummary
                  ? tdpSummary.length
                    ? tdpSummary.map((item, index) => (
                        <li
                          key={"tdp_title_" + index}
                          className={
                            item.is_exam_completed
                              ? "completed"
                              : item.is_exam_inprogress
                              ? "partial"
                              : ""
                          }
                        >
                          <img
                            className="img-fluid stack"
                            src={images.coin.default.src}
                          />
                          <a
                            onClick={() => {
                              if (isTdpAvailable) {
                                handleAssessmentClick();
                              }
                              EventEmitter.dispatch("showMenuMobile");
                            }}
                          >
                            {item.dimension_name}
                          </a>
                          <ToolTipModal
                            text={
                              examCompleted ? "" : "Please complete the exam."
                            }
                          >
                            <Danger variant="Linear" />
                          </ToolTipModal>
                        </li>
                      ))
                    : exams.map((item, index) => (
                        <li key={"exam_name" + index}>
                          <img
                            className="img-fluid stack"
                            src={images.coin.default.src}
                          />
                          {item}
                          <ToolTipModal
                            text={
                              examCompleted ? "" : "Please complete the exam."
                            }
                          >
                            <Danger variant="Linear" />
                          </ToolTipModal>
                        </li>
                      ))
                  : null}
                {tdpSummary ? (
                  tdpSummary[0].is_parent_exam_completed ? (
                    <li className="completed">
                      <img
                        className="img-fluid stack"
                        src={images.coin.default.src}
                      />
                      <a
                        onClick={() => {
                          if (isTdpAvailable) {
                            handleAssessmentClick();
                          }
                          EventEmitter.dispatch("showMenuMobile");
                        }}
                      >
                        Parent's test
                      </a>
                      <ToolTipModal
                        text={
                          examCompleted
                            ? ""
                            : "Please fill the complete details of the section"
                        }
                      >
                        <Danger variant="Linear" />
                      </ToolTipModal>
                    </li>
                  ) : examCompleted ? (
                    <li className="partial">
                      <img
                        className="img-fluid stack"
                        src={images.coin.default.src}
                      />
                      <a
                        onClick={() => {
                          if (isTdpAvailable) {
                            handleAssessmentClick();
                          }
                          EventEmitter.dispatch("showMenuMobile");
                        }}
                      >
                        Parent's test
                      </a>
                      <ToolTipModal
                        text={
                          examCompleted
                            ? ""
                            : "Please fill the complete details of the section"
                        }
                      >
                        <Danger variant="Linear" />
                      </ToolTipModal>
                    </li>
                  ) : (
                    <li>
                      <img
                        className="img-fluid stack"
                        src={images.coin.default.src}
                      />
                      <a
                        onClick={() => {
                          if (isTdpAvailable) {
                            handleAssessmentClick();
                          }
                          EventEmitter.dispatch("showMenuMobile");
                        }}
                      >
                        Parent's test
                      </a>
                      <ToolTipModal
                        text={
                          examCompleted
                            ? ""
                            : "Please fill the complete details of the section"
                        }
                      >
                        <Danger variant="Linear" />
                      </ToolTipModal>
                    </li>
                  )
                ) : null}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={"4"} className="active">
            <Accordion.Header>
              <div className="col-12 start">
                <div className="row">
                  <div className="col-auto left">
                    <img
                      className="img-fluid stack"
                      src={images.coinStack.default.src}
                    />
                  </div>
                  <div className="col right ">
                    <h5 className="blink mb-0 text-pink">
                      {childDetails.first_name}
                      <span className="text-lowercase">'s</span>
                      &nbsp;Profiling
                      <img src={images.pb2.default.src} />
                    </h5>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {/* Profile progress bar */}
              <ProfilingBarometerProgressBar
                childDetail={childDetails}
                getSectionCoins={getSectionCoins}
                getSectionEarnedCoins={getSectionEarnedCoins}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={"5"} className="active">
            <Accordion.Header>
              <div className="col-12 start">
                <div className="row">
                  <div className="col-auto left">
                    <img
                      className="img-fluid stack"
                      src={images.miniStack.default.src}
                    />
                  </div>
                  <div className="col right ">
                    <h5 className="blink mb-1 text-orange">
                      {childDetails.first_name}
                      <span className="text-lowercase">'s</span>
                      &nbsp;On Boarding
                      <img src={images.pb1.default.src} />
                    </h5>
                    {mandatoryCoins ? (
                      <p className="mb-0">{`You have to earn a minimum of ${mandatoryCoins} Mandatory Field Coins in order to Unlock the Talent Discovery Stage`}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default DemographicBarometerProgressBar;
