import React, { useEffect, useState } from "react";
import { images } from "../../../../../constant";
import ToolTipModal from "../../../toot_tip/ToolTip";
import { Danger } from "iconsax-react";
import { useRouter } from "next/router";
import { EventEmitter, PROFILING_SCROLL_ID } from "../../../../helper";
import APIService from "../../../../@mock-api/api.service";

const apiService = new APIService();

function ProfilingBarometerProgressBar(props) {
  const { getSectionCoins, getSectionEarnedCoins } = props;
  const [fieldGroup, setFieldGroup] = useState([]);
  const router = useRouter();

  useEffect(async () => {
    apiService.getFieldGroup().then((res) => {
      let getFieldGroup = res.data.data.getfieldsGroup.filter(
        (val) => val.section != "image"
      );
      getFieldGroup = getFieldGroup.sort((a, b) =>
        a.sortOrder > b.sortOrder ? 1 : -1
      );
      setFieldGroup(getFieldGroup);
    });
  }, []);

  const handleSectionProgress = (section) => {
    return getSectionEarnedCoins &&
      (getSectionEarnedCoins(section == "avatar" ? "image" : section) ?? 0) != 0
      ? getSectionCoins &&
        getSectionEarnedCoins &&
        getSectionCoins(section == "avatar" ? "image" : section) ==
          getSectionEarnedCoins(section == "avatar" ? "image" : section)
        ? "completed"
        : getSectionEarnedCoins &&
          getSectionEarnedCoins(section == "avatar" ? "image" : section) > 0
        ? "partial"
        : ""
      : "";
  };

  const handleSectionName = (title) => {
    return title.section == "about"
      ? "profile-view-card"
      : title.section == "image"
      ? "editProfile"
      : title.section == "banner"
      ? "defaultBanner"
      : title.section;
  };

  const scrollToView = (id) => {
    if (router && router.pathname.includes("/assessment")) {
      router.push(
        {
          pathname: `/assessment`,
          query: { scrollId: id },
        },
        `/assessment`
      );
    } else {
      const viewToScroll = document.getElementById(id);

      if (viewToScroll) {
        let position = viewToScroll.getBoundingClientRect();
        viewToScroll.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }
    }
    EventEmitter.dispatch("showMenuMobile");
  };

  const checkMandatory = (field) => {
    let showAstrik = false;
    field?.map((val) => {
      if (val?.mandatory) {
        showAstrik = true;
      }
    });

    return showAstrik;
  };

  return (
    <div>
      <ul>
        {fieldGroup.length
          ? fieldGroup?.map((item, index) => (
              <li
                key={"field_group_" + index}
                className={
                  item.section == "avatar"
                    ? "completed"
                    : handleSectionProgress(item.section)
                }
              >
                <img
                  className="img-fluid stack"
                  src={images.coin.default.src}
                />
                <a
                  onClick={() => {
                    scrollToView(
                      handleSectionProgress(handleSectionName(item)) == ""
                        ? PROFILING_SCROLL_ID[`${item.section}`][0]
                        : PROFILING_SCROLL_ID[`${item.section}`][1]
                    );
                  }}
                >
                  {item.sectionTitle}
                  <span className={'text-pink'}>
                  {checkMandatory(item.fields) ? "*" : ""}</span>
                </a>
                <ToolTipModal
                  text={"Please fill the complete details of the section"}
                >
                  <Danger variant="Linear" />
                </ToolTipModal>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

export default ProfilingBarometerProgressBar;
