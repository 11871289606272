import React, { useEffect, useRef, useState } from "react";
import { images, starMessage } from "../../../../constant";
import ToolTipModal from "../../toot_tip/ToolTip";
import { GQ_ASSIGN_CHILD_ATTIBUTES } from "../../../@mock-api/graphql/child";
import {
  InfoCircle,
  AddCircle,
  Refresh,
  Star1,
  Trash,
  CloseCircle,
  ArrowUp2,
} from "iconsax-react";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import Rating from "react-rating";
import APIService from "../../../@mock-api/api.service";
import { ERROR_MESSAGE } from "../../../helper";
import { useSnackbar } from "notistack";
import Spinner from "react-bootstrap/Spinner";
const apiService = new APIService();

export default function SkillsEdit(props) {
  const {
    appreciatingLiners,
    eduLiner,
    handleEducateLiner,
    handleAppLiners,
    handleOutOffFocus,
    fieldCoins,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const subjectObj = {
    subject: null,
    score: 0,
  };
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedInterests, setSelectedInterest] = useState([]);
  const [selectedHobbies, setSelectedHobby] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [interestOptions, setInterestOptions] = useState([]);
  const [hobbyOptions, setHobbyOptions] = useState([]);
  const [selectedStar, setSelectedStar] = useState(0);
  const [allSkills, setAllSkills] = useState([]);
  const [allInterest, setAllInterest] = useState([]);
  const [allHobby, setAllHobby] = useState([]);
  const [hobbyText, setHobbyText] = useState(null);
  const [interestText, setInterestText] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [openMenuOnFocusInterests, setOpenMenuOnFocusInterests] =
    useState(false);
  const [openMenuOnFocusHobbies, setOpenMenuOnFocusHobbies] = useState(false);
  const [childDetails, setchildDetails] = useState("");

  const refInterest = useRef();

  const section_name = "co_curricular_information";

  useEffect(() => {
    const child = localStorage.getItem("child");
    setchildDetails(JSON.parse(child))
    handleOutOffFocus();
    getChildGradeAttribute(1, "Skill");
    getChildGradeAttribute(1, "Interest");
    getChildGradeAttribute(1, "Hobby");
  }, []);

  useEffect(() => {
    if (skillOptions.length) {
      const childAssignedAttributes = props.childAssignedAttributes;

      const subjects = [];
      const interests = [];
      const hobbies = [];
      if (childAssignedAttributes.length) {
        childAssignedAttributes.map((item) => {
          if (item.attribute_name === "Skill") {
            if (item.attributes.length) {
              item.attributes.map((val) =>
                subjects.push({
                  subject: val.title,
                  score: val.score,
                })
              );
              setSelectedSubjects(subjects);
            }
          }
          if (item.attribute_name === "Interest") {
            if (item.attributes.length) {
              item.attributes.map((val) => interests.push(val.title));
              setSelectedInterest(interests);
            }
          }
          if (item.attribute_name === "Hobby") {
            if (item.attributes.length) {
              item.attributes.map((val) => hobbies.push(val.title));
              setSelectedHobby(hobbies);
            }
          }
        });
      }
    }
  }, [skillOptions]);

  const getChildGradeAttribute = (standard, name) => {
    apiService
      .getChildGradeSubjects({ standard, name })
      .then((res) => {
        if (name === "Skill") {
          setAllSkills(res.data.data.getAttributesByStandard);
        }
        if (name === "Hobby") {
          setAllHobby(res.data.data.getAttributesByStandard);
        }
        if (name === "Interest") {
          setAllInterest(res.data.data.getAttributesByStandard);
        }

      
        processData(res.data.data.getAttributesByStandard, name);
      })
      .catch((error) => {
        if (error?.data?.errors) {
          console.error(error?.data?.errors[0].message);
        }
      });
  };

  const processData = (data, name) => {
    const options = [];



    if (data.length) {
      data.map((item) => {
        options.push({
          label: item.title,
          value: item.title,
        });
      });
    }
    if (name === "Skill") {
      setSkillOptions(options);
    }
    if (name === "Hobby") {
      setHobbyOptions(options);
    }
    if (name === "Interest") {
      setInterestOptions(options);
    }
  };

  const handleSelection = (index, item) => {
    const tempSubject = [...selectedSubjects];
    tempSubject[index].score = item;
    setSelectedSubjects(tempSubject);
  };

  const handleHover = (item) => {
    if (item) {
      setSelectedStar(item);
    } else {
      setSelectedStar(0);
    }
  };

  const skillSelection = (index, item) => {
    setErrorMessage("");
    const tempSubject = [];

    const tempSubjectDuplicate = [];
    selectedSubjects.map((elem) => {
      if (elem.subject == item.value) {
        tempSubjectDuplicate.push(elem);
      }
      tempSubject.push(elem);
    });
    if (tempSubjectDuplicate.length > 0) {
      tempSubject.splice(index, 1);
      setSelectedSubjects(tempSubject);
      setErrorMessage("Skill already selected.");
      return false;
    }
    tempSubject[index].subject = item.value;
    if (tempSubject.length == 3) {
      let sectionValues = {
        section_name: section_name,
        ...{
          hobbies: selectedHobbies[0] ? selectedHobbies[0] : "",
          interests: selectedInterests[0] ? selectedInterests[0] : "",
          skills: "skills",
        },
      };
    }

    setSelectedSubjects(tempSubject);
  };

  const addSubject = () => {
    setErrorMessage("");
    if (selectedSubjects.length == 0) {
      handleOnFocus("skills");
    }
    setSelectedSubjects((oldArray) => [...oldArray, subjectObj]);
  };

  const submit = () => {
    if (selectedSubjects.length < 3) {
      setErrorMessage("Please select atleast three skills.");
      return false;
    }

    for (let i = 0; i < selectedSubjects.length; i++) {
      if (!selectedSubjects[i].subject || !selectedSubjects[i].score) {
        setErrorMessage("One or more Skill and rating values are invalid.");
        return false;
      }
    }

    let skills = [];
    if (selectedSubjects.length) {
      skills = selectedSubjects.map((item) => {
        if (item["score"] > 0) {
          return { name: item["subject"], score: item["score"] };
        }
      });
    }

    const skillInput = {
      attribute: {
        attributes: skills,
        child: childDetails.id,
        attribute_name: "Skill",
      },
    };

    props.updateChildProfile(
      skillInput,
      GQ_ASSIGN_CHILD_ATTIBUTES,
      "main_card_co-curri"
    );
  };

  const deleteSubject = (index) => {
    const tempSubject = [...selectedSubjects];
    tempSubject.splice(index, 1);
    setSelectedSubjects(tempSubject);
  };

  const deleteInterestOrHobby = (index, type) => {
    if (type === "Interest") {
      const tempInterest = [...selectedInterests];
      tempInterest.splice(index, 1);
      setInterestText(null);
      setSelectedInterest(tempInterest);
    }
    if (type === "Hobby") {
      const tempHobbies = [...selectedHobbies];
      tempHobbies.splice(index, 1);
      setHobbyText(null);
      setSelectedHobby(tempHobbies);
    }
  };

  const resetSubject = (index) => {
    const tempSubject = [...selectedSubjects];
    tempSubject[index].score = 0;
    setSelectedSubjects(tempSubject);
  };

  const interestHobbySelection = (value, type) => {
    setErrorMessage("");
    if (type === "Interest") {
      if (!selectedInterests.includes(value.value)) {
        if (selectedInterests.length == 2) {
          let sectionValues = {
            section_name: section_name,
            ...{
              hobbies: selectedHobbies[0] ? selectedHobbies[0] : "",
              interests: "interests",
              skills: selectedSubjects[0] ? selectedSubjects[0] : "",
            },
          };
        }
        setSelectedInterest((oldItems) => [...oldItems, value.value]);
        setInterestText(value.value);
      }
    }
  };

  const renderIcon = (data, type, item) => {
    let attr = [];
    if (type === "Skill") {
      attr = data.filter((attribute) => attribute.title === item.subject);
    }
    if (type === "Interest" || type === "Hobby") {
      attr = data.filter((attribute) => attribute.title === item);
    }
    if (type === "Skill") {
      return attr.length
        ? attr[0].icon
        : process.env.NEXT_PUBLIC_DEFAULT_SEEDER_ICON_BLUE;
    }
    return attr.length
      ? attr[0].icon
      : process.env.NEXT_PUBLIC_DEFAULT_SEEDER_ICON;
  };
  const handleOnFocus = (field) => {
    setErrorMessage("");
  };

  const handleOnFocusSelect = (field) => {
    if (field == "interests") {
      setOpenMenuOnFocusInterests(true);
    }
    if (field == "hobbies") {
      setOpenMenuOnFocusHobbies(true);
    }
  };

  const handleOnBlur = () => {
    handleOutOffFocus();
    setOpenMenuOnFocusInterests(false);
    setOpenMenuOnFocusHobbies(false);
  };

  const generateClass = (total, index) => {
    let className = "tag-pink";
    if ((total - index) % 4 === 0) {
      className = "tag-yellow";
    }
    if ((total - index) % 4 === 1) {
      className = "tag-darkBlue";
    }
    if ((total - index) % 4 === 2) {
      className = "tag-blue";
    }
    if ((total - index) % 4 === 3) {
      className = "tag-pink";
    }
    return `col-auto icon icon-slc ${className} ps-0`;
  };

  return (
    <>
      <div className=" form-main">
      {props.showquestiontext && (
                <div className="Quetion_no_div">
                <div className="Quetion_no">
                  <p>Question {props.showsecondchildcurrentindex}/{props.showsecondchildlength}</p>
                </div>
              </div>
              )}
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 order-2 order-sm-2 order-md-1">
            <h2 class="modal-title modal-title modalt1 mt-3 mb-3">
              Tell us about {childDetails.first_name}’s Skills.
            </h2>
          </div>
          <div className="col-12 col-md-6 col-sm-12 order-1 order-sm-1 order-md-2">
            <img src={images.skills.default.src} className="skillsimage" />
          </div>
          <div className="col-12 order-3 add-card" id={"add-card-cocurricular-sub"}>
            <div className="col-12 head">
              <div className="row align-items-center">
                <div className="col-auto">
                  <h4 className="mb-0">
                    <span className="text-capitalize">
                      {childDetails.first_name}
                    </span>
                    ’<span>s</span> Skills
                    <ToolTipModal
                      text={props.getEducatingLiners(
                        "co_curricular_information",
                        "skills"
                      )}
                    >
                      <a type="button">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z"
                            fill="#999999"
                          ></path>
                          <path
                            d="M9 7.875C9.3075 7.875 9.5625 8.13 9.5625 8.4375L9.5625 12.1875C9.5625 12.495 9.3075 12.75 9 12.75C8.6925 12.75 8.4375 12.495 8.4375 12.1875L8.4375 8.4375C8.4375 8.13 8.6925 7.875 9 7.875Z"
                            fill="#999999"
                          ></path>
                          <path
                            d="M9 5.43759C9.0975 5.43759 9.195 5.46009 9.285 5.49759C9.375 5.53509 9.4575 5.58759 9.5325 5.65509C9.6 5.73009 9.6525 5.80509 9.69 5.90259C9.7275 5.99259 9.75 6.09009 9.75 6.18759C9.75 6.28509 9.7275 6.38259 9.69 6.47259C9.6525 6.56259 9.6 6.64509 9.5325 6.72009C9.4575 6.78759 9.375 6.84009 9.285 6.87759C9.105 6.95259 8.895 6.95259 8.715 6.87759C8.625 6.84009 8.5425 6.78759 8.4675 6.72009C8.4 6.64509 8.3475 6.56259 8.31 6.47259C8.2725 6.38259 8.25 6.28509 8.25 6.18759C8.25 6.09009 8.2725 5.99259 8.31 5.90259C8.3475 5.80509 8.4 5.73009 8.4675 5.65509C8.5425 5.58759 8.625 5.53509 8.715 5.49759C8.805 5.46009 8.9025 5.43759 9 5.43759Z"
                            fill="#999999"
                          ></path>
                        </svg>
                      </a>
                    </ToolTipModal>
                  </h4>
                </div>
                <div className="col">
                  <hr className="m-0" />
                </div>
                <div className="col-auto">
                  <a
                    onClick={addSubject}
                    id={"add-anchor-cocurricular"}
                    type="button"
                    className="add-link"
                  >
                    <AddCircle id={"add-icon-cocurricular"} variant="Linear" />
                    Add skill
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 body" id={"body-section-cocurricular"}>
              <div
                className="col-12 card card-xs empty-card bg-white p-0 mb-0"
                id={"sub-card-cocurricular"}
              >
                {!!selectedSubjects.length && (
                  <>
                    {selectedSubjects.map((item, index) => (
                      <div
                        className="col-12 card card-xxs school-card sch-xs"
                        key={`co-curricular-${index}`}
                        id={`subject-each-section-coc-${index + 1}`}
                      >
                        <div
                          className="row align-items-center"
                          id={`subject-each-row-coc-${index + 1}`}
                        >
                          <div
                            className="col-auto icon"
                            id={`subject-each-col-coc-${index + 1}`}
                          >
                            {item.subject && (
                              <img
                                className="img-fluid"
                                src={renderIcon(allSkills, "Skill", item)}
                              />
                            )}
                          </div>
                          <div
                            className={
                              item.subject
                                ? "col-md-4 ps-0 col name"
                                : "col-md ps-0 col name"
                            }
                            id={`subject-each-name-coc-${index + 1}`}
                          >
                            <CustomAutoComplete
                              handleOnBlur={() => handleOnBlur()}
                              handleOnFocus={() => {}}
                              standard={childDetails.school_standard}
                              attribute_name={"Skill"}
                              type={"rating"}
                              options={skillOptions}
                              selectedOption={selectedSubjects[index].subject}
                              selectedSubject={(value) =>
                                skillSelection(index, value)
                              }
                              resetAfterSelect={false}
                              id={`subject-${index + 1}`}
                              placeholder={"Click here to add skills"}
                              onFocusPlaceholder={"Start typing skills"}
                              selectedArr={selectedSubjects}
                            />
                          </div>
                          {item.subject && (
                            <div
                              className="col info"
                              id={`subject-each-col-info-coc-${index + 1}`}
                            >
                              <div
                                className="col-12 d-flex align-items-center"
                                id={`subject-main-rating-section-coc-${
                                  index + 1
                                }`}
                              >
                                <div
                                  className="rating d-flex"
                                  id={`subject-sub-rating-coc-${index + 1}`}
                                >
                                  <Rating
                                    id={`ratng-each-subject-coc-${index + 1}`}
                                    initialRating={
                                      selectedSubjects[index].score
                                    }
                                    fractions={1}
                                    readonly={false}
                                    onClick={(item) =>
                                      handleSelection(index, item)
                                    }
                                    type="array"
                                    emptySymbol={<Star1 color={"#FFC929"} />}
                                    fullSymbol={
                                      <Star1
                                        className="filled"
                                        variant="Linear"
                                        color={"#FFC929"}
                                      />
                                    }
                                  />
                                </div>
                                <h6
                                  className="mb-0 ms-2"
                                  id={`subject-message-rating-coc-${index + 1}`}
                                >
                                  {starMessage[
                                    selectedSubjects[index].score - 1
                                  ]
                                    ? starMessage[
                                        selectedSubjects[index].score - 1
                                      ]
                                    : "Provide ratings to Skill"}
                                </h6>
                              </div>
                            </div>
                          )}

                          <div className="col-auto ps-0">
                            <a
                              onClick={() => resetSubject(index)}
                              id={`subject-refresh-coc-${index + 1}`}
                              className="edit-icon float-start"
                            >
                              <Refresh
                                id={`subject-refresh-edit-icon-coc-${
                                  index + 1
                                }`}
                              />
                            </a>
                            <a
                              onClick={() => deleteSubject(index)}
                              id={`subject-trash-coc-${index + 1}`}
                              className="edit-icon float-start ms-3"
                            >
                              <Trash
                                id={`subject-trash-icon-coc-${index + 1}`}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                <a type="button" onClick={addSubject}>
                  <div
                    className="col-12 card card-border card-xxs text-center mb-0"
                    id={"add-last-text"}
                  >
                    <h6 className="text-grey mb-0" id={"add-last-text-h6"}>
                      Click Here to add New Skill
                    </h6>
                  </div>
                </a>
              </div>
              <hr className="mt-4 mb-3" />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-footer coin-anim skillmodalfooter modfooter  ${
          eduLiner && "anim-1"
        }    ${appreciatingLiners && "anim-1 anim-2 anim-3"} ${
          errorMessage && "anim-1 anim-2 anim-3"
        } `}
      >
        <center>
          <button
            type="button"
            className={"btn btn-lg btn-black"}
            onClick={submit}
            disabled={props.showloader ? true : false}
              >
                {props.showloader && (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}{" "}
            Save
          </button>
        </center>

        <div className="text">
          <div className=" align-items-center col-auto">
            {!errorMessage && (
              <>
                <div className="points">
                  <img
                    className="img-fluid coin-float"
                    src={images.coin.default.src}
                  />
                  <img className="img-fluid" src={images.coin.default.src} />
                </div>
                <h6 className="mb-0">{eduLiner}</h6>
                <h6 className="mb-0 text-blue">{appreciatingLiners}</h6>
              </>
            )}
            <h6 className="mb-0 text-pink">{errorMessage}</h6>
          </div>
        </div>
      </div>
    </>
  );
}
