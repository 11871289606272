import { images } from "../../../constant";
import { Danger, Flag2 } from "iconsax-react";
import ProgressBar from "react-bootstrap/ProgressBar";
import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import ToolTipModal from "../toot_tip/ToolTip";
import { useRouter } from "next/router";
import { ERROR_MESSAGE, EventEmitter } from "../../helper";
import APIService from "../../@mock-api/api.service";
import ProfilingBarometerProgressBar from "../child/views/demographic_barometer_progress_bar/ProfilingBarometerProgressBar";
import { useSnackbar, withSnackbar } from "notistack";
import AssessmentServices from "../../services/AssessmentServices";
const apiService = new APIService();

export const TDPProgressView = ({
  percentageBarometric,
  childDetail,
  childChanged,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const [tdpSummary, setTdpSummary] = useState([]);

  const scrollRef = useRef();

  const [fieldGroup, setFieldGroup] = useState(null);
  const [childFieldGroup, setChildFieldGroup] = useState(null);
  const [examCompleted, setExamCompleted] = useState(false);
  const [mandatoryCoins, setMandatoryCoins] = useState(null);

  const accorRef = useRef();

  const exams = [
    "Emotional Stability",
    "Aptitude",
    "Logical Reasoning",
    "Applied Knowledge",
    "Creativity",
    "Accountability",
    "Linguistics",
    "Kinesthetic and Spatial",
    "Interest test",
  ];

  const examsHindi = [
    "भावनात्मक स्थिरता",
    "कौशल",
    "तार्किक विचार",
    "लागू ज्ञान",
    "रचनात्मकता",
    "जवाबदेही",
    "भाषा विज्ञान",
    "किनेस्टेटिक और स्थानिक",
    "बच्चे की दिलचस्पी का परीक्षण",
  ];

  useEffect(async () => {
    scrollRef.current.scrollTop = 300;
    let coins = await localStorage.getItem("mandatoryCoins");
    if (coins) {
      setMandatoryCoins(coins);
    }
  }, []);

  //This method will be called after a delay so that if TDP summary available api will not be called.
  useEffect(() => {
    setTimeout(async () => {
      if (childDetail) {
        getTDPSummary();
      }
    }, 500);
    let paramGroup = localStorage.getItem("fieldGroup");
    if (paramGroup) {
      setFieldGroup(JSON.parse(paramGroup));
    }
    let childParamGroup = localStorage.getItem("childFieldsGroup");
    if (childParamGroup) {
      setChildFieldGroup(JSON.parse(childParamGroup));
    }

    let examCompleted = localStorage.getItem("wishList");
    if (examCompleted == "true") {
      setExamCompleted(true);
    } else {
      setExamCompleted(false);
    }
  }, []);

  // useEffect(() => {
  //   setTimeout(async () => {
  //     let examCompleted = await localStorage.getItem("wishList");
  //     if (examCompleted == "true") {
  //       setExamCompleted(true);
  //     } else {
  //       setExamCompleted(false);
  //     }
  //     let selectedChild = await localStorage.getItem("selected_child");
  //     if (selectedChild) {
  //       getTDPSummary();
  //     }
  //   }, 0);
  
  // }, [childDetail]);





 const getTDPSummary=()=> {
    let userData = localStorage.getItem("userDetails");
    const childId = localStorage.getItem("selected_child");
    if (childDetail?.id) {
     
      const token = localStorage.getItem("aark_auth");
      AssessmentServices.getTDPSummaryV3(token,{ type: "child", dimension_exam_user_id: childId,childId:childId  })
        .then((res) => {
          if(res.data.code==200&&res.data.status=="pass")
          {

          if (res.data.data && res.data.data) {
          
            setTdpSummary(res.data.data);
          } else {
            enqueueSnackbar(
                "Something Went Wrong",
                ERROR_MESSAGE
              );
          }
        }
        });
    }
  }

  const getSectionCoins = (section) => {
    if (fieldGroup?.length) {
      return fieldGroup.find((field) => field.section === section)?.total_coins;
    }
  };

  const getSectionEarnedCoins = (section) => {
    if (childFieldGroup?.length) {
      const sectionData = childFieldGroup.find(
        (field) => field.section === section
      );
      if (sectionData) {
        return sectionData.total_coins;
      }
      return 0;
    }
  };

  const scrollToView = (id) => {
    const viewToScroll = document.getElementById(id);
    if (viewToScroll) {
      let position = viewToScroll.getBoundingClientRect();
      viewToScroll.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
      window.scrollTo(position.left, position.top + window.scrollY - 100);
      // window.scrollTo({
      //     top: viewToScroll.offsetTop,
      //     behavior: "smooth"
      // })
    }
    EventEmitter.dispatch("showMenuMobile");
  };

  return (
    <div className="col-12 card card-md">
      <div className="col-12 content">
        <Accordion ref={scrollRef} className="scr-yellow" defaultActiveKey="3">
          <Accordion.Item
            eventKey="0"
            className={examCompleted ? "active" : "examCompleted"}
          >
            <Accordion.Header>
              <a
                onClick={() => {
                  if (examCompleted) {
                    router.push("/learning");
                  }
                }}
              >
                <ToolTipModal
                  text={
                    examCompleted
                      ? ""
                      : "Please complete the previous stages to unlock the further journey"
                  }
                >
                  <div className="col-12 start">
                    <div className="row">
                      <div className="col-auto left">
                        <img
                          className="img-fluid stack"
                          src={images.coinStack.default.src}
                        />
                      </div>
                      <div className="col right ">
                        <h5 className="blink mb-0 text-pink">
                          {childDetail?.first_name}
                          <span className="text-lowercase">'s</span>
                          &nbsp;{localStorage.getItem("language")==="hindi" ? "शिक्षण यात्रा":"Learning Journey"}
                          <img src={images.pb6.default.src} />
                        </h5>
                      </div>
                    </div>
                  </div>
                </ToolTipModal>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            className={examCompleted ? "active" : "examCompleted"}
          >
            <Accordion.Header>
              <a
                onClick={() => {
                  if (examCompleted) {
                    router.push("/course");
                  }
                }}
              >
                <ToolTipModal
                  text={
                    examCompleted
                      ? ""
                      : "Please complete the previous stages to unlock the further journey"
                  }
                >
                  <div className="col-12 start">
                    <div className="row">
                      <div className="col-auto left">
                        <img
                          className="img-fluid stack"
                          src={images.coinStack.default.src}
                        />
                      </div>
                      <div className="col right ">
                        <h5 className="blink mb-0 text-blue">
                          {childDetail?.first_name}
                          <span className="text-lowercase">'s</span>
                          &nbsp;{localStorage.getItem("language")==="hindi" ? "शिक्षण योजना":"Learning Plan"}
                          <img src={images.pb5.default.src} />
                        </h5>
                      </div>
                    </div>
                  </div>
                </ToolTipModal>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            className={examCompleted ? "active" : "examCompleted"}
          >
            <Accordion.Header>
              <a
                onClick={() => {
                  if (examCompleted) {
                    router.push(`/report/3-5/${childDetail?.id}`);
                  }
                }}
              >
                <ToolTipModal
                  text={
                    examCompleted
                      ? ""
                      : "Please complete the previous stages to unlock the further journey"
                  }
                >
                  <div className="col-12 start">
                    <div className="row">
                      <div className="col-auto left">
                        <img
                          className="img-fluid stack"
                          src={images.coinStack.default.src}
                        />
                      </div>
                      <div className="col right ">
                        <h5 className="blink mb-0 text-yellow">
                          {childDetail?.first_name}
                          <span className="text-lowercase">'s</span>
                          &nbsp;{localStorage.getItem("language")==="hindi" ? "प्रतिभा खोज रिपोर्ट":"Talent Discovery Report"}
                          <img src={images.pb4.default.src} />
                        </h5>
                      </div>
                    </div>
                  </div>
                </ToolTipModal>
              </a>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={"active"}>
            <Accordion.Header>
              <div className="col-12 start">
                <div className="row">
                  <div className="col-auto left">
                    <img
                      className="img-fluid stack"
                      src={images.coinStack.default.src}
                    />
                  </div>
                  <div className="col right ">
                    <h5 className="blink mb-0 text-lightBlue">
                      {childDetail?.first_name}
                      <span className="text-lowercase">'s</span>
                      &nbsp;{localStorage.getItem("language")==="hindi" ? "प्रतिभा खोज मूल्यांकन":"Talent Discovery Assessment"}
                      <img src={images.pb3.default.src} />
                    </h5>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ul>

              {tdpSummary?.length ? (
                  tdpSummary[0].is_interest_exam_completed  ? (
                    <li class="completed">
                      <img
                        className="img-fluid stack"
                        src={images.coin.default.src}
                      />
                      <a onClick={() => scrollToView(`parent_tdp_0`)}>
                       {localStorage.getItem("language")==="hindi" ?"बच्चे की दिलचस्पी का परीक्षण": "Interest's test"}
                      </a>
                      <ToolTipModal text={"Please complete the assessment"}>
                        <Danger variant="Linear" />
                      </ToolTipModal>
                    </li>
                  ) : (
                    <li class="partial">
                      <img
                        className="img-fluid stack"
                        src={images.coin.default.src}
                      />
                      <a onClick={() => scrollToView(`parent_tdp_0`)}>
                      {localStorage.getItem("language")==="hindi" ?"बच्चे की दिलचस्पी का परीक्षण": "Interest's test"}
                      </a>
                      <ToolTipModal text={"Please complete the assessment"}>
                        <Danger variant="Linear" />
                      </ToolTipModal>
                    </li>
                  )
                ) : null}
                {tdpSummary
                  ? tdpSummary.length
                    ? tdpSummary.map((item, index) => (
                        <li
                          key={"tdp_summary_" + index}
                          className={
                            item.is_exam_completed
                              ? "completed"
                              : item.is_exam_inprogress
                              ? "partial"
                              : ""
                          }
                        >
                          <img
                            className="img-fluid stack"
                            src={images.coin.default.src}
                          />
                          <a onClick={() => scrollToView(`child_id_${index}`)}>
                            {localStorage.getItem("language")==="hindi" ? item.dimension_name_hindi:item.dimension_name}
                          </a>
                          <ToolTipModal text={"Please complete the exam."}>
                            <Danger variant="Linear" />
                          </ToolTipModal>
                        </li>
                      ))
                    : (localStorage.getItem("language")==="hindi" ? examsHindi:exams).map((item, index) => (
                        <li key={"exam_name" + index}>
                          <img
                            className="img-fluid stack"
                            src={images.coin.default.src}
                          />
                          {item}
                          <ToolTipModal text={"Please complete the exam."}>
                            <Danger variant="Linear" />
                          </ToolTipModal>
                        </li>
                      ))
                  : null}
               
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item className="active" eventKey="4">
            <Accordion.Header>
              <div className="col-12 start">
                <div className="row">
                  <div className="col-auto left">
                    <img
                      className="img-fluid stack"
                      src={images.coinStack.default.src}
                    />
                  </div>
                  <div className="col right ">
                    <h5 className="blink mb-0 text-pink">
                      {childDetail?.first_name}
                      <span className="text-lowercase">'s</span>
                      &nbsp;Profiling
                      <img src={images.pb2.default.src} />
                    </h5>
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ProfilingBarometerProgressBar
                childDetail={childDetail}
                getSectionCoins={getSectionCoins}
                getSectionEarnedCoins={getSectionEarnedCoins}
              />
            </Accordion.Body>
          </Accordion.Item> */}
          <Accordion.Item eventKey={"5"} className="d-none">
            <Accordion.Header>
              <div className="col-12 start">
                <div className="row">
                  <div className="col-auto left">
                    <img
                      className="img-fluid stack"
                      src={images.miniStack.default.src}
                    />
                  </div>
                  <div className="col right ">
                    <h5 className="blink mb-1 text-orange">
                      {childDetail?.first_name}
                      <span className="text-lowercase">'s</span>
                      &nbsp;On Boarding
                      <img src={images.pb1.default.src} />
                    </h5>
                    {mandatoryCoins ? (
                      <p className="mb-0">{`You have to earn a minimum of ${mandatoryCoins} Mandatory Field Coins in order to Unlock the Talent Discovery Assessment Stage`}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};
