export const GQ_LOGIN = `mutation Login(
  $email: String
  $phone: String
  $password: String
  $device_type: String
  $operating_system: String
  $browser_name: String
  $browser_version: String
  $OS_version: String
  $user_name: String
) {
  login(
    email: $email
    phone: $phone
    password: $password
    device_type: $device_type
    operating_system: $operating_system
    browser_name: $browser_name
    browser_version: $browser_version
    OS_version:$OS_version
    user_name: $user_name
  ) {
    id
    first_name
    last_name
    email
    phone
    role
    token
    role
    business_name
    childCount
    ta_guidelines_checked
    accessToken
    country_code
  }
}`;

export const GQ_REGISTER = `mutation Mutation($user: UserCreateinput) {
  createUser(user: $user) {
  id
  first_name
  last_name
    email
    phone
    token
    role
    childCount
    ta_guidelines_checked
  }
}`;

export const GQ_EMAIL_VERIFICATION = `mutation Mutation($email: String) {
  verifyEmail(email: $email) {
    id
  }
}`;


export const GQ_PROVIDER_EMAIL_VERIFICATION = `mutation Mutation($email: String) {
  verifyProviderEmail(email: $email) {
    id
  }
}`;

export const GQ_PARENT_EMAIL_VERIFICATION = `mutation VerifyEmail($email: String) {
  verifyEmail(email: $email) {
    id
    email
    phone
    phoneVerifiedAt
    emailVerifiedAt
  }
}`;

export const GQ_UPDATE_VIEW_REFER_COUNT = `mutation UpdateViewReferCount($referralToken: String) {
  updateViewReferCount(referral_token: $referralToken) {
    id
    referral_token
    no_of_page_views
    no_of_successful_referral
    createdAt
    updatedAt
  }
}`

export const GQ_PARENT_EARLY_ACCESS = `mutation ParentEarlyAccess($user: parentEarlyAccessInput) {
  parentEarlyAccess(user: $user) {
    id
    first_name
    last_name
    email
    emailVerifiedAt
    phoneVerifiedAt
  }
}`;

export const GQ_PROVIDER_REGISTER = `mutation Mutation($user: UserCreateinput) {
  createUser(user: $user) {
  id
  first_name
  last_name
    email
    phone
    token
    role
    business_name
    childCount
  }
}`;

export const GQ_PARENT_MOBILE_DECODE = `mutation DecodeParentNumber($token: String) {
  decodeParentNumber(token: $token) {
    phone
    verified
  }
}`;



export const GQ_GENERATE_OTP = `  mutation Mutation($email: String, $phone: String) {
    generateOtp(email: $email, phone: $phone) {
      otp
    }
  }`

export const GQ_GENERATE_PARENT_TOKEN_WITH_PHONE = `mutation GenerateParentTokenWithPhone($phone: String) {
  generateParentTokenWithPhone(phone: $phone) {
    id
  }
}`;

export const GQ_GENERATE_VERIFICATION_EMAIL_PARENT = `mutation generateEmailVerificationLink($email: String) {
  generateEmailVerificationLink(email: $email) {
    id
  }
}`;


export const GQ_VERIFY_PROVIDER_OTP = `mutation Mutation($phone: String, $otp: Int) {
  verifyProviderOtp(phone: $phone, otp: $otp) {
    id
  }
}`

export const GQ_VERIFY_OTP = `mutation Mutation($otp: Int, $phone: String) {
  verifyOtp(otp: $otp, phone: $phone) {
    id
    phone
    otp
  }
}`

export const GQ_VERIFY_PARENT_OTP = `mutation Mutation($phone: String, $otp: Int) {
  verifyParentOtp(phone: $phone, otp: $otp) {
    id
    phone
    phoneVerifiedAt
    emailVerifiedAt
  }
}`

export const GQ_GENERATE_PARENT_TOKEN = `mutation GenerateParenttoken($email: String, $phone: String) {
  generateParenttoken(email: $email, phone: $phone) {
    tokenNumber
  }
}`;

// forgot password
export const GQ_FORGOT_PASSWORD = `mutation ForgetPassword($email: String) {
  forgetPassword(email: $email) {
    id
   
  }
}`;
export const GQ_VERIFY_FORGOT_EMAIL = `mutation VerifyForgotEmail($email: String, $otp: Int) {
  verifyForgotEmail(email: $email, otp: $otp) {
    id
  }
}`;
export const GQ_RESET_PASSWORD = `mutation ResetPassword($data: PasswordResetInput) {
  resetPassword(data: $data) {
    id
    first_name
    last_name
    email
  }
}`;

export const GQ_LOGIN_VIA_TOKEN = `mutation LoginByToken(
  $token: String
  $id: String
  $admin: String
  $device_type: String
  $operating_system: String
  $browser_name: String
  $browser_version: String
  $OS_version: String
) {
  loginByToken(
    token: $token
    id: $id
    admin: $admin
    device_type: $device_type
    operating_system: $operating_system
    browser_name: $browser_name
    browser_version: $browser_version
    OS_version: $OS_version
  ) {
    id
    first_name
    last_name
    email
    phone
    role
    authToken
    role
    business_name
    childCount
    ta_guidelines_checked
    type
  }
}`;




export const GQ_GENERATE_PARENT_PASSWORD = `mutation GenerateParentPassword($email: String, $password: String, $confirmPassword: String) {
  generateParentPassword(email: $email, password: $password, confirm_password: $confirmPassword) {
    id
  }
}`;

//
// export const GQ_FORGOT_PASSWORD = `mutation Mutation($email: String) {
//   forgetPassword(email: $email) {
//     otp
//     email
//   }
// }`;
//

//
export const GQ_GENERATE_OTP_PARENT = `mutation GenerateOtpParent($phone: String) {
  generateOtpParent(phone: $phone) {
    id
    otp
  }
}`;


export const GQ_LOGIN_VIA_OTP = `mutation VerifyLoginOTP(
  $email: String
  $phone: String
  $otp: Int
  $device_type: String
  $operating_system: String
  $browser_name: String
  $browser_version: String
  $OS_version: String
) {
  verifyLoginOTP(
    email: $email
    phone: $phone
    otp: $otp
    device_type: $device_type
    operating_system: $operating_system
    browser_name: $browser_name
    browser_version: $browser_version
    OS_version: $OS_version
  ) {
    id
    first_name
    last_name
    email
    phone
    role
    token
    role
    business_name
    childCount
    ta_guidelines_checked
    country_code
     paid_bundle_quantity
  }
}`;


export const GQ_GENERATE_LOGIN_OTP = `mutation GenerateLoginOTP($email: String, $phone: String) {
  generateLoginOTP(email: $email, phone: $phone)
}`;


export const GQ_GET_REGIME_PREFERENCE = `query GetSelectedEaRegimePreference {
  getSelectedEaRegimePreference {
    id
    ea_preference_regime
    session_price_after
    session_price_before
    createdAt
  }
}`;

export const GQ_VALIDATE_EMAIL = `mutation ValidateEmail($email: String) {
  validateEmail(email: $email)
}`;
