import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";
import { useSnackbar } from "notistack";
import APIService from "../../@mock-api/api.service";
import { ERROR_MESSAGE } from "../../helper";
const apiService = new APIService();

export default function CustomAutoComplete(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fieldFocused, setFieldFocused] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (selection) => {
    if (!props.resetAfterSelection) {
      setSelectedOption(selection);
    }
    props.selectedSubject(selection);
  };

  const handleCreate = (textInput) => {
        let input = textInput?.trim()?.split(/ +/)?.join(' ');

    setIsLoading(true);
    switch (props.attribute_name) {
      case "Hobby":
        addNewAttibuteOption(input);
        break;
      case "Subject":
        addNewAttibuteOption(input);
        break;
      case "Interest":
        addNewAttibuteOption(input);
        break;
      case "Skill":
        addNewAttibuteOption(input);
        break;
      case "Industry":
        createFamilyAttribute(input);
        break;
      case "Career":
        createFamilyAttribute(input);
        break;
      case "Designation":
        createFamilyAttribute(input);
        break;
      case "School":
        addNewSchool(input);
        break;
      case "online_courses":
        addOnlineCourse(input);
        break;
      case "Favourite Sport":
        addNewAttibuteOption(input);
        break;
      case "Favourite Food":
        addNewAttibuteOption(input);
        break;
      case "Favourite Music":
        addNewAttibuteOption(input);
        break;
      case "Favourite Movie":
        addNewAttibuteOption(input);
        break;
      case "expectation":
        createParentExpectationFearConcerns(input);
        break;
      case "concern":
        createParentExpectationFearConcerns(input);
        break;
      case "fear":
        createParentExpectationFearConcerns(input);
        break;
        case "courses":
          addOnlineCourse(input);
        break;
    };
  };

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    setSelectedOption({
      label: props.selectedOption,
      value: props.selectedOption,
    });
  }, [props.selectedOption]);

  const createParentExpectationFearConcerns = (name) => {
    const data = {
      name: name,
      type: props.attribute_name,
    };

    apiService
      .createParentExpectationFearConcerns(data)
      .then((res) => {
        if (
          res.data.data &&
          res.data.data.createParentExpectationFearConcerns
        ) {
          let data = {
            label: res.data.data.createParentExpectationFearConcerns.name,
            value: res.data.data.createParentExpectationFearConcerns.name,
          };
          setOptions((oldOptions) => [...oldOptions, data]);
          setSelectedOption(data);
          props.selectedSubject(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          if(res.data?.errors && res.data.errors[0]) {
                    enqueueSnackbar(res?.data?.errors[0]?.message, ERROR_MESSAGE);
                }
      }})
      .catch((error) => {
            if(res.data?.errors && res.data.errors[0]) {
                enqueueSnackbar(res?.data?.errors[0]?.message, ERROR_MESSAGE);
            }
        setIsLoading(false);
      });
  };

  const addNewSchool = (name) => {
    apiService
      .addNewSchool({ name })
      .then((res) => {
        if (res.data.data && res.data.data.addSchool) {
          let data = {
            label: res.data.data.addSchool.name,
            value: res.data.data.addSchool.name,
          };
          setOptions((oldOptions) => [...oldOptions, data]);
          setSelectedOption(data);
          props.selectedSubject(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, ERROR_MESSAGE);
        setIsLoading(false);
      });
  };

    const addOnlineCourse=(name)=>{
        apiService.addOnlineCourse({name}).then((res) => {

            if (res.data.data && res.data.data.addOnlineCourse) {
                let data = {
                    label: res.data.data.addOnlineCourse.name,
                    value: res.data.data.addOnlineCourse.name,
                };
                setOptions((oldOptions) => [...oldOptions, data]);
          setSelectedOption(data);
          props.selectedSubject(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, ERROR_MESSAGE);
        setIsLoading(false);
      });
  };

  const addNewAttibuteOption = (name) => {
    const data = {
      title: name.trim(),
      standard: parseInt(props.standard),
      attribute_name: props.attribute_name,
      type: props.type,
      sortOrder: 0,
      isApproved: 0,
    };

    apiService
      .addGlobalChildAttributes({ attribute: data })
      .then((res) => {
        if (res.data.data && res.data.data.createChildAttribute) {
          let data = {
            label: res.data.data.createChildAttribute.title,
            value: res.data.data.createChildAttribute.title,
          };
          setOptions((oldOptions) => [...oldOptions, data]);
          setSelectedOption(data);
          props.selectedSubject(data);
          setIsLoading(false);
        } else {
          enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, ERROR_MESSAGE);
        setIsLoading(false);
      });
  };

    const createFamilyAttribute = (name) => {
        const data = {
            title: name,
            type: props.type,
        };

    apiService
      .createFamilyAttribute(data)
      .then((res) => {
        if (res.data.data && res.data.data.createFamilyAttribute) {
          let data = {
            label: res.data.data.createFamilyAttribute.title
              ? res.data.data.createFamilyAttribute.title
              : res.data.data.createFamilyAttribute.name,
            value: res.data.data.createFamilyAttribute.title
              ? res.data.data.createFamilyAttribute.title
              : res.data.data.createFamilyAttribute.name,
          };
          setOptions((oldOptions) => [...oldOptions, data]);
          setSelectedOption(data);
          props.selectedSubject(data);
          setIsLoading(false);
        } else {
          enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, ERROR_MESSAGE);
        setIsLoading(false);
      });
  };
  const formatCreate = (inputValue) => {
    return "Add: " + '"' + inputValue + '"';
  };

  const getFilteredoptions = (arr) => {
    if (props?.selectedArr?.length != 0) {
      let arr1 = [];
      let arr2 = [];
      let res = [];
      switch (props.attribute_name) {
        case "Hobby":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item.label));
          return res;
          break;
        case "Subject":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1.filter(function (array_el) {
            return (
              arr2?.filter(function (anotherOne_el) {
                return anotherOne_el?.subject == array_el?.label;
              }).length == 0
            );
          });
          return res;
          break;
        case "Interest":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "Skill":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter(function (array_el) {
            return (
              arr2?.filter(function (anotherOne_el) {
                return anotherOne_el?.subject == array_el?.label;
              }).length == 0
            );
          });
          return res;
          break;
        case "Favourite Sport":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "Favourite Food":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "Favourite Music":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "Favourite Movie":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "expectation":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "fear":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "concern":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "courses":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter((item) => !arr2?.includes(item?.label));
          return res;
          break;
        case "online_courses":
          arr1 = arr;
          arr2 = props.selectedArr;
          res = arr1?.filter(function (array_el) {
            return (
              arr2?.filter(function (anotherOne_el) {
                return anotherOne_el?.course_name == array_el?.label;
              }).length == 0
            );
          });
          return res;
          break;
      }
      return arr;
    } else {
      return arr;
    }
  };

  return (
    <CreatableSelect
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={(e) => handleChange(e)}
      onCreateOption={(e) => handleCreate(e)}
      formatCreateLabel={formatCreate}
      options={getFilteredoptions(options)}
      controlShouldRenderValue={
        props.attribute_name == "School" ||
        props.attribute_name == "Skill" ||
        props.attribute_name == "Subject" ||
        props.attribute_name == "online_courses" ||
        props.attribute_name == "Industry" ||
        props.attribute_name == "Career" ||
        props.attribute_name == "Designation"
          ? true
          : false
      }
      value={selectedOption ? (selectedOption.value ? selectedOption : "A") : "B"}
      onFocus={()=>{
        setFieldFocused(true);
        props?.handleOnFocus()
      }}
      onBlur={()=>{
        setFieldFocused(false);
        props?.handleOnBlur()
      }}
      placeholder={fieldFocused ? (props.onFocusPlaceholder ?? 'Type here...'):props.placeholder ? props.placeholder : null}
      ref={props.ref}
      isValidNewOption={() => props.attribute_name=="Interest" ?false:true}
    />
  );
}
