const initialValue = {
    getTdpReport:null,
};

export default function getTdpReportReducer(state = initialValue, action) {
    console.log(action)
    switch (action.type) {
        case "TDP_REPORT":
            return Object.assign({}, state, {
                getTdpReport: action.get_tdp_report,
            });
        default:
            return state;
    }
};