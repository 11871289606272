export const GQ_GET_TDP_SUMMARY = `query GetTDPSummary($type: String, $dimensionExamUserId: String) {
  getTDPSummary(type: $type, dimension_exam_user_id: $dimensionExamUserId) {
    id
    dimension_name
    dimension_description
    dimension_short_description
    dimension_name_hindi
    dimension_short_description_hindi
    dimension_tokens
    dimension_status
    is_parent_exam_completed
    is_exam_inprogress
    is_exam_completed
    general_instructions
  }
}`;


export const GQ_TDP_EXAM_DETAILS = `query GetTDPExamDetails($type: String, $dimensionId: String, $dimensionExamUserId: String, $childId : String) {
  getTDPExamDetails(type: $type, dimension_id: $dimensionId, dimension_exam_user_id: $dimensionExamUserId, childId:$childId) {
    id
    parent
    child
    dimension_name
    dimension_description
    dimension_tokens
    exam_questions {
      question_id
      question_text
      question_text_hindi
      question_image_url
      question_type
      question_options {
        option_text
        option_text_hindi
        option_image_url
        score
      }
      question_answered
    }
    exam_status
    exam_correct_option
    exam_next_question_id
    model_Id
    popup
  }
}`;
export const GQ_PARENT_POPUPSTATUS = `query GetParentPopUpStatusTDP($id: String) {
  getParentPopUpStatusTDP(id: $id) 
}`;



export const GQ_SUBMIT_TDP_EXAM = `mutation SubmitTDPExam($input: TdpExamInput) {
  submitTDPExam(input: $input) {
    id
    parent
    child
    dimension_id
    dimension_name
    dimension_description
    dimension_tokens
    exam_questions {
      question_id
      question_text
      question_text_hindi
      question_image_url
      question_type
      question_options {
        option_text
        option_text_hindi
        option_image_url
        score
      }
      question_answered
    }
    exam_status
    exam_next_question_id
    exam_correct_option
    model_Id
    popup
  }
}`;


export const GQ_EXIT_TDP_TEST = `mutation CreateTDPExitMessage($input: TdpExitInput) {
  createTDPExitMessage(input: $input) {
    id
    exam_exit_status
  }
}`;

export const GQ_GET_TDP_PROGRESS = `query GetTDPProgress($dimensionExamUserId: String) {
  getTDPProgress(dimension_exam_user_id: $dimensionExamUserId) {
    dimension_id
    dimension_name
    dimension_name_hindi
    percentage
  }
}`;
export const GQ_GET_TDP_REPORT = `query GetTDPReport($childrenId: String) {
  getTDPReport(children_id: $childrenId) {
    is_exam_completed
    Report {
      dimension_id
      dimension_name
      max_score
      allotted_score
      cognitive_scoring
      individual_scoring
      allotted_score_message
      allotted_score_label
       celebrity {
        celebrity_role
        celebrity_name
        celebrity_hover_info
        celebrity_info
        celebrity_picture_url
      }
    }
  }
}`;

export const GET_MULTI_DIMENSION_COGNITIVE_REPORT = `query GetMultiDimensionCognitiveReport($childId: String) {
  getMultiDimensionCognitiveReport(childId: $childId) {
    celebrity {
      celebrity_info
      celebrity_hover_info
      celebrity_name
      celebrity_role
      celebrity_picture_url
    }
    dimension_id
    dimension_name
    celebrity_revealed
    cognitive_profile_description
    learning_dimension_description
    related_courses {
      percentage
      course_type
    }
    traits
  }
}`;

export const GQ_ASSIGN_DIMENSION_CELEBRITY = `mutation AssignDimensionCelebrity($dimensionId: String, $childrenId: String) {
  assignDimensionCelebrity(dimension_id: $dimensionId, children_id: $childrenId) {
    dimension_id
  }
}`;

export const GQ_GENERATE_PDF_REPORT_ID = `query Query($generatePdfReportId: String) {
  generatePDFReport(id: $generatePdfReportId)
}
`;


export const GQ_GET_DIMENSION_COGNITIVE_REPORT = `query GetDimensionCognitiveReport($dimensionName: String, $childId: String) {
  getDimensionCognitiveReport(dimension_name: $dimensionName, childId: $childId) {

    learning_dimension_description

    cognitive_profile_description
    traits
    related_courses {
      course_type
      percentage
    }
    celebrity {
      celebrity_name
      celebrity_role
      celebrity_hover_info
      celebrity_info
      celebrity_picture_url
    }
    celebrity_revealed
  }
}`;

export const GQ_CREATE_TDP_REPORT = `mutation CreateTDPReport($createTdpReportId: ID) {
  createTDPReport(id: $createTdpReportId)
}`;

export const GQ_UPDATE_GUIDELINE = `mutation Mutation {
  taGuidelineChecked
}`;


export const GQ_HANDLE_REQUEST_ACCESS = `mutation StudentReportAccessRequest($child: String) {
  studentReportAccessRequest(child: $child)
}`;