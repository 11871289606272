import React, {useState} from 'react';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {MessageQuestion, Like1, Messages1, Danger} from 'iconsax-react';
import Link from "next/link";


export default function PopoverTool(props){
    const {children,text,handleSelect}=props;

    const handleSelecteItem=(e)=>{
        document.body.click();
        handleSelect(e.target.innerText);
    }
    return(
        <>
        <OverlayTrigger
            trigger="click"
            placement={"left"}
            key={'left'}
            tex={"text"}
            rootClose={true}
            overlay={
                <Popover id={`popover-positioned`}>
                    <Popover.Header as="h3">
                        <span className="d-block">
                            Need help?
                        </span>
                        <Link href="/faq" >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() =>document.body.click()}>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.49 0.666138H5.50671C2.47337 0.666138 0.665039 2.47447 0.665039 5.5078V12.4828C0.665039 15.5245 2.47337 17.3328 5.50671 17.3328H12.4817C15.515 17.3328 17.3234 15.5245 17.3234 12.4911V5.5078C17.3317 2.47447 15.5234 0.666138 12.49 0.666138ZM6.8213 7.1928C6.8213 5.9872 7.79336 5.01503 8.99902 5.01503C10.2047 5.01503 11.1767 5.9872 11.1767 7.1928C11.1767 8.14425 10.5512 8.64638 10.1418 8.92614C9.96614 9.04753 9.85291 9.143 9.77957 9.24137C9.71863 9.3231 9.67675 9.41762 9.67675 9.56935V9.75908C9.67675 10.1334 9.37332 10.4368 8.99902 10.4368C8.62473 10.4368 8.3213 10.1334 8.3213 9.75908V9.56935C8.3213 8.59626 8.96168 8.09383 9.37302 7.80982L9.37588 7.80784L9.37589 7.80785C9.54322 7.69357 9.65269 7.60029 9.72295 7.50547C9.78177 7.42607 9.8213 7.33533 9.8213 7.1928C9.8213 6.73573 9.45603 6.37047 8.99902 6.37047C8.54201 6.37047 8.17675 6.73573 8.17675 7.1928C8.17675 7.5671 7.87332 7.87053 7.49902 7.87053C7.12473 7.87053 6.8213 7.5671 6.8213 7.1928ZM8.99413 11.2411C8.61984 11.2411 8.31641 11.5445 8.31641 11.9188C8.31641 12.2931 8.61984 12.5965 8.99413 12.5965H9.00227C9.37656 12.5965 9.67999 12.2931 9.67999 11.9188C9.67999 11.5445 9.37656 11.2411 9.00227 11.2411H8.99413Z" fill="#2A2A84"/>
                            </svg>
                        </Link>
                    </Popover.Header>
                    <Popover.Body>
                        <div>
                            <h5 onClick={(e)=>handleSelecteItem(e)} >  <Like1 variant="Bulk" />Suggest an improvement</h5>
                            <h5 onClick={(e)=>handleSelecteItem(e)} >  <Danger variant="Bulk"/>Report a problem </h5>
                            <h5  onClick={(e)=>handleSelecteItem(e)}>  <Messages1 variant="Bulk" />Give feedback</h5>
                            <h5 onClick={(e)=>handleSelecteItem(e)} >  <MessageQuestion variant="Bulk" />Ask a question</h5>
                        </div>
                    </Popover.Body>
                    <h6  onClick={() =>document.body.click()}>Cancel</h6>

                </Popover>
            }
        >{children}
        </OverlayTrigger>
      </>
    )}


