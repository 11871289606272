import React, { useEffect, useRef, useState } from "react";
import { images, starMessage } from "../../../../constant";
import ToolTipModal from "../../toot_tip/ToolTip";
import { GQ_ASSIGN_CHILD_ATTIBUTES } from "../../../@mock-api/graphql/child";
import {
  InfoCircle,
  AddCircle,
  Refresh,
  Star1,
  Trash,
  CloseCircle,
  ArrowUp2,
} from "iconsax-react";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import Rating from "react-rating";
import APIService from "../../../@mock-api/api.service";
import { ERROR_MESSAGE } from "../../../helper";
import { useSnackbar } from "notistack";

import Spinner from "react-bootstrap/Spinner";

const apiService = new APIService();

const interestsuggestion = [
  { value: "music", label: "Music" },
  { value: "science", label: "Science" },
  { value: "indoor games", label: "Indoor Games" },
  { value: "sports", label: "Sports" },
  { value: "nature", label: "Nature" },
];

export default function InterestEdit(props) {
  const {
    appreciatingLiners,
    eduLiner,
    handleEducateLiner,
    handleAppLiners,
    handleOutOffFocus,
    fieldCoins,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const subjectObj = {
    subject: null,
    score: 0,
  };
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedInterests, setSelectedInterest] = useState([]);
  const [selectedHobbies, setSelectedHobby] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [interestOptions, setInterestOptions] = useState([]);
  const [hobbyOptions, setHobbyOptions] = useState([]);
  const [selectedStar, setSelectedStar] = useState(0);
  const [allSkills, setAllSkills] = useState([]);
  const [allInterest, setAllInterest] = useState([]);
  const [countSib, SetCountSib] = useState(null);
  const [allHobby, setAllHobby] = useState([]);
  const [hobbyText, setHobbyText] = useState(null);
  const [interestText, setInterestText] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [openMenuOnFocusInterests, setOpenMenuOnFocusInterests] =
    useState(false);
  const [openMenuOnFocusHobbies, setOpenMenuOnFocusHobbies] = useState(false);
  const [childDetails, setchildDetails] = useState("");
  const refInterest = useRef();

  const section_name = "co_curricular_information";

  useEffect(() => {
    handleOutOffFocus();
    getChildGradeAttribute(1, "Skill");
    getChildGradeAttribute(1, "Interest");
    getChildGradeAttribute(1, "Hobby");
    const child = localStorage.getItem("child");
    setchildDetails(JSON.parse(child))
  }, []);

  useEffect(() => {
    if (skillOptions.length) {
      const childAssignedAttributes = props.childAssignedAttributes;

      const subjects = [];
      const interests = [];
      const hobbies = [];
      if (childAssignedAttributes.length) {
        childAssignedAttributes.map((item) => {
          if (item.attribute_name === "Skill") {
            if (item.attributes.length) {
              item.attributes.map((val) =>
                subjects.push({
                  subject: val.title,
                  score: val.score,
                })
              );
              setSelectedSubjects(subjects);
            }
          }
          if (item.attribute_name === "Interest") {
            if (item.attributes.length) {
              item.attributes.map((val) => interests.push(val.title));
              setSelectedInterest(interests);
            }
          }
          if (item.attribute_name === "Hobby") {
            if (item.attributes.length) {
              item.attributes.map((val) => hobbies.push(val.title));
              setSelectedHobby(hobbies);
            }
          }
        });
      }
    }
  }, [skillOptions]);

  const getChildGradeAttribute = (standard, name) => {
    apiService
      .getChildGradeSubjects({ standard, name })
      .then((res) => {
        if (name === "Skill") {
          setAllSkills(res.data.data.getAttributesByStandard);
        }
        if (name === "Hobby") {
          setAllHobby(res.data.data.getAttributesByStandard);
        }
        if (name === "Interest") {
          setAllInterest(res.data.data.getAttributesByStandard);
        }
        processData(res.data.data.getAttributesByStandard, name);
      })
      .catch((error) => {
        if (error?.data?.errors) {
          console.error(error?.data?.errors[0].message);
        }
      });
  };

  const processData = (data, name) => {
    const options = [];
    if (data.length) {
      data.map((item) => {
        options.push({
          label: item.title,
          value: item.title,
        });
      });
    }
    if (name === "Skill") {
      setSkillOptions(options);
    }
    if (name === "Hobby") {
      setHobbyOptions(options);
    }
    if (name === "Interest") {
      setInterestOptions(options);
    }
  };

  const handleSelection = (index, item) => {
    const tempSubject = [...selectedSubjects];
    tempSubject[index].score = item;
    setSelectedSubjects(tempSubject);
  };

  const handleHover = (item) => {
    if (item) {
      setSelectedStar(item);
    } else {
      setSelectedStar(0);
    }
  };

  const submit = () => {
    if (selectedInterests.length < 3) {
      setErrorMessage("Please select atleast three Interest.");
      return false;
    }

    let interests = [];
    if (selectedInterests.length) {
      interests = selectedInterests.map((item) => {
        return { name: item, score: 0 };
      });
    }

    const interestInput = {
      attribute: {
        attributes: interests,
        child: childDetails.id,
        attribute_name: "Interest",
      },
    };

    props.updateChildProfile(interestInput, GQ_ASSIGN_CHILD_ATTIBUTES);
  };

  const deleteInterestOrHobby = (index, type) => {
    if (type === "Interest") {
      const tempInterest = [...selectedInterests];
      tempInterest.splice(index, 1);
      setInterestText(null);
      setSelectedInterest(tempInterest);
    }
    if (type === "Hobby") {
      const tempHobbies = [...selectedHobbies];
      tempHobbies.splice(index, 1);
      setHobbyText(null);
      setSelectedHobby(tempHobbies);
    }
  };

  const resetSubject = (index) => {
    const tempSubject = [...selectedSubjects];
    tempSubject[index].score = 0;
    setSelectedSubjects(tempSubject);
  };

  const Setinterest = (value) => {};

  const interestHobbySelection = (value, type) => {
    setErrorMessage("");
    if (type === "Interest") {
      if (!selectedInterests.includes(value.value)) {
        if (selectedInterests.length == 2) {
          let sectionValues = {
            section_name: section_name,
            ...{
              hobbies: selectedHobbies[0] ? selectedHobbies[0] : "",
              interests: "interests",
              skills: selectedSubjects[0] ? selectedSubjects[0] : "",
            },
          };
        }
        setSelectedInterest((oldItems) => [...oldItems, value.value]);
        setInterestText(value.value);
      }
    }
  };

  const renderIcon = (data, type, item) => {
    let attr = [];
    if (type === "Skill") {
      attr = data.filter((attribute) => attribute.title === item.subject);
    }
    if (type === "Interest" || type === "Hobby") {
      attr = data.filter((attribute) => attribute.title === item);
    }
    if (type === "Skill") {
      return attr.length
        ? attr[0].icon
        : process.env.NEXT_PUBLIC_DEFAULT_SEEDER_ICON_BLUE;
    }
    return attr.length
      ? attr[0].icon
      : process.env.NEXT_PUBLIC_DEFAULT_SEEDER_ICON;
  };
  const handleOnFocus = (field) => {
    setErrorMessage("");
  };

  const handleOnFocusSelect = (field) => {
    if (field == "interests") {
      setOpenMenuOnFocusInterests(true);
    }
    if (field == "hobbies") {
      setOpenMenuOnFocusHobbies(true);
    }
  };

  const handleOnBlur = () => {
    handleOutOffFocus();
    setOpenMenuOnFocusInterests(false);
    setOpenMenuOnFocusHobbies(false);
  };

  const generateClass = (total, index) => {
    let className = "tag-pink";
    if ((total - index) % 4 === 0) {
      className = "tag-yellow";
    }
    if ((total - index) % 4 === 1) {
      className = "tag-darkBlue";
    }
    if ((total - index) % 4 === 2) {
      className = "tag-blue";
    }
    if ((total - index) % 4 === 3) {
      className = "tag-pink";
    }
    return `col-auto icon icon-slc ${className} ps-0`;
  };

  return (
    <>
      <div className="col-12 form-main">
      {props.showquestiontext && (
                <div className="Quetion_no_div Q_intrest Quetion_no_div1">
                <div className="Quetion_no">
                  <p>Question {props.showsecondchildcurrentindex}/{props.showsecondchildlength}</p>
                </div>
              </div>
              )}
        <div className="row">
          <div className="col-12 col-md-5 col-sm-12 d-none d-sm-block d-sm-none d-md-block">
            <img src={images.Interests.default.src} className="interestimage" />
          </div>
          <div className="col-12 col-md-5 col-sm-12 d-block d-sm-block d-md-none">
            <img src={images.Interests2.default.src} className="interestimage" />
          </div>
          <div className="col-12 col-md-7 col-sm-12 ">
            <h3 class="modal-title modalt1 mb-2">
              Tell us what Interests {childDetails.first_name} has! 
            </h3>
            <div className="col-12 add-card" id={"add-card-cocurricular-sub"}>
              <div
                className="col-12  position-relative"
                id={`interest-section-main`}
              >
                <div className="row" id={`interest-section-row-main`}>
                  <div
                    className="col-md-12 form-group cus-select drop-slc mb-md-0"
                    id={`interest-sub-section-main`}
                  >
                    <div>
                      <CustomAutoComplete
                        handleOnFocus={() => {
                          if (selectedInterests.length == 0) {
                            handleOnFocus("interests");
                          }
                          handleOnFocusSelect("interests");
                        }}
                        handleOnBlur={() => handleOnBlur()}
                        standard={"1"}
                        attribute_name={"Interest"}
                        type={"multiselect"}
                        options={interestOptions}
                        selectedOption={interestText}
                        selectedSubject={(value) => {
                          setOpenMenuOnFocusInterests(false);
                          interestHobbySelection(value, "Interest");
                        }}
                        resetAfterSelect={true}
                        id={`interest1`}
                        placeholder={"Click here to start adding interest"}
                        onFocusPlaceholder={"Start typing interest"}
                        openMenuOnFocus={openMenuOnFocusInterests}
                        selectedArr={selectedInterests}
                      />
                      <div className="col-12 slc-box">
                        <div className="row ">
                          {!!selectedInterests.length
                            ? selectedInterests.map((item, index) => (
                                <div
                                  className={generateClass(
                                    selectedInterests.length,
                                    index
                                  )}
                                  id={`interest-sub-main-${index}`}
                                  key={`co-curricular-interest-${index}`}
                                >
                                  <a
                                    type="button"
                                    className="icon"
                                    onClick={() =>
                                      deleteInterestOrHobby(index, "Interest")
                                    }
                                  >
                                    <CloseCircle
                                      id={`hobbies-section-close`}
                                      variant="Bulk"
                                    />
                                  </a>
                                  <img
                                    className="img-fluid"
                                    id={`hobby-icon-${index}`}
                                    src={renderIcon(
                                      allInterest,
                                      "Interest",
                                      item
                                    )}
                                  />
                                  <svg
                                    className="bg-icon"
                                    width="44"
                                    height="50"
                                    viewBox="0 0 44 50"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g filter="url(#filter0_d_906_14167)">
                                      <path
                                        d="M11.4638 40.3956C13.1047 38.6358 15.6062 38.7758 17.047 40.6955L19.0682 43.3952C20.6892 45.5349 23.3107 45.5349 24.9316 43.3952L26.9528 40.6955C28.3937 38.7758 30.8951 38.6358 32.5361 40.3956C36.0982 44.1951 38.9998 42.9353 38.9998 37.6159V15.0787C39.0199 7.01975 37.1388 5 29.5743 5H14.4455C6.8811 5 5 7.01975 5 15.0787V37.5959C5 42.9353 7.92171 44.1751 11.4638 40.3956Z"
                                        fill="#ED037C"
                                      />
                                    </g>
                                    <defs>
                                      <filter
                                        id="filter0_d_906_14167"
                                        x="0"
                                        y="0"
                                        width="44"
                                        height="50"
                                        filterUnits="userSpaceOnUse"
                                        color-interpolation-filters="sRGB"
                                      >
                                        <feFlood
                                          flood-opacity="0"
                                          result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                          in="SourceAlpha"
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                          result="hardAlpha"
                                        />
                                        <feOffset />
                                        <feGaussianBlur stdDeviation="2.5" />
                                        <feComposite
                                          in2="hardAlpha"
                                          operator="out"
                                        />
                                        <feColorMatrix
                                          type="matrix"
                                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in2="BackgroundImageFix"
                                          result="effect1_dropShadow_906_14167"
                                        />
                                        <feBlend
                                          mode="normal"
                                          in="SourceGraphic"
                                          in2="effect1_dropShadow_906_14167"
                                          result="shape"
                                        />
                                      </filter>
                                    </defs>
                                  </svg>
                                  <p className="name">
                                    <ArrowUp2
                                      id={`hobbies-arrow-main`}
                                      variant="Bold"
                                    />
                                    {item}
                                  </p>
                                </div>
                              ))
                            : ""}
                          {/*{ !!selectedInterests.length ? <span className="fw-regular text-grey text-center mt-3 " onClick={()=>setOpenMenuOnFocusInterests(true)}>Click here to add</span> : ""}*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="checkbox-group grade-slc  customgrade-slc w-100 mt-2 mb-0">
              {interestsuggestion.map((item, index) => (
                <div className="checkbox">
                  <label
                    className="checkbox-wrapper"
                    id={`sb-0_label_${index + 1}`}
                  >
                    <input
                      className="checkbox-input"
                      id={`sb-0-${index + 1}`}
                      name="child_sibling_count"
                      type="radio"
                      value={index}
                      checked={countSib == index}
                      onChange={(event) => {
                        //    SetCountSib(item);
                        interestHobbySelection(item, "Interest");
                        SetCountSib(index);
                      }}
                    />
                    <span className="checkbox-tile">
                      <span className="checkbox-label">{item.label}</span>
                    </span>
                  </label>
                </div>
              ))}
            </div>

            <div
              className={`modal-footer coin-anim skillmodalfooter modfooter  ${
                eduLiner && "anim-1"
              }    ${appreciatingLiners && "anim-1 anim-2 anim-3"} ${
                errorMessage && "anim-1 anim-2 anim-3"
              } `}
            >
              <center>
                <button
                  type="button"
                  className={"btn btn-lg btn-black"}
                  onClick={submit}
                  disabled={props.showloader ? true : false}
                  >
                    {props.showloader && (
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    )}{" "}
                  Save
                </button>
              </center>

              <div className="text skillstexterror">
                <div className=" align-items-center col-auto">
                  {!errorMessage && (
                    <>
                      <div className="points">
                        <img
                          className="img-fluid coin-float"
                          src={images.coin.default.src}
                        />
                        <img
                          className="img-fluid"
                          src={images.coin.default.src}
                        />
                      </div>
                      <h6 className="mb-0">{eduLiner}</h6>
                      <h6 className="mb-0 text-blue">{appreciatingLiners}</h6>
                    </>
                  )}
                  <h6 className="mb-0 text-pink">{errorMessage}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
