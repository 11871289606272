export const GQ_GET_USERS = `query Query($searchParams: userParams) {
  users(searchParams: $searchParams) {
    totalItems
    users {
      last_name
      first_name
      email
      phone
      id
      childCount
      business_name
      createdAt
    }
    currentPage
    totalItems
  }
}`;

export const GQ_GET_ALL_CHILDREN = `query Query($params: ChildParams) {
  getAllChildren(params: $params) {
    totalItems
    children {
      id
      first_name
      last_name
      parent{
        id
        first_name
        last_name
        tokenNumber
      }
      city
      date_of_birth
      gender
      school_standard
      grade12_child
      grade3_5_child
      profile_score
      test_score
      createdAt
      image
    }
  }
}`;

export const GQ_GET_SINGLE_USER_DETAIL = `query User($userId: String) {
  user(id: $userId) {
    id
    first_name
    childCount
    business_name
    role
    phone
    email
    last_name
    country_code
    createdAt
     residence_city
    residence_address
    ta_guidelines_checked
    residence_contact
    ta_guidelines_checked
    paid_bundle_quantity
    social_links {
      facebook
      instagram
      twitter
      linkedin
      whatsapp
     youtube
      other
    }
  }
}`;

export const GQ_GET_ALL_COURSES = `query Query($params: CourseFilter) {
  searchCourses(params: $params) {
    totalItems
    courses {
      id
      name
      headline
      image
      fees
      contact_email
      contact_first_name
      contact_last_name
      duration
    }
    totalItems
  }
}`;


export const GQ_GET_CHILD_BY_PARENT = `query GetChildren($getChildrenId: String) {
  getChildren(id: $getChildrenId) {
    id
    first_name
    last_name
    image
    teacher_assigned
    child_stage_cleared
    school_standard
    grade12_child
    grade3_5_child
  }
}`;

export const GQ_GET_PROVIDER_ENQUIRIES = `query Query($params: providerParams) {
    providerInquiries(params: $params) {
        totalItems
        providers {
            id
            company_name
            email
            website
            logo
            first_name
            last_name
            phone {
              number
              code
              name
           }
            status
            createdAt
            phoneVerifiedAt
            emailVerifiedAt
        }
        currentPage
        totalItems
    }
}`;

export const GQ_UPDATE_PROVIDER_STATUS = `mutation Mutation($status: ProviderStatusInput) {
  changeProviderStatus(status: $status) {
    id
  }
}`;

export const GQ_FILTER_FAQS_INQUIRY = `mutation FaqInquiry($subject: String, $message: String) {
  faqInquiry(subject: $subject, message: $message) {
    id
    subject
    message
  }
}`;

export const GQ_GET_PARENT_EARLY_ACCESS = `query EarlyAccessUsers($searchParams: earlyAccessParams) {
  earlyAccessUsers(searchParams: $searchParams) {
    totalPages
    currentPage
    totalItems
    users {
      id
      first_name
      last_name
      email
      phone
      business_name
      role
      tokenNumber
      createdAt
      childCount
      phoneVerifiedAt
      emailVerifiedAt
      status
    }
  }
}`;

export const GQ_GENERATE_SINGLE_LOGIN = `mutation GenerateParentLogin($tokenNumber: Int) {
  GenerateParentLogin(tokenNumber: $tokenNumber) {
    tokenNumber
  }
}`;

export const GQ_GENERATE_BULK_LOGIN = `mutation GenerateParentLoginBulk($searchParams: bulkLoginParams) {
  generateParentLoginBulk(searchParams: $searchParams) {
    id
  }
}`;

export const GQ_SEND_EMAIL_PHONE_VERIFICATION = `mutation SendEmailPhoneVerification($sendEmailPhoneVerificationId: ID, $type: String) {
  sendEmailPhoneVerification(id: $sendEmailPhoneVerificationId, type: $type) {
    id
  }
}`;

export const GQ_FETCH_GROUP_ATTRIBUTES = `query FetchGroupedAttributes {
  fetchGroupedAttributes {
    attribute_name
    attribute_type
    sortOrder
  }
}`;

export const GQ_CREATE_CHILD_ATTRIBUTE = `mutation CreateChildAttribute($attribute: ChildAttributeInput) {
  createChildAttribute(attribute: $attribute) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_ATTRIBUTE = `mutation UpdateAttributeSortOrder($attributeName: String, $sortOrder: Float) {
  updateAttributeSortOrder(attribute_name: $attributeName, sortOrder: $sortOrder) {
    id
  }
}`;

export const GQ_SINGLE_ATTRIBUTE_DETAILS = `query GetAttributesByType($filter: childFilter) {
  getAttributesByType(filter: $filter) {
    id
    title
    standard
    type
    attribute_name
    sortOrder
    isApproved
    createdAt
  }
}`;

export const GQ_DELETE_PROVIDER_CATEGORY = `mutation DeleteProviderCategory($deleteProviderCategoryId: ID) {
  deleteProviderCategory(id: $deleteProviderCategoryId) {
    id
  }
}`;

export const GQ_DELETE_CHILD_ATTRIBUTE = `mutation DeleteChildAttribute($name: String) {
  deleteChildAttribute(name: $name) {
    id
  }
}`;

export const GQ_DELETE_CHILD_ATTRIBUTE_BY_ID = `mutation DeleteChildAttributeById($deleteChildAttributeByIdId: ID) {
  deleteChildAttributeById(id: $deleteChildAttributeByIdId) {
    id
  }
}`;

export const GQ_APPROVE_CHILD_ATTRIBUTE_BY_ID = `mutation ApproveChildAttributeById($approveChildAttributeByIdId: ID) {
  approveChildAttributeById(id: $approveChildAttributeByIdId) {
    id
  }
}`;

export const GQ_APPROVE_PROVIDER_CATEGORY = `mutation ApproveProviderCategory($approveProviderCategoryId: ID) {
  approveProviderCategory(id: $approveProviderCategoryId) {
    id
  }
}`;

export const GQ_DELETE_ONLINE_COURSE = `mutation DeleteOnlineCourse($deleteOnlineCourseId: ID) {
  deleteOnlineCourse(id: $deleteOnlineCourseId) {
    id
  }
}`;

export const GQ_DELETE_PROGRAM_TYPE = `mutation DeleteProgramListing($deleteProgramListingId: ID) {
  deleteProgramListing(id: $deleteProgramListingId) {
    name
  }
}`;

export const GQ_APPROVE_PROGRAM_TYPE = `mutation ApproveProgramListing($approveProgramListingId: ID) {
  approveProgramListing(id: $approveProgramListingId) {
    name
  }
}`;

export const GQ_GET_PROVIDER_CATEGORIES_ADMIN = `query GetAllProviderCategoryAdmin {
  getAllProviderCategoryAdmin {
    category
    id
    isApproved
    createdAt
  }
}`;

export const GQ_GET_FAQ_CATEGORY = `query GetSiteFaqCategories {
  getSiteFaqCategories {
    id
    name
    createdAt
  }
}`;

export const GQ_ADD_FAQ_CATEGORY = `mutation AddSiteFaqCategory($name: String) {
  addSiteFaqCategory(name: $name) {
    id
  }
}`;

export const GQ_EDIT_FAQ_CATEGORY = `mutation EditSiteFaqCategory($editSiteFaqCategoryId: ID, $name: String) {
  editSiteFaqCategory(id: $editSiteFaqCategoryId, name: $name) {
    id
  }
}`;

export const GQ_DELETE_FAQ_CATEGORY = `mutation DeleteSiteFaqCategory($deleteSiteFaqCategoryId: ID) {
  deleteSiteFaqCategory(id: $deleteSiteFaqCategoryId) {
    id
  }
}`;


export const GQ_ADD_SITE_FAQ = `mutation AddSiteFaq($faq: SiteFaqInput) {
  addSiteFaq(faq: $faq) {
   id 
  }
}`;

export const GQ_EDIT_SITE_FAQ = `mutation EditSiteFaq($editSiteFaqId: ID, $faq: SiteFaqInput) {
  editSiteFaq(id: $editSiteFaqId, faq: $faq) {
    id
  }
}`;

export const GQ_DELETE_SITE_FAQ = `mutation DeleteSiteFaq($deleteSiteFaqId: ID) {
  deleteSiteFaq(id: $deleteSiteFaqId) {
    id
  }
}`;

export const GQ_GET_ALL_SITE_FAQ = `query GetAllSiteFaqs($keywords: String, $categories: [String], $page: Int) {
  getAllSiteFaqs(keywords: $keywords, categories: $categories, page: $page) {
    faqs {
      id
      title
      descriptions
      categories
      sort_order
      createdAt
    }
    total
  }
}`;
export const GQ_GET_GROUPED_FAQS= `query GetGroupedFaqs($keywords: String) {
  getGroupedFaqs(keywords: $keywords) {
    category
    icon
    faqs {
      title
      id
      descriptions
      categories
      sort_order
    }
  }
}`;

export const GQ_ADMIN_SEND_NOTIFICATION = `mutation Mutation($inputData: NotificationInput) {
  sendNotification(inputData: $inputData)
}`;

export const GQ_ADD_SITE_FAQ_BULK = `mutation Mutation($file: Upload) {
  addSiteFaqBulk(file: $file) {
    id
  }
}`;

export const GQ_SEND_MARKETTING_EMAIL = `mutation Mutation($inputData: EmailInput) {
  sendMarketingEmail(inputData: $inputData)
}`;

export const GQ_EDIT_TEMPLATE_EMAIL = `mutation Mutation($editEmailTemplateId: ID, $inputData: EmailTemplateInput) {
  editEmailTemplate(id: $editEmailTemplateId, inputData: $inputData) {
    subject
    id
    title
    content
    attachments
  }
}`;

export const GQ_ADD_TEMPLATE_EMAIL = `mutation AddEmailTemplate($inputData: EmailTemplateInput) {
  addEmailTemplate(inputData: $inputData) {
    id
    title
    subject
    content
    attachments
  }
}`;

export const GQ_DELETE_TEMPLATE_EMAIL = `mutation DeleteEmailTemplate($deleteEmailTemplateId: ID) {
  deleteEmailTemplate(id: $deleteEmailTemplateId) {
    id
  }
}`;


export const GQ_GET_ALL_EMAIL_TEMPLATES = `query GetAllEmailTemplates {
  getAllEmailTemplates {
    id
    title
    subject
    content
    attachments
    createdAt
  }
}`;

export const GQ_GET_ALL_MARKETING_EMAILS = `query GetAllMarketingEmails {
  getAllMarketingEmails {
    id
    content
    subject
    users {
      id
      first_name
      last_name
      tokenNumber
    }
    attachments
  }
}`;


export const GQ_ADMIN_SEND_EMAIL = `mutation SendMarketingEmail($inputData: EmailInput) {
  sendMarketingEmail(inputData: $inputData)
}`;

export const GQ_GET_USER_GROUP = `query GetUserGroup($params: userGroupParams) {
  getUserGroup(params: $params) {
    totalPages
    usergroups {
      id
      group_name
      users {
        id
      }
      createdAt
    }
  }
}`;

export const GQ_DELETE_USER_FROM_GROUP = `mutation Mutation($inputData: deleteGroupInput) {
  deleteuserFromGroup(inputData: $inputData) {
    id
  }
}`;

export const GQ_ADMIN_ADD_GROUP = `mutation AddUserGroup($inputData: userGroupInput) {
  addUserGroup(inputData: $inputData) {
    id
    group_name
  }
}`;

export const GQ_GET_GROUP_BY_ID = `query GetGroup($inputData: GetGroup) {
  getGroup(inputData: $inputData) {
    id
    group_name
    type
    users {
      id
      parent
      email
      first_name
      last_name
        token
      phone
    }
    createdAt
  }
}`;

export const  GQ_UPDATE_GROUP_DATA = `mutation AddNewUserToGroup($inputData: addNewUserToGroupInput) {
  addNewUserToGroup(inputData: $inputData) {
    id
  }
}`;

export const GQ_DELETE_GROUP = `mutation Mutation($deleteGroupId: String) {
  deleteGroup(id: $deleteGroupId) {
    id
  }
}`;

export const GQ_GET_EMAIL_LOGS = `query GetAllMarketingEmails {
  getAllMarketingEmails {
    id
    createdAt
    attachments
    sent
    users {
      id
    }
  }
}`;

export const GQ_GET_GROUP_NOTIFICATION_EMAIl_LOGS = `query Query($groupNotificationsEmailsId: ID) {
  groupNotificationsEmails(id: $groupNotificationsEmailsId) {
    notifications {
      title
      message
      link
      users {
        last_name
        tokenNumber
        first_name
      }
      createdAt
    }
    emails {
      subject
      content
      users {
        first_name
        last_name
        tokenNumber
      }
      sent
      createdAt
    }
  }
}`;
