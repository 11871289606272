export const GQ_ADD_CHILD = `mutation Mutation($child: ChildCreateInput) {
  createChild(child: $child) {
    id
  }
}`;

export const GQ_GET_CHILD = `query ChildrenByParent {
  childrenByParent {
    id
    first_name
    last_name
    date_of_birth
    school_standard
    grade12_child
    grade3_5_child
    image
    gender
  }
}`;

export const GQ_GET_SINGLE_CHILD_DETAIL = `query Child($childId: String) {
  child(id: $childId) {
    id
    first_name
    last_name
    image
    school_standard
    date_of_birth
    place_of_birth
    time_of_birth
    about
    grade12_child
    grade3_5_child
    banner
    blood_group
    gender
    city
    child_stage_cleared
    show_counselling_session
    show_course_session
    sleep_time{
        start
        end
    }
    wake_time{
        start
        end
    }
    social_media_time {
      start
      end
    }
    online_course_time {
      start
      end
    }
    subjects {
      subjectId
      subjectName
      score
    }
    habits {
      habitName
      habitId
    }
    skills {
      score
      skillName
      skillId
    }
    interests {
      interestId
      interestName
    }
    hobbies {
      hobbyName
      hobbyId
    }
    child_tuition_subjects {
      tution_type
      subject
    }
    online_courses {
      course_name
      course_type
    }
    favourite_food {
      foodName
      foodId
    }
    favourite_movie {
      movieName
      movieId
    }
    favourite_music {
      musicName
      musicId
    }
    favourite_sport {
      sportId
      sportName
    }
   family_information {
      family_type
      live_with_grandparents
      mother_designation
      father_designation
      father_profession
      father_company_name
      father_income
      father_profession_info
      mother_profession_info
      mother_profession
      mother_company_name
      mother_income
      child_sibling_count
      child_sibling_combination
    }
    
    expectations
    concerns
    fears
    coursesChoice
    parent_note
    child_tuition_other_subjects
    child_take_tuition
    school_name
    school_board
    state_board_name
    last_year_percent
    last_year_percent_type
    child_live_away
    start_month_for_hostel
    start_year_for_hostel
    end_month_for_hostel
    end_year_for_hostel
    currently_living
    psychologically_assessed
    learning_disability
    teacher_assigned
    school_assigned
    report_access_request
    assessment_plan
    social_links {
      facebook
      instagram
      twitter
      linkedin
      whatsapp
      youtube
      other
    }
  }
}`;





export const GQ_GET_PARENT_TDP_POPUP = `query getParentPopUpTDP($id: String) {
  getParentPopUpTDP(id: $id) {
    model_Id
  }
}`;

export const GQ_GET_SINGLE_CHILD_DETAIL_WITH_UNIQUE_ID = `query GetChildByUniqueId($getChildByUniqueIdId: String) {
  getChildByUniqueId(id: $getChildByUniqueIdId) {
    id
    first_name
    last_name
    school_standard
    grade12_child
    grade3_5_child
    date_of_birth
    place_of_birth
    time_of_birth
    about
    image
    banner
    blood_group
    gender
    city
    child_stage_cleared
    show_counselling_session
    show_course_session
    sleep_time{
        start
        end
    }
    wake_time{
        start
        end
    }
    social_media_time {
      start
      end
    }
    online_course_time {
      start
      end
    }
    subjects {
      subjectId
      subjectName
      score
    }
    habits {
      habitName
      habitId
    }
    skills {
      score
      skillName
      skillId
    }
    interests {
      interestId
      interestName
    }
    hobbies {
      hobbyName
      hobbyId
    }
    child_tuition_subjects {
      tution_type
      subject
    }
    online_courses {
      course_name
      course_type
    }
    favourite_food {
      foodName
      foodId
    }
    favourite_movie {
      movieName
      movieId
    }
    favourite_music {
      musicName
      musicId
    }
    favourite_sport {
      sportId
      sportName
    }
   family_information {
      family_type
      live_with_grandparents
      mother_designation
      father_designation
      father_profession
      father_company_name
      father_income
      father_profession_info
      mother_profession_info
      mother_profession
      mother_company_name
      mother_income
      child_sibling_count
      child_sibling_combination
    }
    
    expectations
    concerns
    fears
    coursesChoice
    parent_note
    child_tuition_other_subjects
    child_take_tuition
    school_name
    school_board
    state_board_name
    last_year_percent
    last_year_percent_type
    child_live_away
    start_month_for_hostel
    start_year_for_hostel
    end_month_for_hostel
    end_year_for_hostel
    currently_living
    psychologically_assessed
    learning_disability
    social_links {
      facebook
      instagram
      twitter
      linkedin
      whatsapp
      youtube
      other
    }
  }
}`;

export const GQ_GET_TEACHER_DETAIL_WITH_UNIQUE_ID = `query getTeacherByUniqueId( $id: String){
  getTeacherByUniqueId( id: $id){
       id
  role
  full_name
  email
  grades
  grade_sections {
    grade
    section
  }
  phone
  permissions {
    student {
      edit
      view
    }
  }
  schoolId {
    id
    name_of_school
    address
    city
    state
    pin_code
    principal_name
    trustee_or_owner_name
    official_email_address
    official_phone_number
    show_counselling_session
    show_course_session
    createdAt
    updatedAt
  }
  createdBy {
    full_name
    id
    email
  }
  createdAt
  updatedAt
  }
}`

export const GQ_UPDATE_BASIC_INFO = `mutation Mutation($child: ChildBasicUpdate) {
  updateBasicInfo(child: $child) {
    id
  }
}`;


export const GQ_UPDATE_EDUCATIONAL_INFO = `mutation UpdateChildEducation($child: ChildEducationUpdate) {
  updateChildEducation(child: $child) {
    id
   
  }
}
`;

export const GQ_UPLOAD_FILE = `mutation Mutation($file: String) {
      base64Upload(file: $file) {
    location
  }
}`;

export const GQ_IMAGE_UPLOAD = `mutation Mutation($file: Upload) {
  singleUpload(file: $file) {
    location
    mimetype
  }
}`;


export const GQ_GET_SUBJECTS = `query Query($standard: String) {
  subjectsByStandard(standard: $standard) {
    id
    name
  }
}`;

export const GQ_UPDATE_SUBJECTS = `mutation UpdateChildSubjects($child: ChildSubjectUpdate) {
  updateChildSubjects(child: $child) {
    id
  }
}`;

export const GQ_GET_SKILLS = `query Query($standard: String) {
  skillsByStandard(standard: $standard) {
    id
    name
  }
}`;

export const GQ_UPDATE_SKILLS = `mutation Mutation($child: ChildSkillUpdate) {
  updateChildSkills(child: $child) {
    id
  }
}`;

export const GQ_GET_HABITS = `query Query($standard: String) {
  habitsByStandard(standard: $standard) {
    id
    name
  }
}`;


export const GQ_ADD_TO_WISHLIST_COURSE = `mutation AddCourseToWishlist($wishlist: WishlistInput) {
  addCourseToWishlist(wishlist: $wishlist) {
    id
  }
}`;

export const GQ_ADD_lIKE_TO_COURSE = `mutation LikeCourse($course: LikedCourseInput) {
  likeCourse(course: $course) {
    id
  }
}`;

export const GQ_REMOVE_FROM_WISHLIST_COURSE = `mutation RemoveFromWishlist($removeFromWishlistId: String, $child: String) {
  removeFromWishlist(id: $removeFromWishlistId, child: $child)
}`;

export const GQ_UPDATE_HABITS = `mutation Mutation($child: ChildHabitUpdate) {
  updateChildHabits(child: $child) {
    id
  }
}`;

export const GQ_GET_COURSES = `query CoursesFromElasticSearch($searchParams: searchParams, $childId: String) {
  coursesFromElasticSearch(searchParams: $searchParams, childId: $childId) {
    percentage
    rating_count
    overall_rating
    is_liked
    in_wishlist
    provider_name
    name
    banner {
      path
      mimetype
    }
    price
    tags
    course_hours
    course_length
    id
    provider_image
    skills_level
    attributes
    course_levels
    course_type
    course_sessions
    total_liked
    recommendations
    target_talent
    target_career
    target_dimensions
    apple_store_link
    google_store_link
    platform_reference
    website
    user
  }
}`;

export const GQ_GET_WISH_LISTED_COURSES = `query GetWishlistedCourses($childId: String) {
  getWishlistedCourses(childId: $childId) {
    overall_review
    percentage
    overall_rating
    overall_review
    is_liked
    in_wishlist
    provider_name
    name
    banner {
      path
      mimetype
    }
    price
    tags
    course_hours
    course_length
    id
    percentage
    provider_image
    skills_level
    attributes
    course_levels
    course_type
    course_sessions
    total_liked
    recommendations
    target_talent
    target_career
    target_dimensions
  }
}`;
export const GQ_GET_COURSE_DETAILS = `query Course($courseId: ID, $childId: String) {
  course(id: $courseId,, childId: $childId) {
    id
    percentage
    provider_name
    provider_image
    user {
      id
    }
    name
    description
    banner {
      mimetype
      path
    }
    media_files {
      mimetype
      path
    }
    recommendations
      overall_rating
    overall_review
    rating_count
    overall_view_count
    overall_demo_booked
    website_link
    website
    platform_reference
    google_store_link
    apple_store_link
    short_description
    communication_mode
    course_urls
    grade_of_student
    age_of_student
    school_board
    state_board_name
    potential_of_child
    skills_level
    attributes
    parental_supervision
    course_levels
    course_type
    course_style
    course_length
    course_hours
    course_sessions
    course_conduction
    child_mental_quotient
    study_material
    study_material_files {
      mimetype
      path
    }
    diy_kits
    diy_files {
      mimetype
      path
    }
    students_enrolled
    target_talent
    target_career
    target_dimensions
    tags
    learning_points
    price
    faq {
      answer
      question
    }
    status
    in_wishlist
    is_liked
    total_liked
    sessions
    createdAt
    updatedAt
    
  }
}`;

export const GQ_GET_COURSE_REVIEWS = `query CourseReviews($course: GetReviewInput) {
  courseReviews(course: $course) {
    reviews {
      id 
      createdAt
      children {
        first_name
        last_name
        image
      }
    
      overall_course_rating
      headline
      description
      is_review_helpful{
      parent
        report
      }
      is_reported_abusive 
      parent {
        first_name
        last_name
      }
      parent_id
    }
    recommended
    average_rating
    progress_ratings {
      rating
      percentage
    }
  }
}`;

export const GQ_WRITE_REVIEW_ON_COURSE = `mutation WriteReviewOnCourse($course: CourseReviewInput) {
  writeReviewOnCourse(course: $course) {
    id
  }
}
`;
export const GQ_REPORT_AN_REVIEW = `mutation ReportAnReview($reviewId: String) {
  reportAnReview(review_id: $reviewId) {
    id
  }
}
`;
export const GQ_MAKE_REVIEW_REPORT= `mutation MarkReviewAsHelpful($reviewId: String, $report: Boolean) {
  markReviewAsHelpful(review_id: $reviewId, report: $report) {
    id
  }
}`;
export const GQ_DEMO_SESSION_FEEDBACK = `mutation DemoSessionFeedback($data: SessionDemoFeedbackInput) {
  demoSessionFeedback(data: $data) {
    id
  }
}`;



export const GQ_DEMO_SESSION_FEEDBACK2 = `mutation CounsellingSessionFeedback($data: SessionDemoFeedbackInput) {
  counsellingSessionFeedback(data: $data) {
    id
  }
}`;



export const GQ_SINGLE_COURSE_DETAIL = `query Query($courseId: ID) {
  course(id: $courseId) {
    id
    name
    description
    image
    price
    booking_url
    duration
    level
    rating
    categories
  }
}`;

export const GQ_ADD_COURSE_TO_WISHLIST = `mutation Mutation($wishlist: WishlistInput) {
  addCourseToWishlist(wishlist: $wishlist) {
   id 
  }
}`;

export const GQ_REMOVE_COURSE_FROM_WISHLIST = `mutation Mutation($removeFromWishlistId: String) {
  removeFromWishlist(id: $removeFromWishlistId) {
    id
  }
}`;

export const GQ_GET_WISHLIST_COURSES = `query GetWishlistedCourses {
  getWishlistedCourses {
    course {
      id
      name
      description
      image
      booking_url
      price
      level
      duration
      rating
      categories
      in_wishlist
    }
  }
}`;


export const GQ_GET_ONLINE_COURSE = `query Query($query: String) {
  getOnlineCourse(query: $query) {
    id
    name
    
  }
}`;

export const GQ_GET_INTEREST = `query Query($query: String) {
  getInterest(query: $query) {
    id
    name
  }
}`;

export const GQ_GET_HOBBIES = `query Query($query: String) {
  getHobby(query: $query) {
    id
    name
  }
}`;

export const GQ_GET_SCHOOL = `query Query($query: String) {
  getSchool(query: $query) {
    id
    name
  }
}`;

export const GQ_GET_AVATAR = `query GetAllAvatar {
  getAllAvatar {
    avatar
  }
}`;


export const GQ_GET_ALL_SCHOOL = `query Query {
  getAllSchool {
    id
    name
  }
}`;

export const GQ_GET_ALL_HOBBIES = `query Query{
  getAllHobbies {
    id
    name
  }
}`;

export const GQ_GET_ALL_INTEREST = `query Query {
  getAllInterests {
    id
    name
  }
}`;

export const GQ_SEARCH_HABIT = `query Query {
  getHabit {
    id
    name
  }
}`;

export const GQ_GET_ALL_ONLINE_COURSE = `query GetAllOnlineCourses {
  getAllOnlineCourses {
    id
    name
    icon
    blueicon
   
  }
}`;


export const GQ_CREATE_NEW_CHILD = `mutation createNewChild($child: ChildInput) {
  createNewChild(child: $child) {
   first_name
   last_name
   date_of_birth
   school_standard
   grade12_child
   grade3_5_child
   gender
   city
   image
   id
  }
}`;


export const GQ_ADD_SCHOOL = `mutation Mutation($name: String) {
  addSchool(name: $name) {
    id
    name
  }
}`;

export const GQ_ADD_ONLINE_COURSE = `mutation AddOnlineCourse($name: String) {
  addOnlineCourse(name: $name) {
    id
    name
  }
}`;
export const GQ_ADD_INTEREST = `mutation Mutation($name: String) {
  addInterest(name: $name) {
    id
    name
  }
}`;
export const GQ_ADD_HOBBY = `mutation Mutation($name: String) {
  addHobby(name: $name) {
    id
    name
  }
}`;

export const GQ_ADD_HABIT = `mutation Mutation($name: String, $standard: String) {
  addHabit(name: $name, standard: $standard) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_IMAGE_BANNER = `mutation UpdateChildMedia($child: ChildMediaUpdate) {
  updateChildMedia(child: $child) {
    id
  }
}`;
export const GQ_UPDATE_CHILD_PROFILE_INFO = `mutation UpdateChildBasicDetails($child: ChildBasicDetailsUpdate) {
  updateChildBasicDetails(child: $child) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_EDUCATION_INFO = `mutation UpdateEducationInfo($child: ChildEducationUpdate) {
  updateEducationInfo(child: $child) {
    id
  }
}`;

export const GQ_SEARCH_COURSE_PROVIDERS = `query Query {
  getAllCourseProvider {
    id
    name
  }
}`;

export const GQ_GET_FEARS = `query Fears {
  parentExpectationsFearsConcerns {
    fears {
      updatedAt
      name
      id
      createdAt
      icon
    }
    expectations {
      id
      name
      icon
      whiteicon
      createdAt
      updatedAt
    }
    concerns {
      id
      name
      createdAt
      updatedAt
      icon
    }
  }
}`;

export const GQ_GET_ALL_ONLINE_COURSES_BY_CHILD = `query GetChildEnrolledOnlineCourses($getChildEnrolledOnlineCoursesId: String) {
  getChildEnrolledOnlineCourses(id: $getChildEnrolledOnlineCoursesId) {
    child
    online_course
    course_provider
    courseEndDate
    coursePursuing
    courseStartDate
    id
  }
}`;
export const GQ_ADD_FEAR_CONCERN_EXPECTATIONS = `mutation ChildFearConcernExpectations($child: childFearConcernExpectations) {
  childFearConcernExpectations(child: $child) {
    id
    first_name
    last_name
  }
}`;
export const GQ_CREATE_PARENT_EXPECTATION_FEAR_CONCERN = `mutation CreateParentExpectationFearConcerns($name: String, $type: String) {
  createParentExpectationFearConcerns(name: $name, type: $type) {
    name
    icon
  }
}`;

export const GQ_GET_GLOBAL_ATTRIBUTE_BY_TYPE = `query GetAttributesByType($filter: childFilter) {
  getAttributesByType(filter: $filter) {
   id
    title
    standard
    type
    attribute_name
    icon
  }
}`;

export const GQ_ADD_ONLINE_COURSE_PROVIDER = `mutation AddCourseProvider($name: String) {
  addCourseProvider(name: $name) {
    id
    name
  }
}`;

export const GQ_ADD_CHILD_ONLINE_COURSE = `mutation Mutation($course: EnrollCourse) {
  addEnrolledOnlineCourse(course: $course) {
    online_course
    courseStartDate
    courseEndDate
    course_provider
    coursePursuing
    child
  }
}`;

export const GQ_GET_GLOBAL_CHILD_ATTRIBUTES = `query FetchGroupedAttributes($standard: Int) {
  fetchGroupedAttributes(standard: $standard) {
    attribute_name
    attribute_type
    attributes {
      id
      title
      standard
      type
      attribute_name
    }
  }
}`;

export const GQ_ADD_CHILD_GLOBAL_ATTRIBUTE = `mutation Mutation($attribute: ChildAttributeInput) {
  createChildAttribute(attribute: $attribute) {
    id
    title
    attribute_name
  }
}`;

export const GQ_CREATE_FAMILY_ATTRIBUTE = `mutation CreateFamilyAttribute($title: String, $type: String) {

  createFamilyAttribute(title: $title, type: $type) {

    id

    title

    name

  }

}`;

export const GQ_ASSIGN_CHILD_ATTIBUTES = `mutation Mutation($attribute: ChildAssignAttribute) {
  createUpdateChildAttribute(attribute: $attribute) {
    id
  }
}`;
export const GQ_INTERST_CHILD_ATTIBUTES = `mutation updateChildInterestsTDP($child: ChildInterestsUpdate) {
  updateChildInterestsTDP(child: $child) {
   id
   interests{
interestName
   }
  }
}`;





export const GQ_GET_CHILD_ASSIGNED_ATTRIBUTES = `query GetAttributesByType($child: String) {
  groupedChildAssignedAttributes(child: $child) {
    attribute_type
    attribute_name
    attributes {
      title
      score
      icon
      type
      attribute_name
      id
    }
  }
}`;

export const GQ_UPDATE_ENROLLED_COURSE = `mutation UpdateExistingEnrolledCourse($course: EnrollCourseUpdate) {
  updateExistingEnrolledCourse(course: $course) {
    id
  }
}`;

export const GQ_DELETE_ENROLLED_COURSE = `mutation RemoveEnrolledCourse($removeEnrolledCourseId: String) {
  removeEnrolledCourse(id: $removeEnrolledCourseId) {
    id
  }
}`;

export const GQ_GET_CHILD_REPORT_CARDS = `query GetReportCard($getReportCardId: String) {
  getReportCard(id: $getReportCardId) {
    child
    id
    path
  }
}`;

export const GQ_ADD_CHILD_REPORT_CARD = `mutation AddChildReportCard($addChildReportCardChild2: ChildAddReportCard) {
  addChildReportCard(child: $addChildReportCardChild2) {
    child
    path
  }
}`;

export const GQ_DELETE_CHILD_REPORT_CARD = `mutation RemoveReportCard($removeReportCardId: String) {
  removeReportCard(id: $removeReportCardId) {
    id
  }
}`;

export const GQ_GET_CHILD_CERTIFICATES = `query GetCertificate($getCertificateId: String, $type: String) {
  getCertificate(id: $getCertificateId, type: $type) {
    child
    path
    type
    name
    mimetype
    thumb
    id
  }
}`;

export const GQ_ADD_CHILD_CERTIFICATE = `mutation AddChildCertificates($child: ChildAddCertificate) {
  addChildCertificates(child: $child) {
    child
    path
  }
}`;

export const GQ_DELETE_CHILD_CERTIFICATES = `mutation RemoveCertificate($removeCertificateId: String) {
  removeCertificate(id: $removeCertificateId) {
    id
  }
}`;

export const GQ_GET_CHILD_DEMO_COURSES = `query GetCourseDemoByChild($getCourseDemoByChildId: String) {
  getCourseDemoByChild(id: $getCourseDemoByChildId) {
    user
    headline
    name
    banner
    image
    description
  }
}`;
export const GQ_GET_CHILD_ONGOING_COURSES = `query GetCoursesByChild($getCoursesByChildId: String) {
  getCoursesByChild(id: $getCoursesByChildId) {
    user
    name
    image
    banner
    description
    headline
  }
}`;
export const GQ_GET_CHILD_LIKED_COURSES = `query GetLikedCoursesByChild($getLikedCoursesByChildId: String) {
  getLikedCoursesByChild(id: $getLikedCoursesByChildId) {
    user
    name
    headline
    description
    banner
    image
  }
}`;

export const GQ_GET_CHILD_SCHOOLS = `query GetSchoolByChild($getSchoolByChildId: String) {
  getSchoolByChild(id: $getSchoolByChildId) {
    id
    child
    school_name
    school_board
    state_board_name
    school_end_grade
    school_start_grade
    school_pursuing
  }
}`;
export const GQ_ADD_CHILD_SCHOOL = `mutation AddChildSchool($child: ChildSchoolInput) {
  addChildSchool(child: $child) {
    id
    child
    school_board
    school_name
    state_board_name
    school_end_grade
    school_start_grade
    school_pursuing
  }
}`;
export const GQ_UPDATE_CHILD_SCHOOL = `mutation UpdateChildSchool($child: ChildSchoolUpdate) {
  updateChildSchool(child: $child) {
    id
    school_name
    school_end_grade
    school_start_grade
    school_board
    child
    state_board_name
    school_pursuing
  }
}`;
export const GQ_DELETE_CHILD_SCHOOL = `mutation DeleteChildSchool($deleteChildSchoolId: String) {
  deleteChildSchool(id: $deleteChildSchoolId) {
    id
  }
}`;
export const GQ_UPDATE_CHILD_SLEEP_TIME = `mutation UpdateChildSleepDetails($child: ChildSleepWakeTIme) {
  updateChildSleepDetails(child: $child) {
    wake_time {
      end
      start
    },
    sleep_time {
      end
      start 
    }
  }
}`;

export const GQ_UPDATE_CHILD_SCREEN_TIME = `mutation ChildScreenTime($child: ChildScreenTime) {
  ChildScreenTime(child: $child) {
    social_media_time {
      start
      end
    }
    online_course_time {
      start
      end
    }
  }
}`;

export const GQ_UPDATE_CHILD_FAMILY_INFO = `mutation UpdateChildFamilyInfo($child: ChildFamilyInformation) {
  updateChildFamilyInfo(child: $child) {
    family_information {
      mother_company_name
      child_sibling_combination
      child_sibling_count
    }
  }
}`;

export const GQ_UPDATE_PARENTS_PROF_INFO = `mutation updateParentProfessionTDP($child: ChildParentProfession) {
  updateParentProfessionTDP(child: $child) {
    family_information {
        father_company_name
        father_profession
        father_designation
      mother_company_name
      mother_profession
      mother_designation
    }
  }
}`;
export const GQ_PARENTS_TEST_STATUS_INFO = `query getParentTestStatusTDP($id: String) {
  getParentTestStatusTDP(id: $id) {
  exam_status
  }
}`;


export const GQ_UPDATE_FAMILY_TYPE_INFO = `mutation updateChildFamilyTypeTDP($child: ChildFamilyTypeInfo) {
  updateChildFamilyTypeTDP(child: $child) {
    family_information {
       family_type
    }
  }
}`;

export const GQ_UPDATE_SIBLING__INFO = `mutation updateChildSiblingInfo($child: ChildSiblingInfo) {
  updateChildSiblingInfo(child: $child) {
    family_information {
      child_sibling_count
       family_type
      child_sibling_combination
    }
  }
}`;


export const GQ_UPDATE_LIVE_WITH_GRANDPARENTS_INFO = `mutation updateChildLivingWithGrandparentsTDP($child: ChildLivingWithGrandParents) {
  updateChildLivingWithGrandparentsTDP(child: $child) {
    family_information {
      live_with_grandparents
    }
  }
}`;





export const GQ_UPDATE_CHILD_BIRTH_INFO = `mutation UpdateChildBirthInfo($child: ChildBirthInfoUpdate) {
  updateChildBirthInfo(child: $child) {
    id
  }
}`;
export const GQ_UPDATE_CHILD_BLOOD_GROUP = `mutation UpsertChildBloodGroup($child: String, $bloodGroup: String) {
  upsertChildBloodGroup(child: $child, blood_group: $bloodGroup) {
    first_name,
    blood_group
  }
}`;
export const GQ_UPDATE_CHILD_ASSESSMENT = `mutation UpsertChildAssessment($child: String, $psychologicallyAssessed: String, $learningDisability: String) {
  upsertChildAssessment(child: $child, psychologically_assessed: $psychologicallyAssessed, learning_disability: $learningDisability) {
    id
    psychologically_assessed
    learning_disability
  }
}`;

export const GQ_UPDATE_CHILD_SIBLING = `mutation UpdateChildSibling($child: ChildSibling) {
  updateChildSibling(child: $child) {
    id   
  }
}`;

export const GQ_GET_CHILD_ATTRIBUTE_OPTIONS_BY_NAME_STANDARD = `query GetAttributesByStandard($name: String, $standard: Int) {
  getAttributesByStandard(name: $name, standard: $standard) {
    title
    id
    standard
    type
    icon
    attribute_name
    sortOrder
    isApproved
  }
}`;

export const GQ_UPDATE_CHILD_TUITION_INFO = `mutation UpdateTuitionInfo($child: ChildTuitionInfo) {
  updateTuitionInfo(child: $child) {
    id
    child_tuition_subjects {
      tution_type
      subject
    }
    online_courses {
      course_name
      course_type
    }
  }
}`;

export const GQ_UPDATE_CHILD_SOCIAL_INFO = `mutation UpdateSocialInfo($child: ChildSocialInfo) {
  updateSocialInfo(child: $child) {
    id
  }
}`;

export const GQ_GET_ALL_NOTIFICATIONS = `query GetAllNotifications {
  getAllNotifications {
    title
    message
    link
    createdAt
  }
}`;
export const GQ_SUBMIT_FEEDBACK = `mutation SubmitFeedback($input: FeedbackInput) {
  submitFeedback(input: $input) {
    feedback_type
    id
  }
}`;

export const GQ_LOGOUT = `mutation Logout($id: String){
  logout(id: $id)
}`;






export const GQ_GET_FEEDBACK_SECTION = `query GetFeedbackSection {
  getFeedbackSection {
    id
    feedback_section_name
  }
}`;


export const GQ_SUBMIT_GUIDANCE_FEEDBACK = `mutation SubmitGuidanceFeedback($input : GuidanceFeedbackInput){
  submitGuidanceFeedback(input : $input){
      id
  }
}`;



export const GQ_GET_SUBMITTED_USER_SURVEY = `query GetSubmittedUserSurvey($surveyMilestoneType: String) {
  getSubmittedUserSurvey(survey_milestone_type: $surveyMilestoneType) {
    parent_id
    isFeedbackSubmitted
  }
}`;
export const GQ_GET_LIST_OF_SURVEY = `query GetListOfSurvey($surveyMilestoneType: String) {
  getListOfSurvey(survey_milestone_type: $surveyMilestoneType) {
    id
    survey_milestone_type
    survey_question_text
    survey_question_options {
      option_text
    }
  }
}`;

export const GQ_SUBMIT_USER_SURVEY= `mutation SubmitUserSurvey($input: SurveyInput) {
  submitUserSurvey(input: $input) {
    id
  }
}`;

export const GQ_GET_COURSE_PROVIDER = `query GetBusinessProviderInfo($providerId: String) {
  getBusinessProviderInfo(provider_id: $providerId) {
    business {
      id
      logo
      banner
      business_name
      website
      platform_reference
      google_store_link
      apple_store_link
      director_name
      foundation_year
      category {
        category
        icon
      }
      about
      location
      program_listing_type {
        id
        name
      }
      business_description
      instagram
      twitter
      facebook
      youtube
      linkedin
    }
    courses {
      id
      provider_name
      name
      banner {
      path
      mimetype
    }
    provider_image
    }
    course_booked
    follower
    followed
  }
}`;
export const GQ_GET_PARENT_PROFESSION = `query GetAllProfessions {
  getAllProfessions {
    careers {
      title
    }
    industries {
      title
     
    }
  }
}`;

export const GQ_GET_DESIGNATION = `query GetDesignation($query: String) {
  getDesignation(query: $query) {
    name
 
  }
}`;

export const GQ_FOLLOW_PROVIDER = `mutation FollowProvider($followProviderId: String) {
  followProvider(id: $followProviderId) {
    id
  }
}`;

export const GQ_UNFOLLOW_PROVIDER = `mutation UnfollowProvider($unfollowProviderId: String) {
  unfollowProvider(id: $unfollowProviderId) {
    id
  }
}`;

export const GQ_UPDATE_CHILD_STAGE = `mutation UpdateChildStage($updateChildStageId: String) {
  updateChildStage(id: $updateChildStageId)
}`;

export const GQ_UPDATE_CHILD_SECTION_ORDER = `mutation UpdateSectionOrder($child: String, $section: String) {
  updateSectionOrder(child: $child, section: $section)
}`;

export const GQ_UPDATE_CHILD_INITIAL_REQUEST = `mutation CounsellingInitialRequest($childrenId: String) {
  counsellingInitialRequest(children_id: $childrenId)
}`;
export const GQ_UPDATE_COUNSELLINGSESSION = `mutation updateCounsellingSession($childId: String){
  updateCounsellingSession(childId : $childId)
}`;
export const GQ_GET_COUNSELLINGSESSION = `query GetCounsellingStatus($childId: String){
  getCounsellingStatus(childId : $childId){
      talent_guidance_booked
  }
}`;




export const GQ_ASSIGN_TEACHER_TO_STUDENT = `mutation AssignTeacherToStudent($data: StudentTeacherAssignmentInput) {
  assignTeacherToStudent(data: $data) {
    id
  }
}`;


export const GQ_UPDATE_STUDENT_PARENT_SECONDARY_INFO = `mutation UpdateStudentParentSecondaryInfo($data: StudentParentSecondaryInput) {
  updateStudentParentSecondaryInfo(data: $data) {
    id
  }
}`;

export const GQ_DELETE_PARENT_ACCOUNT_SURVEY=   `mutation DeleteParentAccountSurvey( $input : DeleteAccountSurveyInput){
  deleteParentAccountSurvey( input : $input){
     id
  }
}`



export const GQ_DELETE_ARCHIVED_USER = `mutation DeleteArchivedUser($deleteArchivedUserId: String) {
  deleteArchivedUser(id: $deleteArchivedUserId)
}`;