import Image from "next/image";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { setIn, useFormik } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";
import { useSnackbar, withSnackbar } from "notistack";
import Autocomplete from "react-google-autocomplete";
import {
  ERROR_MESSAGE,
  EventEmitter,
  SUCCESS_MESSAGE,
} from "../../helper/index";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import APIService from "../../@mock-api/api.service";
import { images } from "../../../constant";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { User, ArrowLeft2, Calendar1, CloseCircle, Gps } from "iconsax-react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from "react-bootstrap/ProgressBar";

const apiService = new APIService();

import attach from "../../../public/assets/img/icons/img-add.png";

const schoolStandard = [1, 2, 3, 4, 5];
const parentOptions = ["Father", "Mother", "Guardian"];

const MandatoryDetailsPopup = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [progressValue, setProgressValue] = useState(0);
  //Redux states
  const userData = useSelector((state) => state);

  const [avatarData, setAvatarData] = useState([]);

  const [avatarGallery, setAvatarGallery] = useState([]);

  //Handling Image
  const [uploadedFilePath, setUploadedFilePath] = useState("");
  const [cropper, setCropper] = useState();
  const [cropData, setCropData] = useState("");
  const [userDetails, setUserDetails] = useState("");

  //Handle Button
  const [isNextBtnDisabled, setNextBtnDisabled] = useState(false);
  const [isAddBtnDisabled, setAddBtnDisabled] = useState(false);

  const [heading, setHeading] = useState("Who is filling this form?");

  //Handling show/hide view
  const [isCropperVisible, setCropperVisible] = useState(false);

  // SET STEPS
  const [step, setStep] = useState(1);

  //current added child

  const [childId, setChildId] = useState(undefined);

  //Formik validation and initialisation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Can only be alphabet")
      .max(15, "First name must be at less than 15 chars!")
      .required("Enter first name"),
    last_name: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Can only be alphabet")
      .max(15, "Last name must be at less than 15 chars!")
      .required("Enter last name"),
    gender: Yup.string().required("Select Gender"),
    date_of_birth: Yup.string().required("Select Date of birth").nullable(),
    school_standard: Yup.string().required("Select Standard"),
  });
  const initialValues = {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    school_standard: "",
    parentType: "",
    image: cropData ? cropData : uploadedFilePath,
    city: "",
  };

  useEffect(() => {
    let teacherAssignment = localStorage.getItem("assigned_teacher");
    let userDetails = localStorage.getItem("userDetails");
    if (teacherAssignment) {
      if (
        JSON.parse(teacherAssignment).parent_email ==
        JSON.parse(userDetails).email
      ) {
        updateBasicForm(teacherAssignment);
      }
    }
  }, []);

  useEffect(() => {
    if (step === 2) {
      const intervalId = setInterval(() => {
        setProgressValue((prev) => {
          if (prev >= 8) {
            props.handleStartTestModal();
            clearInterval(intervalId);
            return 1;
          } else {
            return prev + 1;
          }
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [step]);

  const updateBasicForm = (teacherAssignment) => {
    let child = JSON.parse(teacherAssignment).child.split(" ");

    let email = {
      target: {
        name: "first_name",
        value: child[0],
      },
    };
    let phone = {
      target: {
        name: "last_name",
        value: child[1],
      },
    };
    handleChange(email);
    handleChange(phone);
  };

  const assignTeacherToStudent = (data, child) => {
    let userDetails = localStorage.getItem("userDetails");
    apiService
      .assignTeacherToStudent({
        data: {
          children_id: child.id,
          teacher_id: JSON.parse(data).teacher,
          student_roll_number: JSON.parse(data).roll_number,
          student_section: JSON.parse(data).section,
          // student_grade: parseInt(JSON.parse(data).grade),
          student_grade: JSON.parse(data).grade=="KG2"?0:parseInt(JSON.parse(data).grade)
        },
      })
      .then((res) => {
        if (res.data.data && res.data.data.assignTeacherToStudent) {
          if (JSON.parse(data).registered) {
            if (
              JSON.parse(data).parent_email != JSON.parse(userDetails).email
            ) {
              updateTeacherAssigned(data, userDetails);
            }
          }
          getChildDetails(child.id);
          enqueueSnackbar(
            "The teacher has been assigned successfully.",
            SUCCESS_MESSAGE
          );
          localStorage.removeItem("assigned_teacher");
        } else {
          enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
        }
      });
  };

  const updateTeacherAssigned = (data, userDetails) => {
    apiService
      .updateStudentSecondaryParent({
        data: {
          parent_secondary_email: JSON.parse(userDetails).email,
          parent_secondary_phone: JSON.parse(userDetails).phone,
          student_roll_number: JSON.parse(data).roll_number,
        },
      })
      .then((res) => {
        if (res.data.data && res.data.data.updateStudentParentSecondaryInfo) {
          localStorage.removeItem("assigned_teacher");
        } else {
          enqueueSnackbar("Error updating student", ERROR_MESSAGE);
        }
      });
  };

  const getChildDetails = (childId) => {
    apiService.singleChildDetail({ childId }).then((res) => {
      if (res.data.data && res.data.data.child) {
        localStorage.setItem(
          "childDetails",
          JSON.stringify(res.data.data.child)
        );
      } else {
        console.log("error is", res.data);
      }
    });
  };

  //Handling form submit
  const onSubmit = (values, actions) => {
    let teacherAssignment = localStorage.getItem("assigned_teacher");
    if (step != 4) {
      const data = values;
      const user = localStorage.getItem("userDetails");
      data.parent = JSON.parse(user).id;
      data.image = cropData
        ? cropData
        : uploadedFilePath
        ? uploadedFilePath
        : "";
      setAddBtnDisabled(true);
      apiService
        .addChild({ child: data })
        .then((res) => {
          if (res.data.data && res.data.data.createNewChild) {
            if (teacherAssignment) {
              assignTeacherToStudent(
                teacherAssignment,
                res.data.data.createNewChild
              );
            }
            setAddBtnDisabled(false);
            setCropData("");
            setUploadedFilePath(data.image);
            enqueueSnackbar("Child added successfully.", SUCCESS_MESSAGE);
            setStep(4);
            setChildId(res.data.data.createNewChild.id);

            // update child count in local storage
            const userDetails = JSON.parse(user);
            userDetails.childCount = userDetails.childCount + 1;
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            localStorage.setItem(
              "selected_child",
              res.data.data.createNewChild.id
            );
            localStorage.setItem(
              "child",
              JSON.stringify(res.data.data.createNewChild)
            );
          } else {
            setAddBtnDisabled(false);
            enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
          }
        })
        .catch((errors) => {
          setAddBtnDisabled(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: false,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      // setCropperVisible(false)
      uploadBase64(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const onCropChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedFilePath(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setCropperVisible(true);
  };

  const handleFileUpload = (event) => {
    let file = event.target.files[0];
    if (file && !file.name) {
      enqueueSnackbar("Please select image", ERROR_MESSAGE);
      return false;
    }
    if (file.size > 10e6) {
      enqueueSnackbar("Please upload a file smaller than 10 MB", ERROR_MESSAGE);
      return false;
    }
    onCropChange(event);
  };

  const getAvatarData = () => {
    apiService.getAvatarData().then((res) => {
      if (res.data.data && res.data.data.getAllAvatar) {
        setAvatarData(res.data.data.getAllAvatar);
        setUploadedFilePath(res.data.data.getAllAvatar[0]?.avatar);
        const avatarObj = [];
        if (res.data.data.getAllAvatar.length) {
          res.data.data.getAllAvatar.map((item) =>
            avatarObj.push({
              original: item.avatar,
              thumbnail: item.avatar,
            })
          );
          // avatarObj.push({
          //     original: (attach.src),
          //     thumbnail: (attach.src),
          // })
          setAvatarGallery(avatarObj);
        }
      } else {
        enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
      }
    });
  };

  const disabledDate = (current) => {
    return current && current > moment().subtract(5, "years")._d;
  };

  const changeStep = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    
    if (step === 1) {
      setHeading("Who is filling this form?");
    }
    if (step === 2) {
      setHeading("What is your child’s name?");
    }
    if (step === 3) {
      setHeading(`Hello ${values?.first_name}’s ${values.parentType},`);
    }
  }, [step]);

  const onThumbnailClick = (e, i) => {
    setCropData("");
    setUploadedFilePath(avatarGallery[i].original);
  };

  const basicInfoView = () => {
    return (
      <>
        {!isCropperVisible && (
          <>
            {step === 1 && (
              <div>
                <div className="secondchildmodalbodyinside">
                  <div className="row">
                    <div className="col-md-8">
                      <p className="dearparentheading">Dear Parent,</p>
                      <p className="dearparentheading2">
                        {" "}
                        You may now directly go on to the 8-Learning Dimensions’
                        Child Test as you've previously finished the Parent
                        Test.
                      </p>
                      <button
                        className="continuewithchidbtn"
                        onClick={changeStep}
                      >
                        Continue with Child Test
                      </button>
                    </div>
                    <div className="col-md-4">
                      {/* <img src={images.secondmodalpopupicon3.default.src} />
                        <img src={images.secondmodalpopupicon2.default.src} /> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <>
                <div className="col-12 report-body px-3">
                  <div className="row justify-content-md-between justify-content-center">
                    <div className="col-12 info text-center mb-md-4 mb-0">
                      <h2 className="text-white customheading  mb-5">
                        We know you’re a <span>Super Parent!</span>
                      </h2>
                      {/* <h3 className="mb-2 fw-regular">We know you’re a Super Parent!</h3> */}
                    </div>
                    <div className="col-12 info text-center mb-md-4 mb-0">
                      <h3 className="mb-2 fw-regular">
                        Please answer a few mandatory questions about {props.childDetails.first_name}{" "}
                        <br className="d-none d-md-block" /> before we move to
                        the Child Test!{" "}
                      </h3>
                    </div>
                    <div className="col-12 foot text-center">
                      <div className="col-auto test-status mb-4">
                        <div className="col-auto card mx-auto progress-card progress-cardcustom test-blue p-0 mb-1">
                          <button
                            onClick={() => props.handleStartTestModal()}
                            className="btn btn-yellow"
                          ></button>
                          <ProgressBar now={(progressValue / 8) * 100} />
                        </div>
                        <h5>
                          Test will start automatically in{" "}
                          <b>{8 - progressValue}s</b>
                        </h5>
                      </div>
                      {/* <button type={'button'} onClick={handleModalClose} className="btn btn-border">Start another test</button> */}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {isCropperVisible && (
          <div
            className="col-12 form-details"
            style={{ width: "100%", float: "center", height: "100%" }}
          >
            <div className=" cropper-custom">
              <Cropper
                style={{ height: 300, width: "100%" }}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                src={uploadedFilePath}
                zoomable={false}
                aspectRatio={1}
                preview=".img-preview"
                guides={false}
                viewMode={1}
                dragMode="move"
                cropBoxMovable={true}
              />
            </div>
            <div className="pt-0 modal-footer pb-0">
              <button
                onClick={getCropData}
                className="btn btn-blue float-end mx-auto mt-3"
                disabled={isAddBtnDisabled ? true : false}
              >
                Set avatar{" "}
                {isAddBtnDisabled && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mx-2"
                  />
                )}
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        show={props.showModal}
        // show={true}
        id="settings"
        aria-labelledby="settings"
        tabIndex="-1"
        dialogClassName={`modal-dialog-centered modal-trans cussecondmodalstep-${step} step-${step} ${
          isCropperVisible ? "step-img" : ""
        }`}
        backdrop={"static"}
        size="md"
      >
        <div className="modal-onboard p-0">
          <Modal.Body className={`secondchildmodalbody-${step}`}>
            <form
              key="basicInfoView"
              id="registerForm"
              className="tooltip-end-bottom"
              onSubmit={handleSubmit}
            >
              {basicInfoView()}
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default withSnackbar(MandatoryDetailsPopup);
