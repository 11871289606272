import React, { useEffect, useState } from "react";
import { images } from "../../../../constant";
import ToolTipModal from "../../toot_tip/ToolTip";
import {
  InfoCircle,
  Briefcase,
  TextalignJustifyleft,
  SliderVertical,
  People,
} from "iconsax-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { validateJsonString } from "../../../helper";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import Select from "react-select";
import APIService from "../../../@mock-api/api.service";
import Spinner from "react-bootstrap/Spinner";
const siblings = [0, 1, 2, 3, 4, 5];
let api = new APIService();

export default function ParentProfession(props) {
  const {
    appreciatingLiners,
    eduLiner,
    handleFieldCoins,
    handleEducateLiner,
    handleAppLiners,
    getFieldMandatoryStatus,
    handleOutOffFocus,
    fieldCoins,
  } = props;
  const [siblingOptions, setSiblingOptions] = useState([]);
  const [sibling, setSibling] = useState(false);
  const [countSib, SetCountSib] = useState(null);
  const [professionCarreers, setProfessionCarreers] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [industryOptions, setIndustryOptions] = useState("");
  const [careersOptions, setCareersOptions] = useState("");
  const [designationOptions, setDesignationOptions] = useState("");
  const [childDetails, setchildDetails] = useState("");
  useEffect(() => {
    !props.errorMessage ? "" : setErrorMessage(props.errorMessage);
  }, [props.errorMessage]);

  const section_name = "family_information";

  const girlSiblings = [
    {
      options: [
        { label: "Girl - Girl", value: "Girl - Girl" },
        { label: "Girl - Boy", value: "Girl - Boy" },
      ],
    },
    {
      options: [
        { label: "Girl - Girl - Girl", value: "Girl - Girl - Girl" },
        { label: "Girl - Girl - Boy", value: "Girl - Girl - Boy" },
        { label: "Girl - Boy - Boy", value: "Girl - Boy - Boy" },
      ],
    },
    {
      options: [
        {
          label: "Girl - Girl - Girl - Girl",
          value: "Girl - Girl - Girl - Girl",
        },
        {
          label: "Girl - Girl - Girl - Boy",
          value: "Girl - Girl - Girl - Boy",
        },
        { label: "Girl - Girl - Boy - Boy", value: "Girl - Girl - Boy - Boy" },
        { label: "Girl - Boy - Boy - Boy", value: "Girl - Boy - Boy - Boy" },
      ],
    },
    {
      options: [
        {
          label: "Girl - Girl - Girl - Girl - Girl",
          value: "Girl - Girl - Girl - Girl - Girl",
        },
        {
          label: "Girl - Girl - Girl - Girl - Boy",
          value: "Girl - Girl - Girl - Girl - Boy",
        },
        {
          label: "Girl - Girl - Girl - Boy - Boy",
          value: "Girl - Girl - Girl - Boy - Boy",
        },
        {
          label: "Girl - Girl - Boy - Boy - Boy",
          value: "Girl - Girl - Boy - Boy - Boy",
        },
        {
          label: "Girl - Boy - Boy - Boy - Boy",
          value: "Girl - Boy - Boy - Boy - Boy",
        },
      ],
    },
    {
      options: [
        {
          label: "Girl - Girl - Girl - Girl - Girl - Girl",
          value: "Girl - Girl - Girl - Girl - Girl - Girl",
        },
        {
          label: "Girl - Girl - Girl - Girl - Girl - Boy",
          value: "Girl - Girl - Girl - Girl - Girl - Boy",
        },
        {
          label: "Girl - Girl - Girl - Girl - Boy - Boy",
          value: "Girl - Girl - Girl - Girl - Boy - Boy",
        },
        {
          label: "Girl - Girl - Girl -  Boy - Boy - Boy",
          value: "Girl - Girl - Girl -  Boy - Boy - Boy",
        },
        {
          label: "Girl - Girl - Boy - Boy - Boy - Boy",
          value: "Girl - Girl - Boy - Boy - Boy - Boy",
        },
        {
          label: "Girl - Boy - Boy - Boy - Boy - Boy",
          value: "Girl - Boy - Boy - Boy - Boy - Boy",
        },
      ],
    },
  ];

  const boySiblings = [
    {
      options: [
        { label: "Boy - Boy", value: "Boy - Boy" },
        { label: "Boy - Girl", value: "Boy - Girl" },
      ],
    },
    {
      options: [
        { label: "Boy - Boy - Boy", value: "Boy - Boy - Boy" },
        { label: "Boy - Boy - Girl", value: "Boy - Boy - Girl" },
        { label: "Boy - Girl - Girl", value: "Boy - Girl - Girl" },
      ],
    },
    {
      options: [
        { label: "Boy - Boy - Boy - Boy", value: "Boy - Boy - Boy - Boy" },
        { label: "Boy - Boy - Boy - Girl", value: "Boy - Boy - Boy - Girl" },
        { label: "Boy - Boy - Girl - Girl", value: "Boy - Boy - Girl - Girl" },
        {
          label: "Boy - Girl - Girl - Girl",
          value: "Boy - Girl - Girl - Girl",
        },
      ],
    },
    {
      options: [
        {
          label: "Boy - Boy - Boy - Boy - Boy",
          value: "Boy - Boy - Boy - Boy - Boy",
        },
        {
          label: "Boy - Boy - Boy - Boy - Girl",
          value: "Boy - Boy - Boy - Boy - Girl",
        },
        {
          label: "Boy - Boy - Boy - Girl - Girl",
          value: "Boy - Boy - Boy - Girl - Girl",
        },
        {
          label: "Boy - Boy - Girl - Girl - Girl",
          value: "Boy - Boy - Girl - Girl - Girl",
        },
        {
          label: "Boy - Girl - Girl - Girl - Girl",
          value: "Boy - Girl - Girl - Girl - Girl",
        },
      ],
    },
    {
      options: [
        {
          label: "Boy - Boy - Boy - Boy - Boy - Boy",
          value: "Boy - Boy - Boy - Boy - Boy - Boy",
        },
        {
          label: "Boy - Boy - Boy - Boy - Boy - Girl",
          value: "Boy - Boy - Boy - Boy - Boy - Girl",
        },
        {
          label: "Boy - Boy - Boy - Boy - Girl - Girl",
          value: "Boy - Boy - Boy - Boy - Girl - Girl",
        },
        {
          label: "Boy - Boy - Boy - Girl - Girl - Girl",
          value: "Boy - Boy - Boy - Girl - Girl - Girl",
        },
        {
          label: "Boy - Boy - Girl - Girl - Girl - Girl",
          value: "Boy - Boy - Girl - Girl - Girl - Girl",
        },
        {
          label: "Boy - Girl - Girl - Girl - Girl - Girl",
          value: "Boy - Girl - Girl - Girl - Girl - Girl",
        },
      ],
    },
  ];

  const validationSchema = Yup.object().shape({
    // siblings:Yup.string().required('Select siblings'),
    family_type: Yup.string().required("Select family_type"),
    live_with_grandparents: Yup.string().required(
      "Select live_with_grandparents"
    ),
    father_profession_info: Yup.string().required(
      "Select father_profession_info"
    ),
    father_profession: Yup.string().required("Select father_profession_info"),
    mother_profession_info: Yup.string().required(
      "Select mother_profession_info"
    ),
    mother_profession: Yup.string().required("Select mother_profession"),
  });

  const initialValues = {
    father_designation: "",
    father_profession_info: "",
    father_profession: "",
    mother_designation: "",
    mother_profession_info: "",
    mother_profession: "",
    father_company_name: "",
    mother_company_name: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: false,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const onSubmit = (values) => {
    let data = values;
    if (childDetails && childDetails.id) {
      data.id = childDetails.id;
    }
    data = {
      child: data,
    };
    props.updateParentsProfInfo(data);
  };

  const handleDetails = () => {
    if (!values.father_profession_info) {
      setErrorMessage("Select father industry of profession");
      return false;
    }
    if (!values.father_profession) {
      setErrorMessage("Select father profession ");
      return false;
    }

    if (!values.mother_profession_info) {
      setErrorMessage("Select mother industry of profession");
      return false;
    }
    if (!values.mother_profession) {
      setErrorMessage("Select mother profession ");
      return false;
    }

    onSubmit(values);
  };
  const handleOnFocus = (field, fieldValue) => {
    setErrorMessage("");
  };

  const handleChangeFunction = (event, field) => {
    setErrorMessage("");
    handleChange(event);
    let sectionValues = { section_name: section_name, ...values };
    let isValuePresent =
      event.target.value !== undefined && event.target.value !== ""
        ? true
        : false;
  };

  useEffect(() => {
    const child = localStorage.getItem("child");
    setchildDetails(JSON.parse(child))
    handleOutOffFocus();
  }, []);

  useEffect(() => {
    getDesignation();
    api.getParentProfession().then((res) => {
      if (res.data.data && res.data.data.getAllProfessions) {
        setProfessionCarreers(res.data.data.getAllProfessions);
        processData(res.data.data.getAllProfessions.industries, "Industry");
        processData(res.data.data.getAllProfessions.careers, "Career");
      }
    });
  }, []);

  const getDesignation = () => {
    api.getDesignation().then((res) => {
      if (res.data.data && res.data.data.getDesignation) {
        // setProfessionCarreers(res.data.data.getAllProfessions)
        processData(res.data.data.getDesignation, "Designation");
      }
    });
  };

  const processData = (data, name) => {
    const options = [];
    if (data.length) {
      data.map((item) => {
        options.push({
          label: item.title ? item.title : item.name,
          value: item.title ? item.title : item.name,
        });
      });
    }
    if (name === "Industry") {
      setIndustryOptions(options);
    }
    if (name === "Career") {
      setCareersOptions(options);
    }
    if (name === "Designation") {
      setDesignationOptions(options);
    }
  };

  return (
    <>
      <form>
      {props.showquestiontext && (
                <div className="Quetion_no_div">
                <div className="Quetion_no">
                  <p>Question {props.showsecondchildcurrentindex}/{props.showsecondchildlength}</p>
                </div>
              </div>
              )}
        <img className="img-fluid img-fluid-res" src={images.ParentProfession.default.src} />
        <h3 class="modal-title modal-title text-center mt-3 mb-3">
          Let us know about Profession of  <br />{childDetails.first_name}'s Parent
        </h3>
        <div className="col-12 form-main px-4">
          <div className="row">
            <div className="col-md-12 mb-0 px-0">
              <div className="col-12 form-group mb-2">
                <label className="text-capitalize" id={"FP_info_label"}>
                  {childDetails.first_name}’<span>s</span> Father Profession
                </label>
                <SliderVertical className="icon-field" variant="linear" />
                <CustomAutoComplete
                  handleOnFocus={() => {
                    handleOnFocus(
                      "father_profession",
                      values.father_profession_info
                    );
                  }}
                  handleOnBlur={() => handleOutOffFocus()}
                  attribute_name={"Industry"}
                  type={"industry"}
                  options={industryOptions}
                  placeholder={"Select industry of profession*"}
                  onFocusPlaceholder={"Start typing profession"}
                  selectedOption={values.father_profession_info}
                  selectedSubject={(value) => {
                    let event = {
                      target: {
                        name: "father_profession_info",
                        value: value.value,
                      },
                    };
                    handleChange(event);
                    // skillSelection(index, value)
                  }}
                  resetAfterSelect={false}
                  id={`industryFather`}
                />
              </div>

              {values.father_profession_info && (
                <div className={`col-12 form-group no-label mb-2`}>
                  <Briefcase className="icon-field" variant="linear" />
                  <CustomAutoComplete
                    handleOnFocus={() => {
                      setErrorMessage("");
                    }}
                    handleOnBlur={() => handleOutOffFocus()}
                    attribute_name={"Career"}
                    type={"career"}
                    options={careersOptions}
                    selectedOption={values.father_profession}
                    selectedSubject={(value) => {
                      let event = {
                        target: {
                          name: "father_profession",
                          value: value.value,
                        },
                      };
                      handleChange(event);
                      handleChangeFunction(event, "father_profession");
                      // skillSelection(index, value)
                    }}
                    resetAfterSelect={false}
                    id={`industryFather`}
                    placeholder={"Select father  profession*"}
                    onFocusPlaceholder={"Start typing profession"}
                  />
                </div>
              )}
              {values.father_profession_info && values.father_profession && (
                <div className={`col-12 form-group no-label mb-2`}>
                  <Briefcase className="icon-field" variant="linear" />
                  <CustomAutoComplete
                    handleOnFocus={() => {
                      setErrorMessage("");
                    }}
                    handleOnBlur={() => handleOutOffFocus()}
                    attribute_name={"Designation"}
                    type={"designation"}
                    options={designationOptions}
                    selectedOption={values.father_designation}
                    selectedSubject={(value) => {
                      let event = {
                        target: {
                          name: "father_designation",
                          value: value.value,
                        },
                      };
                      handleChange(event);
                    }}
                    resetAfterSelect={false}
                    id={`industryFather`}
                    placeholder={"Select father designation"}
                    onFocusPlaceholder={"Start typing designation"}
                  />
                </div>
              )}

              {values.father_profession_info &&
                values.father_profession &&
                values.father_designation && (
                  <>
                    <div className={`col-12 form-group no-label mb-2`}>
                      <TextalignJustifyleft
                        className="icon-field"
                        variant="linear"
                      />
                      <input
                        className={`form-control `}
                        name={"father_company_name"}
                        placeholder="Enter company name "
                        value={values.father_company_name}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
            </div>
            <div className="col-md-12 mb-0 px-0">
              <div className="col-12 form-group mb-2">
                <label className="text-capitalize" id={"MF_info_label"}>
                  {childDetails.first_name}’<span>s</span> Mother Profession
                </label>
                <SliderVertical className="icon-field" variant="linear" />
                <CustomAutoComplete
                  handleOnFocus={() => {
                    handleOnFocus(
                      "mother_profession",
                      values.mother_profession_info
                    );
                  }}
                  handleOnBlur={() => handleOutOffFocus()}
                  attribute_name={"Industry"}
                  type={"industry"}
                  options={industryOptions}
                  // selectedOption={values.mother_profession_info}
                  selectedSubject={(value) => {
                    let event = {
                      target: {
                        name: "mother_profession_info",
                        value: value.value,
                      },
                    };
                    handleChange(event);
                  }}
                  resetAfterSelect={false}
                  id={`industryMother`}
                  placeholder={"Select industry of profession*"}
                  onFocusPlaceholder={"Start typing profession"}
                />
              </div>

              {values.mother_profession_info && (
                <div className={`col-12 form-group no-label mb-2`}>
                  <Briefcase className="icon-field" variant="linear" />
                  <CustomAutoComplete
                    handleOnFocus={() => {
                      setErrorMessage("");
                    }}
                    handleOnBlur={() => handleOutOffFocus()}
                    attribute_name={"Career"}
                    type={"career"}
                    options={careersOptions}
                    selectedOption={values.mother_profession}
                    selectedSubject={(value) => {
                      let event = {
                        target: {
                          name: "mother_profession",
                          value: value.value,
                        },
                      };
                      handleChange(event);
                      handleChangeFunction(event, "father_profession");
                    }}
                    resetAfterSelect={false}
                    id={`industryFather`}
                    placeholder={"Select mother  profession*"}
                    onFocusPlaceholder={"Start typing profession"}
                  />
                </div>
              )}

              {values.mother_profession_info && values.mother_profession && (
                <div className={`col-12 form-group no-label mb-2`}>
                  <Briefcase className="icon-field" variant="linear" />
                  <CustomAutoComplete
                    handleOnFocus={() => {
                      setErrorMessage("");
                    }}
                    handleOnBlur={() => handleOutOffFocus()}
                    attribute_name={"Designation"}
                    type={"designation"}
                    options={designationOptions}
                    selectedOption={values.mother_designation}
                    selectedSubject={(value) => {
                      let event = {
                        target: {
                          name: "mother_designation",
                          value: value.value,
                        },
                      };
                      handleChange(event);
                    }}
                    resetAfterSelect={false}
                    id={`designation-mother`}
                    placeholder={"Select mother designation"}
                    onFocusPlaceholder={"Start typing designation"}
                  />
                </div>
              )}
              {values.mother_profession_info &&
                values.mother_profession &&
                values.mother_designation && (
                  <>
                    <div className={`col-12 form-group no-label mb-2`}>
                      <TextalignJustifyleft
                        className="icon-field"
                        variant="linear"
                      />
                      <input
                        className="form-control"
                        placeholder="Enter company name "
                        name={"mother_company_name"}
                        value={values.mother_company_name}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        <div
          className={`modal-footer coin-anim  ${eduLiner && "anim-1"}    ${
            appreciatingLiners && "anim-1 anim-2 anim-3"
          } ${errorMessage && "anim-1 anim-2 anim-3"} modfooter`}
        >
          <center>
            <button
              type="button"
              className={"btn btn-lg btn-black"}
              onClick={handleDetails}
              disabled={props.showloader ? true : false}
              >
                {props.showloader && (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}{" "}
              Save
            </button>
          </center>

          <div className="text">
            <div className=" align-items-center col-auto">
              {!errorMessage && (
                <>
                  <div className="points">
                    <img
                      className="img-fluid coin-float"
                      src={images.coin.default.src}
                    />
                    <img className="img-fluid" src={images.coin.default.src} />
                  </div>
                  <h6 className="mb-0">{eduLiner}</h6>
                  <h6 className="mb-0 text-blue">{appreciatingLiners}</h6>
                </>
              )}
              <h6 className="mb-0 text-pink">{errorMessage}</h6>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
