export const GQ_ADD_EVENT = `mutation CreateParentEvent($event: ParentEventCreateInput) {
  createParentEvent(event: $event) {
    id
  }
}`;

export const GQ_UPDATE_EVENT = `mutation UpdateParentEvent($event: UpdateParentEventInput) {
  updateParentEvent(event: $event) {
    id
  }
}`;

export const GQ_CREATE_FREE_SLOT = `mutation CreateFreeSlot($childrenId: String, $slots: [FreeSlotInput]) {
  createFreeSlot(children_id: $childrenId, slots: $slots) {
    message
  }
}`;


export const GQ_CREATE_FREE_SLOT_GUIDANCE = `mutation CreateFreeSlotGuidance($childrenId: String, $slots: [FreeSlotInput]) {
  createFreeSlotGuidance(children_id: $childrenId, slots: $slots) {
    message
  }
}`;


export const GQ_GET_EVENT_BY_DATE = `query GetParentEventsByDate($date: Date,$children: [String]) {
  getParentEventsByDate(date: $date, children: $children) {
    date
    day
    events {
      id
      id
      type
      children_id
      title
      availability_type
      start_date
      end_date
      start_time
      end_time
      activity_type
      auto_repetition
      activity_provider
      weekday_preference
      priority
      description
      status
    }
  }
}`;

export const GQ_GET_EVENT_BY_MONTH = `query GetParentEventsByMonth($month: Date,$children: [String]) {
  getParentEventsByMonth(month: $month, children: $children) {
    date
    day
    events {
      id
      type
      children_id
      title
      availability_type
      start_date
      end_date
      start_time
      end_time
      activity_type
      auto_repetition
      activity_provider
      weekday_preference
      priority
      description
      status
    }
  }
}`;


export const GQ_DELETE_EVENT = `mutation DeleteParentEvent($eventId: String, $date: Date, $type: Int) {
  deleteParentEvent(event_id: $eventId, date: $date, type: $type) {
    id
  }
}`;

export const GQ_GET_COURSE_PROVIDERS = `query GetCourseProvider($query: String) {
  getCourseProvider(query: $query) {
    name
    id
  }
}`;

export const GQ_ADD_COURSE_PROVIDERS = `mutation AddCourseProvider($name: String) {
  addCourseProvider(name: $name) {
    id
    name
  }
}`;

export const GQ_DAILY_EVENT = `mutation UpdateDailySchedules($events: [DailyScheduleUpdateInput]) {
    updateDailySchedules(events: $events) {
        id
    }
}`;

export const GQ_GET_USER_EVENTS = `query GetParentEvents($children: String) {
  getParentEvents(children: $children) {
    id
    children_id
    type
    title
    availability_type
    start_date
    end_date
    end_time
    start_time
    activity_type
    activity_provider
    auto_repetition
    weekday_preference
    priority
    description
    status
    type
  }
}`;


export const GQ_DELETE_FREE_SLOT = `mutation RemoveFreeSlot($removeFreeSlotId: String) {
  removeFreeSlot(id: $removeFreeSlotId) {
    message
  }
}`;

export const GQ_BOOK_COUNSELLOR_SESSION = `mutation BookSessionWithCounsellor($childrenId: String) {
  bookSessionWithCounsellor(children_id: $childrenId) {
    message
  }
}`;

export const GQ_COUNSELLING_PAYMENT = `mutation CounsellingSessionPayment($childrenId: String) {
  counsellingSessionPayment(children_id: $childrenId) {
    transaction_amount
    transaction_id
  }
}`;

export const GQ_BOOK_PROVIDER_DEMO = `mutation BookSessionForDemo($data: DemoRequestInput) {
  bookSessionForDemo(data: $data) {
    message
  }
}`;

export const GQ_BOOKED_PENDING_DEMO_COURSES = `query GetChildrenDemoRequestCourses($childrenId: String) {
  getChildrenDemoRequestCourses(children_id: $childrenId) {
    courses
  }
}`;

export const GQ_DEMO_JOINED_STATUS = `mutation DemoJoinedStatus($requestId: String) {
  demoJoinedStatus(request_id: $requestId) {
    id
  }
}`;

export const GQ_CHILDREN_DEMO_PENDING_REQUEST = `query GetChildrenDemoPendingRequest($childrenId: String) {
  getChildrenDemoPendingRequest(children_id: $childrenId) {
    id
    counsellor_course_name
    counsellor_course_id
    provider_name
    request_type
    createdAt
    updatedAt
    status
    course {
      id
      name
      provider_image
      provider_name
      banner {
       path
       mimetype
      }
      media_files {
        path
        mimetype
      }
    }
  }
}`;

export const GQ_GET_CHILDREN_DEMO_BOOKING_REQUEST = `query GetChildrenDemoBookingRequest($childrenId: String, $status: String, $keyword: String) {
  getChildrenDemoBookingRequest(children_id: $childrenId, status: $status, keyword: $keyword) {
    id
    course_id {
      id
      provider_name
      provider_image
      name
      banner {
       path
       mimetype
      }
      media_files {
        path
        mimetype
      }
    }
    is_reviewed
    trainer_name
    session_date
    session_time
    session_url
    session_comment
    request_type
    status
    jookens
    createdAt
    children_id
  }
}`;




export const GQ_GET_COUNSELLINGREQUEST = `query GetCounsellingRequest($children_id: String, $status: String){
  getCounsellingRequest (children_id :$children_id, status: $status){
      id
      session_date
      session_time
      session_url
      status
      createdAt
      course_id
      is_reviewed
      trainer_name
      session_comment
      request_type
      jookens
      children_id
      type
  }
}`;



export const GQ_GET_FREE_SLOTS = `query GetFreeSlots($childrenId: String!, $weekStartDate: Date!, $weekEndDate: Date!) {
  getFreeSlots(children_id: $childrenId, week_start_date: $weekStartDate, week_end_date: $weekEndDate) {
    allSlots {
      id
      week_start_date
      week_end_date
      auto_repetition
      weekday_preference
      custom_date
      start_time
      end_time
    }
    total
    scheduled
    available
    allow_counsellor_booking
    initial_request
    session_discount_over
    session_booking_payment
  }
}`;

export const GQ_GET_CONFIRM_REQUEST = `query GetChildrenConfirmRequest($childrenId: String) {
  getChildrenConfirmRequest(children_id: $childrenId) {
    id
    request_type
    trainer_name
    session_date
    session_time
    session_url
    session_comment
  }
}`;

export const GQ_GET_CALENDAR_BOOKED_REQUEST = `query GetChildrenDemoBookingRequest($childrenId: String, $status: String, $keyword: String) {
    getChildrenDemoBookingRequest(children_id: $childrenId, status: $status, keyword: $keyword) {
        id
        course_id {
            id
            provider_name
            provider_image
            name
            media_files {
                path
                mimetype
            }
        }
        trainer_name
        session_date
        session_time
        session_url
        session_comment
        request_type
        status
        jookens
        createdAt
        children_id
    }
}`;

