
const initialValue = {
    info_section_text: '',

};

export default function set_InfoSection(state = initialValue, action){
    switch(action.type) {
        case "INFO_SECTION_ACTION":
            return Object.assign({}, state, {
                info_section_text: action.info_section_text,
            });
        default:
            return state;
    }
};
